import React from "react";

import Grid from "@material-ui/core/Grid";

import config from "../../../../../config"
import { CARRIER_ICONS } from "../../../../../constants/index";
import { RadioBox } from "../../../../../components/Radio";
import { getPriceAndShippingDurationText } from './utils'
import RemarkDetail from "./RemarkDetail";

const { IS_USING_NEW_SHIPPING_SERVICE } = config

const SelectShippingMethod = ({ selectedShippingMethodIndex, setSelectedShippingMethodIndex, shippingMethod, t }) => {
  if (!IS_USING_NEW_SHIPPING_SERVICE) {
    return (
      <Grid container>
        {shippingMethod.map((shipping, index) => {
          return (
            <Grid item xs={12} className="p-2" key={index}>
              <RadioBox
                disabled={shipping.disabled}
                isActive={selectedShippingMethodIndex === index}
                title={t(shipping.description)}
                imageUrl={CARRIER_ICONS[shipping.description] || CARRIER_ICONS.DEFAULT_CARRIER}
                subTitle={Boolean(shipping.isCODPayment) ? t("CASH_ON_DELIVERY") : ""}
                description={getPriceAndShippingDurationText(t)(shipping)}
                onClick={() => setSelectedShippingMethodIndex(index)}
                remark={<RemarkDetail shipping={shipping} t={t} />}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return (
    <Grid container>
      {shippingMethod.map((shippingQuotation, index) => {
        return (
          <Grid item xs={12} className="p-2" key={index}>
            <RadioBox
              disabled={shippingQuotation.isDisabled}
              isActive={selectedShippingMethodIndex === shippingQuotation.shippingId}
              title={shippingQuotation.thDisplayName}
              imageUrl={shippingQuotation.iconUrl}
              subTitle={Boolean(shippingQuotation.isCod) ? t("CASH_ON_DELIVERY") : ""}
              description={getPriceAndShippingDurationText(t)(shippingQuotation)}
              onClick={() => setSelectedShippingMethodIndex(shippingQuotation.shippingId)}
              remark={<RemarkDetail shipping={shippingQuotation} t={t} />}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SelectShippingMethod;
