import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

import config from "../../../config";

const { TWO_C_TWO_P_PAYMENT_URL } = config;

const TwoCTwoPPayment = ({ handleSubmit, payload }) => {
  const {
    amount: formattedAmount,
    currency: twoCTwoPCurrency,
    enableStoreCard,
    hashValue,
    lang,
    merchantId,
    orderId,
    paymentDescription,
    paymentOption,
    qrType,
    request3DS,
    resultURL1,
    resultURL2,
    storedCardUniqueId,
    version,
  } = payload;

  return (
    <>
      <Grid container justify="center" className="p-4">
        <CircularProgress />
      </Grid>

      <form
        onSubmit={handleSubmit}
        action={TWO_C_TWO_P_PAYMENT_URL}
        method="POST"
        style={{ display: "none" }}
      >
        <input type="hidden" name="version" value={version} />
        <input type="hidden" name="merchant_id" value={merchantId} />
        <input type="hidden" name="currency" value={twoCTwoPCurrency} />
        <input type="hidden" name="result_url_1" value={resultURL1} />
        <input type="hidden" name="result_url_2" value={resultURL2} />
        <input
          type="hidden"
          name="enable_store_card"
          value={enableStoreCard}
        />
        <input type="hidden" name="request_3ds" value={request3DS} />
        <input type="hidden" name="payment_option" value={paymentOption} />
        <input type="hidden" name="hash_value" value={hashValue} />
        <input
          type="hidden"
          name="payment_description"
          value={paymentDescription}
        />
        storedCardUniqueId ? <input type="hidden" name="stored_card_unique_id" value={storedCardUniqueId} /> : null
        qrType ? <input type="hidden" name="qr_type" value={qrType} /> : null
        <input type="hidden" name="order_id" value={orderId} />
        <input type="hidden" name="amount" value={formattedAmount} />
        <input type="hidden" name="default_lang" value={lang} />
        <input
          type="submit"
          name="submit"
          value="Confirm"
          id="buttonSubmit2C2PForm"
        />
      </form>
    </>
  );
}

export default TwoCTwoPPayment;
