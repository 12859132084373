import React from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { Grid, Button, Typography } from "@material-ui/core";
import CheckBox from "../../../components/CheckBox";
import { formatMoney } from "../../../utils/product";
import { COLORS, PRODUCT } from "../../../constants/index";

import {
  ProductFoodAmountText,
  ButtonCircle,
  Footer,
  InputNote,
  ProductPrice,
  SKUTextLabel,
  SKUTextLabelWarning,
  PromotionTitlePrimaryText,
  OptionsContainer,
} from "./ProductDetail.styled";

export const SelectFoodProduct = ({
  onChangeAmount,
  onClickAddToCart,
  amount,
  promotions,
  productSKUOptions,
  onSelectOption,
  onChangeNote,
  grandTotalPrice,
  optionErrorIndexes,
}) => {
  const { t } = useTranslation(["PRODUCT_DETAIL"]);

  return (
    <>
      <Grid container>
        {/* promotion text list */}
        {Boolean(promotions.length) && (
          <Grid item container className="pb-2">
            {promotions.map((promotion) => {
              const promotionName = get(promotion, "name");
              const promotionDescription = get(promotion, "description");
              return (
                <Grid item container className="py-1">
                  <PromotionTitlePrimaryText>
                    {promotionDescription || promotionName}
                  </PromotionTitlePrimaryText>
                </Grid>
              );
            })}
          </Grid>
        )}

        {productSKUOptions.map((option, index) => {
          const {
            name,
            choices,
            maximum,
            isRequired,
            isMultipleChoices,
            isActive,
          } = option;

          if (!isActive) {
            return null;
          }

          const maximumSelected =
            choices.length < maximum ? choices.length : maximum;
          const countSelectedChoice = choices.reduce((sum, choice) => {
            return choice.isSelected ? sum + 1 : sum;
          }, 0);
          const isReachLimit =
            isMultipleChoices && countSelectedChoice === maximumSelected;
          const isError =
            optionErrorIndexes &&
            optionErrorIndexes.some((errorIndex) => errorIndex === index);

          return (
            <Grid container item xs={12} key={index} className="py-1">
              <Grid
                item
                container
                justify="space-between"
                alignItems="center"
                className="pb-2"
                id={`option${index}`}
              >
                <SKUTextLabel className="pl-2">{`${name} : `}</SKUTextLabel>
                <SKUTextLabel>{`${
                  isRequired ? t("SELECT") : t("MAXIMUM_SELECTED")
                } ${maximumSelected}`}</SKUTextLabel>
              </Grid>
              {isError && (
                <Grid item xs={12} className="pb-2">
                  <SKUTextLabelWarning>
                    {t("SELECT_OPTION_PRODUCT")}
                  </SKUTextLabelWarning>
                </Grid>
              )}
              <OptionsContainer item container>
                {choices.map((choice, choiceIndex) => {
                  const {
                    name: choiceName,
                    price,
                    isSelected,
                    isActive,
                  } = choice;

                  const choicePriceText = `${price < 0 ? "-" : "+"}฿${Math.abs(
                    price
                  )}`;

                  return (
                    isActive && (
                      <Grid item xs={12}>
                        <CheckBox
                          isRadio={!isMultipleChoices}
                          isActive={isSelected}
                          disabled={isReachLimit && !isSelected}
                          onClick={() => {
                            onSelectOption(
                              index,
                              choiceIndex,
                              isMultipleChoices
                            );
                          }}
                          noBorder
                        >
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={9}>
                              {choiceName}
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              container
                              alignContent="flex-end"
                              justify="flex-end"
                            >
                              {choicePriceText}
                            </Grid>
                          </Grid>
                        </CheckBox>
                      </Grid>
                    )
                  );
                })}
              </OptionsContainer>
            </Grid>
          );
        })}

        <Grid item xs={12} className="pl-2">
          <Grid item container>
            <SKUTextLabel>{t("NOTE")} :</SKUTextLabel>
          </Grid>
          <Grid item xs={12}>
            <InputNote
              placeholder={t("EXAMPLE")}
              className="w-100 pt-1"
              onChange={(event) => onChangeNote(event.target.value)}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignContent="center"
          alignItems="center"
          className="my-2"
        >
          <Grid item xs={5}>
            <SKUTextLabel className="pl-2">{t("AMOUNT")} :</SKUTextLabel>
          </Grid>
          <Grid item container alignContent="center" alignItems="center" xs={7}>
            <ButtonCircle
              className="mr-2"
              onClick={() => onChangeAmount(-1, PRODUCT.QUANTITY_LIMIT)}
              disabled={amount === 1}
            >
              -
            </ButtonCircle>
            <ProductFoodAmountText className="px-2">
              {amount}
            </ProductFoodAmountText>
            <ButtonCircle
              className="ml-2"
              onClick={() => onChangeAmount(1, PRODUCT.QUANTITY_LIMIT)}
              disabled={amount === PRODUCT.QUANTITY_LIMIT}
            >
              +
            </ButtonCircle>
          </Grid>
        </Grid>
      </Grid>

      <Footer>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Grid container>
              <Grid item>
                <div
                  className="pl-3 pr-1"
                  style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: COLORS.DarkMed,
                  }}
                >
                  {t("TOTAL_PRICE")}
                </div>
              </Grid>
              <Grid item className="pl-2">
                <ProductPrice>฿{formatMoney(grandTotalPrice)}</ProductPrice>
              </Grid>
            </Grid>
            <Grid item className="pl-3 pb-1">
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="caption"
                    style={{ color: COLORS.LightBlue }}
                  >
                    {Boolean(promotions.length) &&
                      `*${t("TOTAL_PRICE_INCLUDE_PROMOTIONS")}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              style={{ borderRadius: "16px" }}
              className="my-3 mx-2 px-4"
              disabled={amount < 1}
              onClick={onClickAddToCart}
              variant="contained"
              color="primary"
            >
              {t("ADD_TO_CART")}
            </Button>
          </Grid>
        </Grid>
      </Footer>
    </>
  );
};
