import * as React from "react";

const SvgIcCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#FBFCFE"
      fill-rule="evenodd"
      d="M11.494 4.729a.882.882 0 0 1 1.248 1.248L7.447 11.27a.882.882 0 0 1-1.247 0L3.258 8.33a.882.882 0 0 1 1.248-1.248l2.318 2.317 4.67-4.67z"
    />
  </svg>
);

export default SvgIcCheck;
