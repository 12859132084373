import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { StoreLogo, StoreName } from "./styled";
import { getShippingCarrier } from './utils'
import OrderProductList from "./OrderProductList";
import PriceSummaryCard from "./PriceSummaryCard";
import ShippingInformation from "./ShippingInformation";

import config from "../../../../../config"
import { VAT, SHIPPING_METHOD } from "../../../../../constants/index";
import calculateCarts from "../../../../../utils/cart";

const { IS_USING_NEW_SHIPPING_SERVICE } = config

const OrderSummary = props => {
  const { t, cartData, storeDetail, addressData, shippingData, hasSKUProductSKUs, deliverySchedule } = props;

  const vatValue = cartData.vat.rate;
  const vatOptions = cartData.vat.options;
  const address = `${addressData.firstName} ${addressData.lastName} ${addressData.streetAddress}
   ${addressData.district} ${addressData.subDistrict} ${addressData.province} ${addressData.postalCode}`;

  const phoneNo = addressData.phoneNumber;
  const email = addressData.email;

  const isManualShippingType = [SHIPPING_METHOD.SHIPPING_CALCULATE_TYPE.DISTANCE_BASE_PRICE, SHIPPING_METHOD.SHIPPING_TYPE.MANUAL]
  .includes(shippingData.type || shippingData.calculateType);

  let shipping = shippingData
  if (!IS_USING_NEW_SHIPPING_SERVICE) {
    shipping = {
      ...shippingData,
      rate: isManualShippingType ? 0 : shippingData.fixedPrice, // exclude shipping price for DISTANCE_BASE_PRICE calculation type
    };
  }

  const {
    grandTotal,
    productSKUs,
    promotions,
    shippingMethod,
    shippingPrice,
    totalPrice,
    totalProductDiscount,
    totalShippingDiscount,
    vatPrice,
  } = calculateCarts(
    cartData.productSKUs,
    cartData.promotions,
    cartData.vat.rate,
    cartData.vat.options,
    shipping,
    cartData.vat.isShippingIncluded,
    hasSKUProductSKUs,
  );

  return (
    <Grid container>
      <Grid item container alignItems="center">
        <Grid item className="pr-3">
          <StoreLogo src={storeDetail.image} />
        </Grid>
        <Grid item className="pr-3">
          <StoreName variant="body1">{storeDetail.name}</StoreName>
        </Grid>
      </Grid>
      <Grid item xs={12} className="pt-3">
        <ShippingInformation
          t={t}
          address={address}
          phoneNo={phoneNo}
          email={email}
          deliverySchedule={deliverySchedule}
        />
      </Grid>
      {addressData.deliveryNote && (
        <Grid item xs={12} className="pt-3">
          <Typography variant="body2">
            {t("DELIVERY_NOTE")} : {addressData.deliveryNote}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} className="pt-4">
        <OrderProductList t={t} products={productSKUs} />
      </Grid>
      <Grid item xs={12} className="pt-2 pb-4">
        <PriceSummaryCard
          t={t}
          discountPrice={totalProductDiscount}
          shippingDiscountPrice={totalShippingDiscount}
          subTotalPrice={totalPrice}
          hasVat={Boolean(vatOptions) && vatOptions !== VAT.VAT_OPTIONS_TYPE.NONE}
          vatValue={vatValue}
          vatPrice={vatPrice}
          vatOptions={vatOptions}
          shippingPrice={shippingPrice}
          shippingMethod={shippingMethod}
          grandTotal={grandTotal}
          orderPromotions={promotions}
          shippingCarrier={getShippingCarrier(shippingData)}
          isShippingDistanceBaseType={isManualShippingType}
        />
      </Grid>
    </Grid>
  );
};

export default OrderSummary;
