const COMMON = {
  en: {
    SUBMIT: "Submit",
    FREE: "Free",
    DATE: "Date",
    TIME: "Time",
    CONFIRM: "Confirm",
  },
  th: {
    SUBMIT: "ส่ง",
    FREE: "ฟรี",
    DATE: "วันที่",
    TIME: "เวลา",
    CONFIRM: "ยืนยัน",
  },
};

export default COMMON;
