import axios from "axios";
import { get } from "lodash";
import qs from "qs";

import config from "../../config";
import { HTTP_CLIENT } from "../../constants";
import { base64ToJSON, isMobileDevice, sleep } from "../../utils/common";

const GET_FB_CONTEXT_BACKOFF_MS = 200;
class HttpClientService {
  token = "";
  authPlatform = "";

  constructor() {
    const { apiBaseUrl, FACEBOOK_APP_ID, LIFF_ID } = config;
    this.LIFF_ID = LIFF_ID;
    this.API_BASE_URL = apiBaseUrl;
    this.FACEBOOK_APP_ID = FACEBOOK_APP_ID;
  }

  /**
   * @private
   * Fallback on closing window
   * @param {string} platform
   * @returns {void}
   */
  _fallbackCloseWindow(platform) {
    if (isMobileDevice && platform === "FACEBOOK") {
      return window.location.replace("https://www.messenger.com/closeWindow/?display_text=closing%20window");
    }

    return window.top && window.top.close();
  }

  /**
   * @private
   * Close Facebook window
   * @returns
   */
  async _closeFacebookWindow(platform) {
    try {
      await new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        if (MessengerExtensions) {
          // eslint-disable-next-line no-undef
          return MessengerExtensions.requestCloseBrowser(
            function success() {
              resolve(null);
            },
            function error(err) {
              console.error(err);

              reject(err);
            },
          );
        }

        return this._fallbackCloseWindow(platform);
      });

      return;
    } catch (err) {
      this._fallbackCloseWindow(platform);
    }
  }

  /**
   * @private
   * Close line window
   * @returns {void}
   */
  _closeLineWindow() {
    return window.liff.closeWindow();
  };

  /**
   * Close window by customer's platform
   * @param {string} platform
   * @returns {Promise<void>}
   */
  _closeWindowByPlatform(platform) {
    if (["FACEBOOK", "INSTAGRAM"].includes(platform)) {
      return this._closeFacebookWindow(platform);
    }

    return this._closeLineWindow();
  };

  _getFacebookToken() {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      MessengerExtensions.getContext(
        this.FACEBOOK_APP_ID,
        async thread_context => {
          let { signed_request } = thread_context;
          if (!signed_request) {
            console.log("fail to get context, retrying...");
            await sleep(GET_FB_CONTEXT_BACKOFF_MS);
            signed_request = await this._getFacebookToken();
          }

          const encodedPayloads = signed_request.split(".");
          const decodedPayload = base64ToJSON(encodedPayloads[1]);
          const psid = get(decodedPayload, "psid");
          if (!psid) {
            reject(new Error("empty psid"));
          }

          console.log("successfully get context!");
          resolve(signed_request);
        },
        function error(err) {
          reject(err);
        },
      );
    });
  }

  _getFallbackQueryString(query) {
    const liffStateQueryString = get(query, "liff.state")
    if (!liffStateQueryString) {
      return {}
    }

    return qs.parse(get(liffStateQueryString.split("?"), "1"))
  }

  _getQueryString() {
    const queryString = decodeURIComponent(window.location.search);

    return qs.parse(queryString.replace("?", ""));
  }

  _getPlatform() {
    const query = this._getQueryString();
    if (query.platform) {
      this.authPlatform = query.platform

      return query.platform
    }

    const fallbackQueryString = this._getFallbackQueryString(query)

    return get(fallbackQueryString, "platform")
  }

  _getProviderToken() {
    const query = this._getQueryString();
    if (query.token) {
      return query.token
    }

    const fallbackQueryString = this._getFallbackQueryString(query)
    const token = get(fallbackQueryString, "token")
    if (!token) {
      throw new Error("TOKEN_NOT_FOUND")
    }

    return token
  }

  _getProjectId() {
    const query = this._getQueryString();
    if (query.projectId) {
      return query.projectId
    }

    const fallbackQueryString = this._getFallbackQueryString(query)
    const projectId = get(fallbackQueryString, "projectId")
    if (!projectId) {
      throw new Error("PROJECT_ID_NOT_FOUND")
    }

    return projectId
  }

  /**
   * @private
   * Get customer's platform
   * @returns {Promise<string>}
   */
  async _getCustomerPlatform() {
    const platform = this._getPlatform()
    if (platform) {
      return platform
    }

    const headers = this.getAuthorizationHeaders();
    const projectId = this._getProjectId()

    const res = await axios.get(`${this.API_BASE_URL}/webview/ecommerce/customer-platform?projectId=${projectId}`, { headers });
    const customerPlatform = get(res, "data.platform");

    this.authPlatform = customerPlatform

    return customerPlatform
  }

  /**
   * @private
   * Is customer in given platform
   * @param {ReadonlyArray<string>} platforms
   * @returns {Promise<boolean>}
   */
  async _isCustomerPlatform(platforms) {
    const customerPlatform = await this._getCustomerPlatform()

    return platforms.includes(customerPlatform)
  }

  /**
   * Initialize LIFF client with LIFF ID
   */
  init = async () => {
    if (window.liff && this.LIFF_ID) {
      await window.liff.init({ liffId: this.LIFF_ID });
    }
  };

  /**
   * Is line client
   * @returns {Promise<boolean>} isLineClient
   */
  isLineClient = () => {
    if (this.authPlatform) {
      return this.authPlatform === "LINE"
    }

    const hasLiffSDK = get(window, "liff");
    if (!hasLiffSDK) {
      return false;
    }

    const isInLiffBrowser = window.liff.isInClient();
    if (isInLiffBrowser) {
      this.authPlatform = "LINE";

      return true;
    }

    return this._isCustomerPlatform(["LINE"]);
  };

  /**
   * Is meta platform
   *
   * ** note **
   *
   * this function create on facebook sdk can't detect platform issue if facebook sdk work correctly should remove this function
   * @returns {Promise<boolean>} isMetaPlatform
   */
  isMetaPlatform = async () => {
    if (this.authPlatform) {
      return ["FACEBOOK", "INSTAGRAM"].includes(this.authPlatform)
    }

    return this._isCustomerPlatform(["FACEBOOK", "INSTAGRAM"]);
  };

  /**
   * Check whether current customer is using meta client
   * @returns {Promise<boolean>}
   */
  isMetaClient = async () => {
    const isInExtension = get(window, "MessengerExtensions.isInExtension");
    if (isInExtension && isInExtension()) {
      return true;
    }

    return this.isMetaPlatform();
  };

  // #region Authorization related
  getAuthorizationHeaders() {
    if (!this.token) {
      this.token = this._getProviderToken();
    }

    return { [HTTP_CLIENT.AUTH_PLATFORM.AUTHORIZATION]: this.token };
  }
  // #endregion

  /**
   * Close webview window
   * @returns {Promise<void>}
   */
  async closeWindow() {
    const customerPlatform = await this._getCustomerPlatform()

    try {
      if (customerPlatform) {
        await this._closeWindowByPlatform(customerPlatform)

        return
      }

      // Fallback to unknown platform
      return window.top && window.top.close()
    } catch (error) {
      console.error(error);

      return this._fallbackCloseWindow(customerPlatform);
    }
  }

  // #region API call related
  async addOrderAndSchedule(projectId, shippingMethod, deliverySchedule) {
    const headers = await this.getAuthorizationHeaders();

    return axios.post(
      `${this.API_BASE_URL}/webview/ecommerce/order-schedule`,
      {
        projectId,
        shippingMethod,
        deliverySchedule,
      },
      { headers },
    );
  }

  async addLegacyOrder(projectId, shippingMethod, shippingAddress, isCODPayment, deliverySchedule, offlineStoreId) {
    const headers = await this.getAuthorizationHeaders();

    return axios.post(
      `${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/orders`,
      {
        deliverySchedule,
        isCODPayment,
        offlineStoreId,
        shippingAddress,
        shippingMethod,
      },
      { headers },
    );
  }

  async addOrder(projectId, shippingAddress, deliverySchedule, offlineStoreId, shippingId) {
    const headers = await this.getAuthorizationHeaders();

    return axios.post(
      `${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/orders`,
      {
        deliverySchedule,
        offlineStoreId,
        shippingAddress,
        shippingId,
      },
      { headers },
    );
  }

  async addToCart(projectId, productId, productSKUId, productSKUDetails) {
    const headers = await this.getAuthorizationHeaders();

    return axios.post(
      `${this.API_BASE_URL}/webview/ecommerce/add-to-cart`,
      {
        ...productSKUDetails,
        productId,
        productSKUId,
        projectId,
      },
      { headers },
    );
  }

  async fillShippingAddress(projectId, data) {
    const headers = await this.getAuthorizationHeaders();

    return axios.post(
      `${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/shipping-address`,
      {
        data,
      },
      { headers },
    );
  }

  async getOrderDetail(projectId, orderId) {
    const headers = await this.getAuthorizationHeaders();

    return axios.get(`${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/orders/${orderId}`, { headers });
  }

  async getActivePromotion(projectId, productId) {
    const headers = await this.getAuthorizationHeaders();

    return axios.get(
      `${this.API_BASE_URL}/webview/ecommerce/promotions?projectId=${projectId}&productId=${productId}`,
      { headers },
    );
  }

  async getAllActivePromotion(projectId) {
    const headers = await this.getAuthorizationHeaders();

    return axios.get(`${this.API_BASE_URL}/webview/ecommerce/project/${projectId}/all-promotions`, { headers });
  }

  async getCart(projectId) {
    const headers = await this.getAuthorizationHeaders();

    return axios.post(`${this.API_BASE_URL}/webview/ecommerce/cart`, { projectId }, { headers });
  }

  async getProductDetail(projectId, productId) {
    const headers = await this.getAuthorizationHeaders();

    return axios.get(
      `${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/product-detail?productId=${productId}`,
      { headers },
    );
  }

  async getShippingAddress(projectId, orderId) {
    const headers = await this.getAuthorizationHeaders();
    const shippingAddressURL = `${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/shipping-address`;

    return axios.get(orderId ? `${shippingAddressURL}?orderId=${orderId}` : shippingAddressURL, { headers });
  }

  async getWaitingForAddressOrder(projectId, orderId) {
    const headers = await this.getAuthorizationHeaders();

    return axios.post(
      `${this.API_BASE_URL}/webview/ecommerce/orderWaitingForAddress`,
      { orderId: Number(orderId), projectId: Number(projectId) },
      { headers },
    );
  }

  async selectProduct(projectId, productName) {
    const headers = await this.getAuthorizationHeaders();

    return axios.post(
      `${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/products`,
      { productName },
      { headers },
    );
  }

  async updateCart(projectId, data, nextStep) {
    const headers = await this.getAuthorizationHeaders();

    return axios.post(`${this.API_BASE_URL}/webview/ecommerce/cart/update`, { data, nextStep, projectId }, { headers });
  }

  async getPaymentGatewayInformation(projectId, orderNumber, paymentGatewayId) {
    const headers = await this.getAuthorizationHeaders();

    return axios.get(`${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/payment-gateway`, {
      headers,
      params: { orderNumber, paymentGatewayId },
    });
  }

  async getShippingMethod(projectId, shippingAddress) {
    const headers = await this.getAuthorizationHeaders();

    return axios.get(`${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/shipping-method`, {
      headers,
      params: { shippingAddress },
    });
  }

  async getShippingMethodByShippingMethodType(projectId, shippingMethodType) {
    const headers = await this.getAuthorizationHeaders();
    return axios.get(
      `${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/shipping-method/types/${shippingMethodType}`,
      {
        headers,
      },
    );
  }

  async getDeliveryConfiguration(projectId, shippingMethod = "STANDARD", offlineStoreId) {
    const headers = await this.getAuthorizationHeaders();

    return axios.get(`${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/delivery-configuration`, {
      headers,
      params: { shippingMethod, offlineStoreId },
    });
  }

  async getStoreDetail(projectId) {
    const headers = await this.getAuthorizationHeaders();

    return axios.get(`${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/store`, { headers });
  }

  async getOfflineStores(projectId) {
    const headers = await this.getAuthorizationHeaders();

    return axios.get(`${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/offline-stores`, { headers });
  }

  async addPickedUpBranchAndSchedule(projectId, offlineStoreId, deliverySchedule, shippingAddress) {
    const headers = await this.getAuthorizationHeaders();

    return axios.post(
      `${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/offline-stores`,
      {
        offlineStoreId,
        deliverySchedule,
        shippingAddress,
      },
      { headers },
    );
  }

  async getProjectConfiguration(projectId) {
    const headers = await this.getAuthorizationHeaders();

    return axios.get(`${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/configuration`, { headers });
  }

  async getProductSearch(projectId, searchParams, offset = 0, limit = 10) {
    const headers = await this.getAuthorizationHeaders();
    const encodedSearchParams = encodeURIComponent(`${JSON.stringify(searchParams)}`);

    return axios.get(
      `${this.API_BASE_URL}/webview/ecommerce/project/${projectId}/product-search?offset=${offset}&limit=${limit}&searchParams=${encodedSearchParams}`,
      { headers },
    );
  }

  async generateOTP(projectId, phoneNumber) {
    const headers = await this.getAuthorizationHeaders();
    return axios.post(
      `${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/generate-otp`,
      { phoneNumber: phoneNumber },
      { headers },
    );
  }

  async verifyOTP(projectId, OTP, refCode) {
    const headers = await this.getAuthorizationHeaders();
    return axios.post(
      `${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/verify-otp`,
      { OTP: OTP, refCode: refCode },
      { headers },
    );
  }

  async validateCheckoutCart(projectId) {
    const headers = await this.getAuthorizationHeaders();
    return axios.get(`${this.API_BASE_URL}/webview/ecommerce/projects/${projectId}/checkout/validate`, { headers });
  }

  // #endregion
}

export default HttpClientService;
