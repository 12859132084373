import React, { useEffect, useState } from "react";
import moment from "moment";
import get from "lodash/get";

import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { Helmet } from "react-helmet";
import { DateTimePicker } from "@material-ui/pickers";

import Loading from "../../../components/Loading";
import { withQueryDeliverySchedule } from "../../../hoc";

import HttpClientService from "../../../services/http-client";
import { sleep } from "../../../utils/common";

import { Button, Container, Grid, Typography, Divider, CircularProgress } from "@material-ui/core";

import {
  TIME_FORMAT,
  TIME_INPUT_FORMAT,
  isDuringShopOpeningHours,
  getSuggestDeliveryTime,
  getSuggestDeliveryTimeWithDeliveryConfiguration,
  isDeliveryTimeValidWithDeliveryConfiguration,
} from "../../../utils/offlineStore";

const httpClientService = new HttpClientService();

const defaultDeliveryConfiguration = {
  start: "02:00:00+00:00",
  end: "11:00:00+00:00",
  timeInAdvance: 120,
};

const Schedule = ({ projectId, shippingMethod }) => {
  const { t } = useTranslation(["SCHEDULE", "COMMON", "DAYS"]);

  const currentDate = moment().toDate();

  const [selectedDate, handleDateChange] = useState(currentDate);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [deliveryConfiguration, setDeliveryConfiguration] = useState(defaultDeliveryConfiguration);
  const [offlineStore, setOfflineStore] = useState({});
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);

  useEffect(() => {
    const getProjectConfiguration = async () => {
      try {
        const { data } = await httpClientService.getDeliveryConfiguration(projectId, shippingMethod);
        const { configuration, offlineStore: branch } = data;

        // use defaultDeliveryConfiguration if project does not have this configuration
        const projectDeliveryConfiguration = configuration || defaultDeliveryConfiguration;

        setIsLoading(false);
        setOfflineStore(branch);
        const suggestTime = branch
          ? getSuggestDeliveryTime(branch, projectDeliveryConfiguration, selectedDate)
          : getSuggestDeliveryTimeWithDeliveryConfiguration(projectDeliveryConfiguration);

        handleDateChange(suggestTime);
        setDeliveryConfiguration(projectDeliveryConfiguration);
      } catch (err) {
        await httpClientService.closeWindow();
      }
    };

    getProjectConfiguration();
  }, []);

  const getOfflineStoreAddress = offlineStoreObject => {
    if (!offlineStoreObject) {
      return null;
    }

    const { address, subDistrict, district, province, postalCode } = offlineStoreObject;

    return [address, subDistrict, district, province, postalCode].filter(Boolean).join(" ");
  };

  const handleSubmit = async _value => {
    const deliverySchedule = moment(selectedDate).toISOString();
    try {
      setIsSubmit(true);
      await httpClientService.addOrderAndSchedule(projectId, shippingMethod, deliverySchedule);

      /**
       * We need to delay after already done of a new order and delay for process message between
       * chat webview -> rest api -> sme -> publish message (pub/sub) -> message-consumer -> process data -> publish to provider (FACEBOOK, INSTAGRAM, LINE etc.)
       **/
      await sleep(3000);
    } catch (err) {
      console.error(err);
    } finally {
      // Remove loading
      setIsSubmit(false);
      // Disable back button to force customer to close window by themselves if system cannot do it
      setIsButtonEnabled(false);
      await httpClientService.closeWindow();
    }
  };

  const startTimeLabel = moment(deliveryConfiguration.start, TIME_FORMAT)
    .add(Number(deliveryConfiguration.timeInAdvance), "minutes")
    .format(TIME_INPUT_FORMAT);

  const endTimeLabel = moment(deliveryConfiguration.end, TIME_FORMAT)
    .subtract(Number(deliveryConfiguration.timeInAdvance), "minutes")
    .format(TIME_INPUT_FORMAT);

  const isActiveDeliverySchedule = get(deliveryConfiguration, "isActive") || true;

  const isValidSchedule =
    (offlineStore
      ? isDuringShopOpeningHours(offlineStore, selectedDate)
      : isDeliveryTimeValidWithDeliveryConfiguration(deliveryConfiguration, selectedDate)) && isActiveDeliverySchedule;

  const offlineStoreAddress = getOfflineStoreAddress(offlineStore);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{t("SELECT_DELIVERY_TIME")}</title>
      </Helmet>

      <Formik onSubmit={handleSubmit} enableReinitialize>
        <Form noValidate>
          <Container className="py-2">
            <Grid container>
              <Grid item>
                <Typography className={"px-2"} fontWeight="fontWeightBold" variant="h5" color={"primary"} gutterBottom>
                  {t("SELECT_DELIVERY_TIME")}
                </Typography>
              </Grid>
            </Grid>

            {offlineStore && offlineStoreAddress && (
              <Grid item container justify="center" alignItems="center">
                <Grid item>
                  <Typography variant="h6" gutterBottom align="center">
                    {offlineStore.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" gutterBottom align="center">
                    {offlineStoreAddress}
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Divider variant="middle" />
            <Grid container justify="center" alignItems="center" className="mt-3">
              <Grid item>
                <Typography fontWeight="fontWeightBold" gutterBottom>
                  {t("WORKING_HOURS")}
                </Typography>
              </Grid>

              <Grid container item justify="center" alignItems="center" className="mt-3">
                {offlineStore && offlineStore.openingHours ? (
                  <Grid container item justify="center" alignItems="center" className="pl-5">
                    {offlineStore.openingHours.map(openingHour => {
                      return (
                        <React.Fragment key={`${openingHour.day}`}>
                          <Grid item xs={4}>
                            <Typography variant="subtitle1" gutterBottom>
                              {t(`DAYS:${openingHour.day}`)}{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                              {!openingHour.isActive ? t("NOT_DELIVERY") : `${openingHour.open} - ${openingHour.close}`}
                            </Typography>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                ) : (
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      {startTimeLabel} - {endTimeLabel}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container justify="center" className="mt-3">
              <DateTimePicker
                style={{ minWidth: 260 }}
                autoOk
                ampm={false}
                value={selectedDate}
                onChange={handleDateChange}
                format="ddd D MMMM YYYY HH:mm"
                inputVariant="outlined"
                disablePast
                hideTabs
                error={!isValidSchedule}
                label={!isValidSchedule && <p className="mt-2">{t("UNAVAILABLE")}</p>}
              />
            </Grid>

            <Grid container justify="center" alignItems="center" className="mt-4">
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!isValidSchedule || isSubmit || !isButtonEnabled}
                  color="primary"
                >
                  {t("COMMON:CONFIRM")}
                  {isSubmit && <CircularProgress size="24px" className="ml-2" />}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Form>
      </Formik>
    </>
  );
};

export default withQueryDeliverySchedule(Schedule);
