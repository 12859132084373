import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Grid } from "@material-ui/core";
import {
  OrderNumberLabel,
  OrderNumberValue,
  OrderStatus,
  OrderHeaderLabel,
} from "./OrderDetail.styled";

export const OrderHeader = ({
  orderNumber,
  updatedAt,
  state,
  productsAmount,
}) => {
  const { t } = useTranslation(["ORDER_DETAIL", "ORDER_STATE"]);

  return (
    <>
      <Grid item xs={12} className="pl-3 py-2">
        <Grid
          container
          justify="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Grid item>
            <OrderNumberLabel>{t("ORDER_NUMBER")}</OrderNumberLabel>
            <OrderNumberValue>{orderNumber}</OrderNumberValue>
            <span>{moment(updatedAt).format("DD/MM/YYYY HH:mm")}</span>
          </Grid>
          <Grid item>
            <OrderStatus state={state}>{t(`ORDER_STATE:${state}`)}</OrderStatus>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <OrderHeaderLabel>
          <Grid
            className="px-3"
            container
            justify="space-between"
            alignContent="center"
            alignItems="center"
          >
            <Grid item>
              <span>{t("ORDER")}</span>
            </Grid>
            <Grid item>
              <span>
                {productsAmount} {t("ITEMS")}
              </span>
            </Grid>
          </Grid>
        </OrderHeaderLabel>
      </Grid>
    </>
  );
};
