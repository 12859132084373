import React from "react";
import { Helmet } from "react-helmet";

import config from "../../../config";

const {
  CHILL_PAY_API_BASE_URL,
  CHILL_PAY_BASE_SCRIPT_URL,
} = config;

const LANG = "TH";
const CURRENCY = "764"; // THB, look like chill pay support only this currency for now.
const ROUTE_NO = "1"; // Default route in chill pay setting, may move to project base config in the future

const ChillPayPayment = ({ amount, orderNumber , payload, storeName }) => {
  const {
    apiKey,
    merchantId,
    clientIpAddress,
    customerId,
  } = payload;

  return (
    <div>
      <div>
        <Helmet>
          <script
            src={CHILL_PAY_BASE_SCRIPT_URL}
            charset="utf-8"
          ></script>
        </Helmet>
        <div
          dangerouslySetInnerHTML={{
            __html: `<form id="payment-form" action="${CHILL_PAY_API_BASE_URL}/Payment/" method="post" role="form" className="form-horizontal">
            <modernpay:widget id="modernpay-widget-container"
              data-lang="${LANG}"
              data-merchantid="${merchantId}"
              data-amount="${amount}"
              data-orderno="${orderNumber}"
              data-customerid="${customerId}"
              data-clientip="${clientIpAddress}"
              data-routeno="${ROUTE_NO}"
              data-currency="${CURRENCY}"
              data-description="${storeName} ${orderNumber}"
              data-apikey="${apiKey}">
            </modernpay:widget>
          </form>`,
          }}
        />
      </div>
    </div>
  )
}

export default ChillPayPayment
