const NO_IMAGE_AVAL_SRC = "https://storage.googleapis.com/kanda-ai-core/static/fallback-image-placeholder.png";

const REGEX_NUMBER_ONLY = /^[0-9]*$/;
const REGEX_PHONE_NUMBER_ONLY = /^(0[1-9][0-9]{8}|[0-9]{4})$/;

const CART = {
  ACTION: {
    CHECKOUT: "checkout",
    SAVE: "save",
  },
};

const ORDER_STATES = {
  ORDER_PLACED: "ORDER_PLACED",
  WAIT_FOR_PAYMENT_APPROVE: "WAIT_FOR_PAYMENT_APPROVE",
  PREPARE_TO_SHIPPING: "PREPARE_TO_SHIPPING",
  SHIPPED: "SHIPPED",
  CANCELLED: "CANCELLED",
  ABANDONED: "ABANDONED",
};

const QUERY_PARAM = {
  ACTION: "action",
};

const ACTION = {
  CLOSE: "close",
};

const LOCAL_STORAGE = {
  HREF: "href",
};

const PROMOTION = {
  TYPE: {
    // To be deprecated
    BUNDLE: "BUNDLE",
    MULTIPLE_PRODUCT: "MULTIPLE_PRODUCT",
    // New promotion type
    PRODUCT: "PRODUCT",
    SHIPPING: "SHIPPING",
  },
  MESSAGE: {
    REMARK_MULTIPLE_PRODUCT: "*ซื้อรวมกับสินค้าชิ้นอื่นได้",
  },
  SETTING: {
    TYPE: {
      BUY_X_GET_Y: "BUY_X_GET_Y",
      FIXED_AMOUNT_DISCOUNT: "FIXED_AMOUNT_DISCOUNT",
      FIXED_PRICE_DISCOUNT: "FIXED_PRICE_DISCOUNT",
      FREE_GIVEAWAY: "FREE_GIVEAWAY",
      FREE_SHIPPING: "FREE_SHIPPING",
      PERCENTAGE_DISCOUNT: "PERCENTAGE_DISCOUNT",
      FREE_LOWEST_PRICE: "FREE_LOWEST_PRICE",
    },
    CONDITION: {
      BUY_ANY_PRODUCT: "BUY_ANY_PRODUCT",
      BUY_ANY_SELECTED_PRODUCT: "BUY_ANY_SELECTED_PRODUCT",
      BUY_X_PIECE_OF_ANY_PRODUCT: "BUY_X_PIECE_OF_ANY_PRODUCT",
      BUY_X_PIECE_OF_SELECTED_PRODUCT: "BUY_X_PIECE_OF_SELECTED_PRODUCT",
      BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE: "BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE",
      BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT: "BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT",
      BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT: "BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT",
      BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE: "BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE",
    },
  },
  SHIPPING: {
    ENTIRE_SHOP: "ENTIRE_SHOP",
    PRODUCT_GROUP: "PRODUCT_GROUP",
  },
};

const PAYMENT_GATEWAY = {
  TYPE: {
    CHILL_PAY: "PAYMENT_GATEWAY_CHILL_PAY",
    CUSTOM: "PAYMENT_GATEWAY_CUSTOM",
    TWO_C_TWO_P: "PAYMENT_GATEWAY_2C2P",
  },
};

const SHIPPING = {
  METHODS: {
    ALL: "ALL",
  },
};

export {
  ACTION,
  CART,
  LOCAL_STORAGE,
  NO_IMAGE_AVAL_SRC,
  ORDER_STATES,
  PAYMENT_GATEWAY,
  PROMOTION,
  QUERY_PARAM,
  REGEX_NUMBER_ONLY,
  REGEX_PHONE_NUMBER_ONLY,
  SHIPPING,
};
