const MEMBERSHIP = {
  en: {
    MEMBERSHIP_CONFIRMATION: "Membership confirmation",
    VERIFIED_SUCCESS: "Verified success",
    CONFIRM_REF_CODE: "Confirm Ref. code",
    GET_OTP: "Get OTP",
    PHONE_NUMBER: "Phone number",
  },
  th: {
    MEMBERSHIP_CONFIRMATION: "ยืนยันการเป็นสมาชิก",
    VERIFIED_SUCCESS: "ผ่านการตรวจสอบแล้ว",
    CONFIRM_REF_CODE: "ยืนยันรหัสอ้างอิง",
    GET_OTP: "รับ OTP",
    PHONE_NUMBER: "เบอร์โทรศัพท์",
  },
};

export default MEMBERSHIP;
