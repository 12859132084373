import get from "lodash/get"

import config from "../../../../../config"
import i18n from "../../../../../utils/i18n"

const { IS_USING_NEW_SHIPPING_SERVICE } = config

export const getShippingCarrier = (shipping) => {
  if (!IS_USING_NEW_SHIPPING_SERVICE) {
    return shipping.description
  }

  const selectedLanguage = get(i18n, "languages.0") || "TH"
  if (selectedLanguage === "TH") {
    return shipping.thDisplayName
  }

  return shipping.enDisplayName
}
