export const TYPE = {
  SKU: "SKU",
  SINGLE_SKU_WITH_OPTIONS: "SINGLE_SKU_WITH_OPTIONS",
  DIGITAL_CONTENT: "DIGITAL_CONTENT",
};

export const QUANTITY_LIMIT = 99;

export const DigitalContentAvailability = {
  PERIOD_AFTER_PURCHASED: "PERIOD_AFTER_PURCHASED",
  EXPIRY_DATE: "EXPIRY_DATE",
  DATE_RANGE: "DATE_RANGE",
  NO_EXPIRY: "NO_EXPIRY",
};

export const ProductOutputType = {
  STATIC: "STATIC",
  QR_CODE: "QR_CODE",
  CODE_IMPORTED: "CODE_IMPORTED",
  CODE_AUTO_GENERATED: "CODE_AUTO_GENERATED",
  CODE_GENERATED_FROM_OTHER: "CODE_GENERATED_FROM_OTHER",
};
