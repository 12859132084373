import React from "react";
import Grid from "@material-ui/core/Grid";

import { IconWrapper, IcCheck } from "../Icon";
import {
  CheckBoxContainer,
  CheckButton,
  SelectedIcon,
  StyledGridCheckButton,
  ChildrenWrapper,
} from "./styled";

const CustomCheckBox = (props) => {
  const {
    disabled,
    isActive,
    isRadio,
    className,
    onClick,
    children,
    noBorder,
  } = props;
  return (
    <CheckBoxContainer
      className={className}
      disabled={disabled}
      isActive={isActive}
      onClick={onClick}
      isNoBorder={noBorder}
    >
      <Grid container justify="flex-start" alignItems="center">
        <StyledGridCheckButton item>
          <CheckButton isActive={isActive} isRadio={isRadio}>
            {isActive &&
              (isRadio ? (
                <SelectedIcon />
              ) : (
                <div className="pl-1 pt-1">
                  <IconWrapper component={IcCheck} />
                </div>
              ))}
          </CheckButton>
        </StyledGridCheckButton>
        <ChildrenWrapper item className="pl-3">
          {children}
        </ChildrenWrapper>
      </Grid>
    </CheckBoxContainer>
  );
};

export default CustomCheckBox;
