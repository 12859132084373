import React from "react";
import { useTranslation } from "react-i18next";
import { Paper, Button } from "@material-ui/core";
import styled from "styled-components";

import { ORDER_STATES } from "../../../constant";
import HttpClientService from "../../../services/http-client";

const ORDER_STATUS_DESCRIPTION = {
  [ORDER_STATES.WAIT_FOR_PAYMENT_APPROVE]: "WAIT_FOR_PAYMENT_APPROVE",
  [ORDER_STATES.PREPARE_TO_SHIPPING]: "PREPARE_TO_SHIPPING",
  [ORDER_STATES.SHIPPED]: "SHIPPED",
  [ORDER_STATES.CANCELLED]: "CANCELLED",
  [ORDER_STATES.ABANDONED]: "ABANDONED",
};

const OrderStatusText = styled.div`
  font-size: 14px;
`;

const YourOrderText = styled.strong`
  font-size: 16px;
`;

const OrderStatusTextGroup = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
`;

const OrderStatusContainer = styled(Paper)`
  display: flex;
  margin-bottom: 8px;
  overflow: hidden;
`;

const ColorBox = styled.div`
  width: 60px;
  background-color: ${({ theme }) => theme.COLORS.Primary};
`;

const FooterText = styled.div`
  color: #42495a;
  font-size: 14px;
`;

const Container = styled.div`
  margin: 18px;
`;

const CloseButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled(Button)`
  max-width: 90px;
`;

export default ({ orderNumber, status }) => {
  const { t } = useTranslation(["PAYMENT"]);

  return (
    <Container>
      <OrderStatusContainer>
        <ColorBox />
        <OrderStatusTextGroup>
          <YourOrderText>{`${t("ORDER_NUMBER")} ${orderNumber}`}</YourOrderText>
          <OrderStatusText>
            {t(ORDER_STATUS_DESCRIPTION[status])}
          </OrderStatusText>
        </OrderStatusTextGroup>
      </OrderStatusContainer>

      <FooterText>{t("CONTACT_US")}</FooterText>
      <CloseButtonWrapper>
        <CloseButton
          variant="contained"
          onClick={() => {
            const httpClient = new HttpClientService();
            httpClient.closeWindow();
          }}
        >
          {t("CLOSE")}
        </CloseButton>
      </CloseButtonWrapper>
    </Container>
  );
};
