import { COLORS } from "../../../constants/index";
import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const ScrollContainer = styled.div`
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  background: ${COLORS.LightGray};
`;

export const Card = styled(Grid)`
  display: flex;
  background: ${COLORS.White};
  border-radius: 10px;
`;

export const ProductTitle = styled.div`
  display: flex;
  font-weight: bold;
  color: ${COLORS.Dark};
  align-content: center;
  align-items: center;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const ProductSKU = styled.div`
  display: flex;
  font-size: 12px;
  align-content: center;
  align-items: center;
  color: ${COLORS.DarkGray};
  flex-wrap: wrap;
`;

export const ProductDescription = styled.div`
  display: flex;
  min-height: 10px;
  max-height: 34px;
  font-size: 12px;
  align-content: flex-start;
  align-items: flex-start;
  color: ${COLORS.DarkGray};
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: hidden;
`;

export const PromotionDescription = styled.li`
  display: list-item !important;
  min-height: 10px;
  font-size: 12px;
  align-content: flex-start;
  align-items: flex-start;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: ${COLORS.Error};
`;

export const ProductPrice = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.Primary};
  flex-wrap: wrap;
`;

export const ProductImage = styled.img`
  width: 124px;
  height: 124px;
  object-fit: cover;
  display: flex;
`;

export const ProductPromotionText = styled.div`
  background: ${({ theme }) => theme.COLORS.Primary};
  color: ${({ theme }) => theme.COLORS.White};
  border-radius: 10px 0 10px 0;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  line-height: 12px;
  padding: 6px;
  z-index: 1;
  position: relative;
  max-width: 114px;
  height: fit-content;
  width: fit-content;
`;
