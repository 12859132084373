import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@material-ui/core";
import { COLORS, PRODUCT, PROMOTION } from "../../../constants/index";
import { get, truncate } from "lodash";

import { NO_IMAGE_AVAL_SRC } from "../../../constant";
import {
  Card,
  ProductDescription,
  PromotionDescription,
  ProductImage,
  ProductPrice,
  ProductPromotionText,
  ProductSKU,
  ProductTitle,
} from "./ProductItem.styled";
import { calculateMinAndMaxPrice, getProductSKUData, getRelatedPromotionByProducts } from "../../../utils/product";
import { getRelatedPromotionForProductHeaderBadge, sortPromotionByPriority } from "../../../utils/promotion";

const ProductItem = ({ currentProduct, onClickAddToCart, promotions }) => {
  const { t } = useTranslation(["PRODUCT_SEARCH"]);

  const productType = get(currentProduct, 'type', PRODUCT.TYPE.SKU)

  let filteredPromotions = promotions
  if (productType === PRODUCT.TYPE.DIGITAL_CONTENT) {
    filteredPromotions = promotions.filter(promotion => promotion.type !== PROMOTION.TYPE.SHIPPING)
  }

  const { minPrice, maxPrice } = calculateMinAndMaxPrice(currentProduct);
  const relatedPromotionByProducts = getRelatedPromotionByProducts([currentProduct], filteredPromotions);
  const sortedPromotions = relatedPromotionByProducts.sort(sortPromotionByPriority);

  const skuData = getProductSKUData(currentProduct);

  const promotionBadge = getRelatedPromotionForProductHeaderBadge(relatedPromotionByProducts);
  const promotionBadgeText = get(promotionBadge, "shortName");

  return (
    <Card
      container
      item
      xs={12}
      key={get(currentProduct, "name")}
      alignItems="flex-start"
      alignContent="center"
      className="mb-2 mx-2"
      style={{ overflow: "hidden" }}
    >
      {promotionBadgeText && <ProductPromotionText>{promotionBadgeText}</ProductPromotionText>}

      <Grid
        container
        item
        alignContent="center"
        alignItems="center"
        xs={12}
        style={{
          borderRadius: "10px 0px 0px 10px",
          overflow: "hidden",
          marginTop: sortedPromotions && sortedPromotions.length > 0 ? "-24px" : "0px",
        }}
      >
        <ProductImage alt="" src={get(currentProduct, "images.0.src") || NO_IMAGE_AVAL_SRC} />
        <Grid item style={{ flex: "1" }} className="pl-3 pr-2">
          <Grid item>
            <ProductTitle className="pt-2 pb-1">{get(currentProduct, "name")}</ProductTitle>
            <ProductDescription className="mb-1 flex-1 wordBreakAll ">
              {truncate(` ${get(currentProduct, "description")} `, {
                length: 80,
              })}
            </ProductDescription>
          </Grid>
          {Object.keys(skuData).length > 0 ? (
            <Grid item>
              {Object.keys(skuData).map(skuType => {
                return (
                  <ProductSKU key={skuType} className="pt-1">
                    <span className="font-weight-bold">{skuType} : </span>
                    <span>
                      {truncate(` ${[...new Set(skuData[skuType])].join(", ")} `, {
                        length: 22,
                      })}
                    </span>
                  </ProductSKU>
                );
              })}
            </Grid>
          ) : (
            <div style={{ minHeight: "34px" }} />
          )}

          {sortedPromotions && sortedPromotions.length > 0 && (
            <Grid item className="mt-1">
              {sortedPromotions.map(promotion => {
                return (
                  <PromotionDescription>
                    {get(promotion, "description") ? get(promotion, "description") : get(promotion, "name")}
                  </PromotionDescription>
                );
              })}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            container
            justify="space-between"
            alignContent="center"
            alignItems="center"
            className="pt-2"
          >
            <Grid item style={{ marginBottom: "6px" }}>
              <ProductPrice>{minPrice === maxPrice ? `฿${minPrice}` : `฿${minPrice} - ฿${maxPrice}`}</ProductPrice>
            </Grid>
            <Grid item justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                style={{
                  borderRadius: "10px",
                  fontSize: "11px",
                  fontWeight: "bold",
                  padding: "4px",
                  marginBottom: "6px",
                  color: COLORS.White,
                }}
                onClick={() => onClickAddToCart(currentProduct)}
              >
                {t("SELECT")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
export default ProductItem;
