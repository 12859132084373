import React from "react";
import { Grid } from "@material-ui/core";
import { withQueryProjectIdFromLIFFURI } from "../../../hoc";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import RequestOTP from "./RequestOTP";

import HttpClientService from "../../../services/http-client";
const httpClientService = new HttpClientService();

class Membership extends React.Component {
  state = { responseOTP: null, responseVerifyOTP: null };

  componentWillMount = async () => {};

  getOTP = async (phoneNumber) => {
    const { projectId } = this.props;
    const responseData = await httpClientService.generateOTP(
      projectId,
      phoneNumber
    );

    this.setState({
      responseOTP: responseData,
      // example response data = {
      //   isExistingMember: true || false,
      //   refCode: "VA12345",
      // },
    });
  };

  sendVerifyOTP = async (OTP, refCode) => {
    const { projectId } = this.props;
    const responseData = await httpClientService.verifyOTP(
      projectId,
      OTP,
      refCode
    );

    this.setState({
      responseVerifyOTP: responseData,
      // example response data = { data: "", status: 200 },
    });

    // should close if success
    // await httpClientService.closeWindow();
  };

  render() {
    const { responseOTP, responseVerifyOTP } = this.state;
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <title>{t("MEMBERSHIP_CONFIRMATION")}</title>
        </Helmet>

        <Grid container direction="column">
          <RequestOTP
            getOTP={this.getOTP}
            sendVerifyOTP={this.sendVerifyOTP}
            refCode={responseOTP && responseOTP.refCode}
            isOTPVerified={
              responseVerifyOTP &&
              responseVerifyOTP.status &&
              responseVerifyOTP.status === 200
            }
          />
        </Grid>
      </>
    );
  }
}
export default withRouter(
  withQueryProjectIdFromLIFFURI(withTranslation(["MEMBERSHIP"])(Membership))
);
