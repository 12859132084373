const PAYMENT = {
  en: {
    ABANDONED: "Abandoned",
    CANCELLED: "Cancelled",
    CLOSE: "Close",
    CONTACT_US: "If you have any question. Please chat or contact admin.",
    ORDER_NUMBER: "Order No.",
    PREPARE_TO_SHIPPING: "Ready to ship",
    SHIPPED: "Completed",
    WAIT_FOR_PAYMENT_APPROVE: "Ready to approve",
  },
  th: {
    ABANDONED: "ได้ถูกยกเลิกไปเรียบร้อยแล้ว",
    CANCELLED: "ได้ถูกยกเลิกไปเรียบร้อยแล้ว",
    CLOSE: "ปิดหน้านี้",
    CONTACT_US: "ถ้ามีข้อสงสัยใด สามารถพิมพ์สอบถามหรือติดต่อแอดมินได้เลย",
    ORDER_NUMBER: "หมายเลขรายการสั่งซื้อ",
    PREPARE_TO_SHIPPING: "กำลังรอการจัดส่งอยู่",
    SHIPPED: "ได้ถูกจัดส่งเรียบร้อยแล้ว",
    WAIT_FOR_PAYMENT_APPROVE: "กำลังรอตรวจสอบการชำระเงินอยู่",
  },
};

export default PAYMENT;
