// TODO: Update naming to be capitalized
const config = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
  publicUrl: process.env.PUBLIC_URL,
  LIFF_ID: process.env.REACT_APP_LIFF_ID,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV || "development",
  WEBVIEW_FACEBOOK_TOKEN_HEADER: process.env.REACT_APP_WEBVIEW_FACEBOOK_TOKEN_HEADER || 'x-facebook-token',
  WEBVIEW_LINE_TOKEN_HEADER: process.env.REACT_APP_WEBVIEW_LINE_TOKEN_HEADER || 'x-line-token',
  WEBVIEW_DEEPLE_TOKEN_HEADER: process.env.REACT_APP_WEBVIEW_DEEPLE_TOKEN_HEADER || 'x-deeple-token',
  CHILL_PAY_API_BASE_URL: process.env.REACT_APP_CHILL_PAY_API_BASE_URL || 'https://sandbox-cdnv3.chillpay.co',
  CHILL_PAY_BASE_SCRIPT_URL: process.env.REACT_APP_CHILL_PAY_BASE_SCRIPT_URL || 'https://sandbox-cdnv3.chillpay.co/js/widgets.js?v=1.00',
  TWO_C_TWO_P_PAYMENT_URL: process.env.REACT_APP_TWO_C_TWO_P_PAYMENT_URL || 'https://demo2.2c2p.com/2C2PFrontEnd/RedirectV3/payment',
  IS_USING_NEW_SHIPPING_SERVICE: process.env.REACT_APP_IS_USING_NEW_SHIPPING_SERVICE === 'true'
};

export default config;

// REACT_APP_API_BASE_URL=https://sme-api.deeple.ai REACT_APP_LIFF_ID=1651109655-3kOXgmVD yarn build
