export const SHIPPING_METHOD = {
  STANDARD: "STANDARD",
  EXPRESS: "EXPRESS",
  PICK_UP: "PICK_UP",
  DIGITAL: "DIGITAL",
};

export const SHIPPING_METHOD_EN = {
  STANDARD: "Standard",
  EXPRESS: "Express",
  PICK_UP: "Pickup",
};

export const SHIPPING_METHOD_TH = {
  STANDARD: "ส่งธรรมดา",
  EXPRESS: "ส่งด่วนพิเศษ",
  PICK_UP: "รับสินค้าด้วยตัวเอง",
};

export const SHIPPING_CALCULATE_TYPE = {
  DISTANCE_BASE_PRICE: "DISTANCE_BASE_PRICE",
  FIXED_PRICE: "FIXED_PRICE",
  LALAMOVE: "LALAMOVE",
  POSTAL_CODE_BASED_PRICE: "POSTAL_CODE_BASED_PRICE",
  SHIPPOP: "SHIPPOP",
};

export const SHIPPING_TYPE = {
  DIGITAL: 'DIGITAL',
  FIXED_PRICE: 'FIXED_PRICE',
  LALAMOVE: 'LALAMOVE',
  MANUAL: 'MANUAL',
  PICKUP: 'PICKUP',
  POSTAL_CODE: 'POSTAL_CODE',
  SHIPPOP: 'SHIPPOP',
}

export const SHIPPING_PRICE_OPTION = {
  FIXED_PRICE: 'FIXED_PRICE',
  NONE: 'NONE',
  PERCENTAGE: 'PERCENTAGE',
}
