import React from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import has from "lodash/has";
import get from "lodash/get";
import styled from "styled-components";
import { withQueryProductId, withProductDetail, withPromotionDetail } from "../../../hoc";
import HttpClientService from "../../../services/http-client";
import { ProductDetailPresentation } from "./ProductDetailPresentation";
import { ProductDetailFoodPresentation } from "./ProductDetailFoodPresentation";
import { DigitalContentProductDetailPresentation } from "./DigitalContentProductDetailPresentation";
import { findSKUFromProductType, generateRenderSKUObject, getVariableTypes } from "../../../utils/product";
import { TYPE } from "../../../constants/product";

const httpClientService = new HttpClientService();

const DEFAULT_SKU_AMOUNT = 1;

const OutOfStock = styled.h2`
  text-align: center;
  position: absolute;
  top: 40%;
  width: 100%;
  color: ${({ theme }) => theme.COLORS.DarkMed};
`;

class ProductDetailContainer extends React.Component {
  constructor(props) {
    super(props);

    const productSKUOptions = get(props, "productDetail.productSKUs[0].options");
    const firstProductSKU = get(props, "productDetail.productSKUs[0]");
    const selectedProductSKUVariableTypesObject = firstProductSKU && getVariableTypes(firstProductSKU);
    const { renderSKU } = (selectedProductSKUVariableTypesObject &&
      generateRenderSKUObject(props.productDetail, selectedProductSKUVariableTypesObject)) || { renderSKU: {} };

    this.state = {
      productSKUOptions: productSKUOptions || [],
      renderSKU: renderSKU,
      amount: DEFAULT_SKU_AMOUNT,
      selectedSKU: selectedProductSKUVariableTypesObject,
    };
  }

  componentDidMount() {
    window.document.title = `Deeple ${this.props.t("PRODUCT_DETAIL")}`;
  }

  onSelectOption = (optionIndex, choiceIndex, isMultiple) => {
    const { productSKUOptions, optionErrorIndexes } = this.state;
    const newProductSKUOptions = productSKUOptions.map((option, oIndex) => {
      if (optionIndex === oIndex) {
        const newChoices = option.choices.map((choice, cIndex) => {
          if (choiceIndex === cIndex) {
            return {
              ...choice,
              isSelected: isMultiple ? !choice.isSelected : true,
            };
          }

          return isMultiple
            ? choice
            : {
                ...choice,
                isSelected: false,
              };
        });

        return {
          ...option,
          choices: newChoices,
        };
      }

      return option;
    });

    if (optionErrorIndexes) {
      const index = optionErrorIndexes.indexOf(optionIndex);
      if (index !== -1) {
        optionErrorIndexes.splice(index, 1);
      }
    }

    this.setState({
      productSKUOptions: newProductSKUOptions,
      optionErrorIndexes,
    });
  };

  onChangeNote = note => {
    this.setState({
      note,
    });
  };

  onChangeSKU = (key, value) => {
    const { productDetail } = this.props;

    const { renderSKU, selectedSKU: selectedProductSKU } = generateRenderSKUObject(productDetail, {
      ...this.state.selectedSKU,
      [key]: value,
    });

    this.setState({
      renderSKU,
      amount: DEFAULT_SKU_AMOUNT,
      selectedSKU: selectedProductSKU,
    });
  };

  onChangeAmount = (adder, stock) => {
    if (this.state.amount + adder <= stock && this.state.amount + adder >= 0) {
      this.setState({ amount: this.state.amount + adder });
    }
  };

  onClickAddToCart = async () => {
    const { projectId, productId, productDetail, t } = this.props;
    const { amount, selectedSKU: selectedProductSKU, productSKUOptions, note } = this.state;
    let isError = false;

    if (productSKUOptions.length) {
      const optionErrorIndexes = productSKUOptions.reduce((errorIndexes, option, index) => {
        const isNoSelectedChoice = option.choices.every(choice => !choice.isSelected);

        return option.isActive && option.isRequired && isNoSelectedChoice ? [...errorIndexes, index] : errorIndexes;
      }, []);

      if (optionErrorIndexes.length) {
        isError = true;
        toast.error(t("ERROR_SELECT_OPTIONS_PRODUCT"), {
          position: toast.POSITION.TOP_CENTER,
        });

        document.getElementById(`option${optionErrorIndexes[0]}`).scrollIntoView();

        this.setState({
          optionErrorIndexes,
        });
      }
    }

    if (!isError) {
      const { id, isFree } = findSKUFromProductType({
        product: productDetail,
        selectedSKU: selectedProductSKU,
      });

      await httpClientService.addToCart(projectId, productId, id, {
        amount,
        isFree,
        options: productSKUOptions,
        note,
      });
      await httpClientService.closeWindow();
    }
  };

  render() {
    const { productDetail, promotionDetail, t } = this.props;
    const promotions = get(promotionDetail, "promotions") || [];
    const { selectedSKU: selectedProductSKU, amount, renderSKU, productSKUOptions, optionErrorIndexes } = this.state;
    const productName = productDetail.name;
    const productDetailType = productDetail.type;

    if (!has(productDetail, "productSKUs[0]")) {
      return <OutOfStock>{t("OUT_OF_STOCK")}</OutOfStock>;
    }

    return (
      <>
        <ToastContainer />
        <Helmet>
          <title>{productName}</title>
        </Helmet>
        {productDetailType === TYPE.SINGLE_SKU_WITH_OPTIONS && (
          <ProductDetailFoodPresentation
            amount={amount}
            productSKUOptions={productSKUOptions}
            productDetail={productDetail}
            onChangeAmount={this.onChangeAmount}
            onClickAddToCart={this.onClickAddToCart}
            promotions={promotions}
            onSelectOption={this.onSelectOption}
            onChangeNote={this.onChangeNote}
            optionErrorIndexes={optionErrorIndexes}
          />
        )}
        {productDetailType === TYPE.SKU && (
          <ProductDetailPresentation
            amount={amount}
            selectedSKU={selectedProductSKU}
            productSKUs={productDetail.productSKUs}
            skuForRender={renderSKU}
            productDetail={productDetail}
            onChangeSKU={this.onChangeSKU}
            onChangeAmount={this.onChangeAmount}
            onClickAddToCart={this.onClickAddToCart}
            promotions={promotions}
          />
        )}
        {productDetailType === TYPE.DIGITAL_CONTENT && (
          <DigitalContentProductDetailPresentation
            amount={amount}
            selectedSKU={selectedProductSKU}
            productSKUs={productDetail.productSKUs}
            skuForRender={renderSKU}
            productDetail={productDetail}
            onChangeSKU={this.onChangeSKU}
            onChangeAmount={this.onChangeAmount}
            onClickAddToCart={this.onClickAddToCart}
            promotions={promotions}
          />
        )}
      </>
    );
  }
}

export default withRouter(
  withQueryProductId(
    withProductDetail(withPromotionDetail(withTranslation(["PRODUCT_DETAIL"])(ProductDetailContainer))),
  ),
);
