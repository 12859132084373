import { CircularProgress } from "@material-ui/core";
import { get } from "lodash";
import React from "react";

import HttpClientService from "../services/http-client";

const httpClientService = new HttpClientService();

export default Component =>
  class extends React.Component {
    state = {
      loading: true,
      data: undefined,
    };

    async componentDidMount() {
      let data = undefined;

      try {
        const { projectId, orderId } = this.props;
        const resp = await httpClientService.getOrderDetail(projectId, orderId);

        data = get(resp, "data");
      } catch (e) {
        console.error(e);
      }

      this.setState({
        loading: false,
        data,
      });
    }

    render() {
      const { loading, data } = this.state;

      if (loading) {
        return (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={150} />
          </div>
        );
      } else if (!loading && !data) {
        throw new Error();
      }

      return <Component orderDetail={data} {...this.props} />;
    }
  };
