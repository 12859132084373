import React from "react";
import get from "lodash/get";

import Typography from "@material-ui/core/Typography";

import config from "../../../../../config"
import { SHIPPING_METHOD } from "../../../../../constants/index";
import { convertPriceFormat } from "../../../../../utils/common";

const { IS_USING_NEW_SHIPPING_SERVICE } = config

const RemarkDetail = ({ shipping, t }) => {
  if (!IS_USING_NEW_SHIPPING_SERVICE) {
    const isCODPayment = get(shipping, "isCODPayment");
    const calculateType = get(shipping, "calculateType");
    const depositThreshold = get(shipping, "CODPayment.depositThreshold");
    const isShippingDistanceBaseType = calculateType === SHIPPING_METHOD.SHIPPING_CALCULATE_TYPE.DISTANCE_BASE_PRICE;

    return (
      <>
        {isShippingDistanceBaseType && (
          <>
            <Typography variant="body3">
              <strong>{t("WAITING_FOR_ADMIN_CONFIRM_SHIPPING_COST")}</strong>
            </Typography>
            <br />
          </>
        )}
        {isCODPayment && Boolean(depositThreshold) && (
          <>
            <Typography variant="body3">
              <strong>{t("MINIMUM_TOTAL_PRICE_PER_ORDER")}</strong> : ฿{convertPriceFormat(depositThreshold)}
            </Typography>
            <br />
          </>
        )}
      </>
    );
  }

  const isCod = get(shipping, "isCod") || false;
  const shippingType = get(shipping, "type");
  const thresholdType = get(shipping, "codInformation.threshold.type");
  const thresholdFee = get(shipping, "codInformation.threshold.fee")
  const hasThreshold = thresholdType !== SHIPPING_METHOD.SHIPPING_PRICE_OPTION.NONE
  const isManualShipping = shippingType === SHIPPING_METHOD.SHIPPING_TYPE.MANUAL;

  return (
    <>
      {isManualShipping && (
        <>
          <Typography variant="body3">
            <strong>{t("WAITING_FOR_ADMIN_CONFIRM_SHIPPING_COST")}</strong>
          </Typography>
          <br />
        </>
      )}
      {isCod && hasThreshold && (
        <>
          <Typography variant="body3">
            <strong>{t("MINIMUM_TOTAL_PRICE_PER_ORDER")}</strong> : ฿{convertPriceFormat(thresholdFee)}
          </Typography>
          <br />
        </>
      )}
    </>
  );
}

export default RemarkDetail
