const DAYS = {
  en: {
    SUNDAY: "Sunday",
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
  },
  th: {
    SUNDAY: "อาทิตย์",
    MONDAY: "จันทร์",
    TUESDAY: "อังคาร",
    WEDNESDAY: "พุธ",
    THURSDAY: "พฤหัส",
    FRIDAY: "ศุกร์",
    SATURDAY: "เสาร์",
  },
};

export default DAYS;
