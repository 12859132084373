import * as yup from "yup";

import { REGEX_PHONE_NUMBER_ONLY, REGEX_NUMBER_ONLY } from "../../../constant";

/**
 * Get address form validation
 * @param {unknown} t
 * @param {boolean} isEmailRequired
 * @param {boolean} hasSKUTypeProduct
 * @param {boolean} hasDigitalContentTypeProduct
 * @returns {yup.Schema}
 */
export const getValidationSchema = (t, isEmailRequired, hasSKUTypeProduct, hasDigitalContentTypeProduct) => {
  const firstNameIsRequired = t("FORM_REQUIRED", {
    fieldName: t("FIRST_NAME"),
  });

  const lastNameIsRequired = t("FORM_REQUIRED", {
    fieldName: t("LAST_NAME"),
  });

  const addressIsRequired = t("FORM_REQUIRED", {
    fieldName: t("STREET_ADDRESS"),
  });

  const districtIsRequired = t("FORM_REQUIRED", {
    fieldName: t("DISTRICT"),
    interpolation: { escapeValue: false },
  });

  const subDistrictIsRequired = t("FORM_REQUIRED", {
    fieldName: t("SUB_DISTRICT"),
    interpolation: { escapeValue: false },
  });

  const provinceIsRequired = t("FORM_REQUIRED", {
    fieldName: t("PROVINCE"),
  });

  const postalCodeIsRequired = t("FORM_REQUIRED", {
    fieldName: t("POSTAL_CODE"),
  });

  const postalCodeIsRequiredLength = t("FORM_LENGTH", {
    fieldName: t("POSTAL_CODE"),
    length: 5,
  });

  const phoneNumberIsRequiredRangeLength = t("FORM_RANGE_LENGTH", {
    fieldName: t("MOBILE_PHONE_NUMBER"),
    min: 10,
    max: 10,
  });

  const phoneNumberIsRequired = t("FORM_REQUIRED", {
    fieldName: t("MOBILE_PHONE_NUMBER"),
  });

  const emailIsRequired = t("FORM_REQUIRED", {
    fieldName: t("EMAIL"),
  });

  return yup.object({
    firstName: yup.string().required(firstNameIsRequired),
    lastName: yup.string().required(lastNameIsRequired),
    streetAddress: hasSKUTypeProduct ? yup.string().required(addressIsRequired) : yup.string().optional(),
    district: hasSKUTypeProduct ? yup.string().required(districtIsRequired) : yup.string().optional(),
    subDistrict: hasSKUTypeProduct ? yup.string().required(subDistrictIsRequired) : yup.string().optional(),
    province: hasSKUTypeProduct ? yup.string().required(provinceIsRequired) : yup.string().optional(),
    postalCode: hasSKUTypeProduct
      ? yup
          .string()
          .length(5, postalCodeIsRequiredLength)
          .matches(REGEX_NUMBER_ONLY, {
            message: t("FORM_NUMBER_ONLY"),
          })
          .required(postalCodeIsRequired)
      : yup.string().optional(),
    phoneNumber: yup
      .string()
      .matches(REGEX_PHONE_NUMBER_ONLY, {
        message: t("FORM_PHONE_NUMBER_ONLY"),
      })
      .min(10, phoneNumberIsRequiredRangeLength)
      .max(10, phoneNumberIsRequiredRangeLength)
      .required(phoneNumberIsRequired),
    email:
      hasDigitalContentTypeProduct || isEmailRequired
        ? yup
            .string()
            .typeError(emailIsRequired)
            .email()
            .required(emailIsRequired)
        : yup
            .string()
            .email()
            .optional()
            .nullable(),
  });
};
