import React from 'react'
import { CircularProgress, Grid  } from '@material-ui/core'

const Loading = () => (
  <Grid container item>
    <div
      style={{
        display: "flex",
        margin: "0 auto",
        marginTop: "100px"
      }}
    >
      <CircularProgress size={150} />
    </div>
  </Grid>
);

export default Loading
