import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import {
  DescriptionText,
  NoDescriptionText,
  PropertyTextKey,
  PropertyTextValue,
  TitleSectionText,
} from "./ProductDetail.styled";

export const RenderPropertiesTable = (properties, t) => {
  if (!properties || !properties.length) {
    return <NoDescriptionText>-</NoDescriptionText>;
  }
  return (
    <Grid container>
      {properties.map((property, i) => {
        return (
          <Grid item container key={i} className="py-2">
            <Grid item xs={6}>
              <PropertyTextKey>{property.name}</PropertyTextKey>
            </Grid>
            <Grid item xs={6}>
              <PropertyTextValue>{property.value}</PropertyTextValue>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const ProductDescriptionAndProperty = ({ description, properties }) => {
  const { t } = useTranslation(["PRODUCT_DETAIL"]);

  return (
    <Grid container item direction="row" xs={12}>
      <Grid item xs={12}>
        <TitleSectionText className="pb-2">{t("PRODUCT_DETAIL")}</TitleSectionText>
      </Grid>

      <Grid item xs={12}>
        {description ? <DescriptionText>{description}</DescriptionText> : <NoDescriptionText>-</NoDescriptionText>}
      </Grid>

      <Grid item xs={12}>
        <TitleSectionText className="py-2">{t("PROPERTIES")}</TitleSectionText>
      </Grid>

      <Grid item xs={12}>
        {RenderPropertiesTable(properties, t)}
      </Grid>
    </Grid>
  );
};
