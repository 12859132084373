import { useEffect, useState } from "react";
import { createMuiTheme } from "@material-ui/core/styles";

import HttpClientService from "./services/http-client";

const httpClientService = new HttpClientService();

const defaultPrimaryPalette = {
  light: "#b097ff",
  main: "#663ee8",
  dark: "#4521bb",
  contrastText: "#fff",
};

export const theme = (projectId) => {
  const [primaryPalette, setPrimaryPalette] = useState(defaultPrimaryPalette);

  useEffect(() => {
    const getProjectConfiguration = async () => {
      if (projectId) {
        try {
          const {
            data: { theme },
          } = await httpClientService.getProjectConfiguration(projectId);

          if (theme && theme.color) {
            const newPrimaryPalette = {
              light: "#b097ff",
              main: theme.color.primary,
              dark: "#4521bb",
              contrastText: "#fff",
            };
            setPrimaryPalette(newPrimaryPalette);
          }
        } catch (err) {
          await httpClientService.closeWindow();
        }
      }
    };
    getProjectConfiguration();
  }, []);

  return createMuiTheme({
    palette: {
      primary: primaryPalette,
      secondary: {
        light: "#ff4081",
        main: "#f50057",
        dark: "#c51162",
        contrastText: "#fff",
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff",
      },
      warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      info: {
        light: "#64b5f6",
        main: "#2196f3",
        dark: "#1976d2",
        contrastText: "#fff",
      },
      success: {
        light: "#81c784",
        main: "#4caf50",
        dark: "#388e3c",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
    },
  });
};
