export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "HH:mm:ssZ";
export const TIME_INPUT_FORMAT = "HH:mm";

export const DAYS = {
  SUNDAY: "SUNDAY",
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
};

export const DATE_DIGITAL_CONTENT_EXPIRED_AT_FORMAT = "YYYY-MM-DD";
export const DATE_DIGITAL_CONTENT_DISPLAY_FORMAT = "DD MMMM YYYY";
export const FULL_DATE_TIME_DISPLAY_FORMAT = "ddd D MMMM YYYY HH:mm";
