module.exports = {
  TYPE: {
    PRODUCT: 'PRODUCT',
    SHIPPING: 'SHIPPING',
  },
  SETTING: {
    TYPE: {
      BUY_X_GET_Y: 'BUY_X_GET_Y',
      FIXED_AMOUNT_DISCOUNT: 'FIXED_AMOUNT_DISCOUNT',
      FIXED_PRICE_DISCOUNT: 'FIXED_PRICE_DISCOUNT',
      FREE_GIVEAWAY: 'FREE_GIVEAWAY',
      FREE_SHIPPING: 'FREE_SHIPPING',
      PERCENTAGE_DISCOUNT: 'PERCENTAGE_DISCOUNT',
    },
    CONDITION: {
      BUY_ANY_PRODUCT: 'BUY_ANY_PRODUCT',
      BUY_ANY_SELECTED_PRODUCT: 'BUY_ANY_SELECTED_PRODUCT',
      BUY_X_PIECE_OF_ANY_PRODUCT: 'BUY_X_PIECE_OF_ANY_PRODUCT',
      BUY_X_PIECE_OF_SELECTED_PRODUCT: 'BUY_X_PIECE_OF_SELECTED_PRODUCT',
      BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE: 'BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE',
      BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT: 'BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT',
      BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT: 'BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT',
      BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE: 'BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE',
    },
  },
}
