import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { get } from "lodash";
import { withTranslation } from "react-i18next";

import { withQueryProjectId, withSearchParams } from "../../../hoc";
import HttpClientService from "../../../services/http-client";
import ProductItem from "./ProductItem";
import { ScrollContainer } from "./ProductItem.styled";

const httpClientService = new HttpClientService();

class SearchProducts extends React.Component {
  state = {
    products: [],
    isLoading: false,
    promotions: [],
    noMoreData: false,
    pageCount: 0,
    itemCount: 0,
  };
  limit = 10;

  componentWillMount = async () => {
    const { projectId, searchParams } = this.props;
    this.setState({
      isLoading: true,
    });
    const [productSearch, promotions] = await Promise.all([
      httpClientService.getProductSearch(projectId, searchParams, 0, this.limit),
      httpClientService.getAllActivePromotion(projectId),
    ]);

    this.setState({
      products: get(productSearch, "data.products", []),
      promotions: get(promotions, "data.promotions", []),
      itemCount: get(productSearch, "data.products", []).length,
      pageCount: 1,
      isLoading: false,
    });
  };

  onClickAddToCart = async currentProduct => {
    const { projectId } = this.props;
    const productName = get(currentProduct, "name");
    await httpClientService.selectProduct(projectId, productName);
    await httpClientService.closeWindow();
  };

  handleOnScroll = async (event, loading, products) => {
    const { projectId, searchParams } = this.props;
    const isShowPromotion = searchParams && searchParams.is_product_promotion;
    const { noMoreData, pageCount, itemCount } = this.state;

    if (
      !noMoreData &&
      !loading &&
      Boolean(Number(event.target.scrollTop) + 1 >= Number(event.target.scrollHeight) - Number(window.innerHeight))
    ) {
      this.setState({
        isLoading: true,
        pageCount: pageCount + 1,
      });
      // TODO: is_product_promotion pagination is pageCount + 1 but get product by category pagination by itemCount
      const productSearch = await httpClientService.getProductSearch(
        projectId,
        searchParams,
        isShowPromotion ? pageCount * this.limit + 1 : itemCount,
        this.limit,
      );

      if (get(productSearch, "data.products", []).length) {
        this.setState({
          products: [...products, ...get(productSearch, "data.products", [])],
          isLoading: false,
          noMoreData: false,
          itemCount: itemCount + get(productSearch, "data.products", []).length,
        });
      } else {
        this.setState({
          noMoreData: true,
        });
      }
    }
  };

  render() {
    const { products, isLoading, promotions, noMoreData } = this.state;
    const { t } = this.props;

    return (
      <>
        <ScrollContainer
          onScroll={e => {
            this.handleOnScroll(e, isLoading, products);
          }}
        >
          <Helmet>
            <title>{t("SEARCH_TITLE")}</title>
          </Helmet>

          <Grid
            container
            item
            justify="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
            xs={12}
            className="mt-2"
          >
            {Boolean(products.length) &&
              products.map((currentProduct, _index) => {
                return (
                  <ProductItem
                    key={get(currentProduct, "id")}
                    currentProduct={currentProduct}
                    onClickAddToCart={this.onClickAddToCart}
                    promotions={promotions}
                  />
                );
              })}

            {isLoading && !noMoreData && (
              <Grid container justify="center" alignItems="center" alignContent="center">
                <CircularProgress size={100} color="primary" />
              </Grid>
            )}
          </Grid>
        </ScrollContainer>
      </>
    );
  }
}
export default withRouter(withQueryProjectId(withSearchParams(withTranslation(["PRODUCT_SEARCH"])(SearchProducts))));
