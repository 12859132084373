import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withQueryProjectId, withOrderDetail } from "../../../hoc";
import { Grid } from "@material-ui/core";
import { get } from "lodash";
import {
  GrandTotalPrice,
  OrderDetailWrapper,
  OrderDivider,
  OrderTotalLabel,
  DiscountValue,
  VatOption,
  PromotionText,
} from "./OrderDetail.styled";
import { formatMoney } from "../../../utils/product/index";
import { ShippedDetail } from "./ShippedDetail";
import { OrderHeader } from "./OrderHeader";
import { ProductSummary } from "./ProductSummary";
import getShippingPriceText from "./getShippingPriceText";
import { SHIPPING_METHOD, VAT } from "../../../constants/index";

class OrderDetailContainer extends React.Component {
  componentDidMount() {
    window.document.title = "Order detail";
  }

  render() {
    const { orderDetail, t } = this.props;

    const order = get(orderDetail, "order");
    const additionalDiscount = Number(get(order, "additionalDiscount")) || 0;
    const discountTotalPrice = Number(get(order, "discountTotalPrice")) || 0;
    const totalPrice = Number(get(order, "totalPrice")) || 0;
    const vatValue = get(order, "vatValue");
    const vatPrice = Number(get(order, "vatPrice"));
    const vatOptions = get(order, "vatOptions");
    const orderNumber = get(order, "orderNumber");
    const updatedAt = get(order, "updatedAt");
    const state = get(order, "state");
    const products = get(order, "products");
    const grandTotal = Number(get(order, "grandTotal")) || 0;
    const shippingMethod = get(order, "shippingMethod");
    const discountShippingPrice =
      Number(get(order, "discountShippingPrice")) || 0;
    const shippingPrice = Number(get(order, "shippingPrice")) || 0;
    const shippedDetail = get(order, "shippedDetail");
    const promotionProduct = get(order, "promotionProduct") || [];
    const productsAmount = products.reduce((prev, cur) => prev + cur.amount, 0);
    const hasVat =
      Boolean(vatOptions) && vatOptions !== VAT.VAT_OPTIONS_TYPE.NONE;
    const bundledPromotions = promotionProduct.filter((promotion) =>
      get(promotion, "settings.isBundled")
    );
    const bundledPromotionDescriptions = bundledPromotions
      .map(
        (promotion) => get(promotion, "description") || get(promotion, "name")
      )
      .join(", ");

    return (
      <OrderDetailWrapper>
        <Helmet>
          <title>{`${t("ORDER")} ${orderNumber}`}</title>
        </Helmet>

        <Grid container>
          <OrderHeader
            productsAmount={productsAmount}
            state={state}
            orderNumber={orderNumber}
            updatedAt={updatedAt}
          />

          <Grid item xs={12} className="pt-2 pb-1">
            <ProductSummary
              products={products}
              promotionProduct={promotionProduct}
            />
          </Grid>

          {bundledPromotionDescriptions && bundledPromotionDescriptions.length && (
            <Grid item xs={12} className="px-3 mb-2">
              <PromotionText>
                {t("PROMOTION")} - {bundledPromotionDescriptions}
              </PromotionText>
            </Grid>
          )}

          <Grid item xs={12} className="px-3">
            <OrderDivider />
          </Grid>

          <Grid container item className="px-3 pt-2">
            <Grid item xs={6}>
              <span>{t("PRICE")}</span>
            </Grid>
            <Grid item className="text-right" xs={6}>
              <span>{formatMoney(totalPrice + discountTotalPrice)}</span>
            </Grid>
          </Grid>

          {(Boolean(discountTotalPrice) || Boolean(additionalDiscount)) && (
            <Grid container item className="px-3 pt-2">
              <Grid item xs={6}>
                <DiscountValue>{t("DISCOUNT")}*</DiscountValue>
              </Grid>
              <Grid item className="text-right" xs={6}>
                <DiscountValue>
                  ฿ -{formatMoney(discountTotalPrice + additionalDiscount)}
                </DiscountValue>
              </Grid>
            </Grid>
          )}

          <Grid container item className="px-3 pt-2">
            <Grid item xs={6}>
              <span>
                {t("SHIPPING")} {`(${shippingMethod})`}
              </span>
            </Grid>
            <Grid item className="text-right" xs={6}>
              <span>
                {getShippingPriceText(state, shippingMethod, shippingPrice)}
              </span>
            </Grid>
          </Grid>

          {Boolean(discountShippingPrice) && (
            <Grid container item className="px-3 pt-2">
              <Grid item xs={6}>
                <DiscountValue>{t("SHIPPING_DISCOUNT")}</DiscountValue>
              </Grid>
              <Grid item className="text-right" xs={6}>
                <DiscountValue>
                  ฿ -{formatMoney(discountShippingPrice)}
                </DiscountValue>
              </Grid>
            </Grid>
          )}

          {hasVat && (
            <Grid container item className="px-3 pt-2">
              <Grid item xs={6} className="text-left">
                <span>
                  {t("VAT")} {vatValue}%{" "}
                  <VatOption>{`(${vatOptions})`}</VatOption>
                </span>
              </Grid>
              <Grid item className="text-right" xs={6}>
                <span>฿ {vatPrice && formatMoney(vatPrice)}</span>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} className="px-3 pt-3">
            <Grid container justify="space-between">
              <OrderTotalLabel>{t("TOTAL_PRICE")} : </OrderTotalLabel>
              <GrandTotalPrice>฿{formatMoney(grandTotal)}</GrandTotalPrice>
            </Grid>
          </Grid>
          {shippingMethod !== SHIPPING_METHOD.SHIPPING_METHOD.PICK_UP && (
            <ShippedDetail
              shippingMethod={shippingMethod}
              shippedDetail={shippedDetail}
            />
          )}
        </Grid>
      </OrderDetailWrapper>
    );
  }
}

export default withRouter(
  withQueryProjectId(
    withOrderDetail(withTranslation(["ORDER_DETAIL"])(OrderDetailContainer))
  )
);
