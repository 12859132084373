import React, { useState, useMemo } from "react";
import { fieldsEnum, resolveResultByField } from "../../../../../utils/ThailandAddress/finder";
import { Formik, Form } from "formik";
import { FormikTextField, Footer } from "../../../../../utils/common";
import { Grid, Button } from "@material-ui/core";
import { getValidationSchema } from "../../getValidationSchema";

import EmailIcon from "@material-ui/icons/Email";
import FormikErrorFocus from "formik-error-focus";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import SearchIcon from "@material-ui/icons/Search";

import SuggestionAddressList from "../../SuggestionAddressList";

const MAXIMUM_DISPLAY_SUGGESTION_ITEMS = 20;

const EditAddress = props => {
  const {
    isDisabled,
    hasSKUProductSKUs,
    hasDigitalProductSKUs,
    isFilled,
    isEmailRequired,
    submitAddress,
    addressData,
    t,
  } = props;
  const validationSchema = useMemo(
    () => getValidationSchema(t, isEmailRequired, hasSKUProductSKUs, hasDigitalProductSKUs),
    [],
  );

  const [searchAll, setSearchAll] = useState("");

  const [searchResultData, setSearchResultData] = useState({});
  const [searchAllResultData, setSearchAllResultData] = useState({});
  const [searchResultType, setSearchResultType] = useState("");
  const [isShowSuggestionResult, setIsShowSuggestionResult] = useState(true);
  const [isShowSearchSuggestionResult, setIsShowSearchSuggestionResult] = useState(true);

  const searchAllAddress = searchValue => {
    if (searchValue) {
      const resultFromProvince = resolveResultByField(
        fieldsEnum.PROVINCE,
        searchValue,
        MAXIMUM_DISPLAY_SUGGESTION_ITEMS,
      );
      const resultFromAmphoe = resolveResultByField(fieldsEnum.AMPHOE, searchValue, MAXIMUM_DISPLAY_SUGGESTION_ITEMS);
      const resultFromDistrict = resolveResultByField(
        fieldsEnum.DISTRICT,
        searchValue,
        MAXIMUM_DISPLAY_SUGGESTION_ITEMS,
      );
      const resultFromZipcode = resolveResultByField(fieldsEnum.ZIPCODE, searchValue, MAXIMUM_DISPLAY_SUGGESTION_ITEMS);
      setSearchAllResultData({
        ...resultFromProvince,
        ...resultFromAmphoe,
        ...resultFromDistrict,
        ...resultFromZipcode,
      });
    } else {
      setSearchAllResultData({});
    }
  };

  const searchAddress = (fieldsType, searchValue) => {
    if (searchValue) {
      setSearchResultType(fieldsType);
      setSearchResultData(resolveResultByField(fieldsType, searchValue, MAXIMUM_DISPLAY_SUGGESTION_ITEMS));
    } else {
      setSearchResultType(null);
      setSearchResultData({});
    }
  };

  const clearAddressList = () => {
    setSearchResultType(null);
    setSearchResultData({});
  };

  const onKeyDown = keyEvent => {
    // PREVENT ENTER SUBMIT
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  return (
    <Formik
      initialValues={addressData}
      onSubmit={(values, formikActions) => {
        submitAddress(values, formikActions);
      }}
      validationSchema={validationSchema}
      render={formProps => {
        const { handleChange, handleBlur, touched, errors, setValues, values } = formProps;

        return (
          <Form onKeyDown={onKeyDown}>
            <Grid container item xs={12}>
              <Grid container item xs={12} direction="row" justify="space-between" alignItems="center">
                <Grid container item xs={6} className="pr-1">
                  <FormikTextField
                    margin="normal"
                    disabled={isDisabled}
                    onChange={handleChange}
                    name="firstName"
                    value={values.firstName}
                    onBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    label={t("FIRST_NAME")}
                    fullWidth
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid container item xs={6} className="pl-1">
                  <FormikTextField
                    margin="normal"
                    disabled={isDisabled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    name="lastName"
                    value={values.lastName}
                    label={t("LAST_NAME")}
                    fullWidth
                    required
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  margin="normal"
                  disabled={isDisabled}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  name="phoneNumber"
                  value={values.phoneNumber}
                  label={t("MOBILE_PHONE_NUMBER")}
                  fullWidth
                  required
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIphoneIcon style={{ color: "#a1a6b9" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  margin="normal"
                  disabled={isDisabled}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  name="email"
                  value={values.email}
                  label={t("EMAIL")}
                  fullWidth
                  required={hasDigitalProductSKUs || isEmailRequired}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon style={{ color: "#a1a6b9" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {hasSKUProductSKUs && (
                <Grid item xs={12}>
                  <FormikTextField
                    margin="normal"
                    disabled={isDisabled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    name="streetAddress"
                    value={values.streetAddress}
                    label={t("STREET_ADDRESS")}
                    multiline
                    fullWidth
                    required={hasSKUProductSKUs}
                    variant="outlined"
                  />
                </Grid>
              )}
              {!isFilled && hasSKUProductSKUs && (
                <Grid item xs={12}>
                  <FormikTextField
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "#a1a6b9" }} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={e => {
                      setSearchAll(e.target.value);
                      handleChange(e);
                      setIsShowSearchSuggestionResult(true);
                      searchAllAddress(e.target.value);
                    }}
                    onBlur={e => {
                      handleBlur(e);
                      setIsShowSearchSuggestionResult(false);
                    }}
                    touched={touched}
                    errors={errors}
                    name="Search"
                    value={searchAll}
                    size="medium"
                    placeholder={t("SEARCH")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                  {isShowSearchSuggestionResult && (
                    <>{SuggestionAddressList(searchAllResultData, setValues, values, clearAddressList, t)}</>
                  )}
                </Grid>
              )}
              {hasSKUProductSKUs && (
                <Grid item xs={12}>
                  <FormikTextField
                    margin="normal"
                    disabled={isDisabled}
                    onChange={e => {
                      handleChange(e);
                      setIsShowSuggestionResult(true);
                      searchAddress(fieldsEnum.PROVINCE, e.target.value);
                    }}
                    onBlur={e => {
                      handleBlur(e);
                      setIsShowSuggestionResult(false);
                    }}
                    touched={touched}
                    errors={errors}
                    name="province"
                    value={values.province}
                    label={t("PROVINCE")}
                    fullWidth
                    required={hasSKUProductSKUs}
                    variant="outlined"
                  />
                  {searchResultType === fieldsEnum.PROVINCE && isShowSuggestionResult && (
                    <>{SuggestionAddressList(searchResultData, setValues, values, clearAddressList, t)}</>
                  )}
                </Grid>
              )}
              {hasSKUProductSKUs && (
                <Grid item xs={12}>
                  <FormikTextField
                    margin="normal"
                    disabled={isDisabled}
                    onChange={e => {
                      handleChange(e);
                      setIsShowSuggestionResult(true);
                      searchAddress(fieldsEnum.AMPHOE, e.target.value);
                    }}
                    onBlur={e => {
                      handleBlur(e);
                      setIsShowSuggestionResult(false);
                    }}
                    touched={touched}
                    errors={errors}
                    name="district"
                    value={values.district}
                    label={t("DISTRICT")}
                    fullWidth
                    required={hasSKUProductSKUs}
                    variant="outlined"
                  />
                  {searchResultType === fieldsEnum.AMPHOE && isShowSuggestionResult && (
                    <>{SuggestionAddressList(searchResultData, setValues, values, clearAddressList, t)}</>
                  )}
                </Grid>
              )}
              {hasSKUProductSKUs && (
                <Grid item xs={12}>
                  <FormikTextField
                    margin="normal"
                    disabled={isDisabled}
                    onChange={e => {
                      handleChange(e);
                      setIsShowSuggestionResult(true);
                      searchAddress(fieldsEnum.DISTRICT, e.target.value);
                    }}
                    onBlur={e => {
                      handleBlur(e);
                      setIsShowSuggestionResult(false);
                    }}
                    touched={touched}
                    errors={errors}
                    name="subDistrict"
                    value={values.subDistrict}
                    label={t("SUB_DISTRICT")}
                    fullWidth
                    required={hasSKUProductSKUs}
                    variant="outlined"
                  />
                  {searchResultType === fieldsEnum.DISTRICT && isShowSuggestionResult && (
                    <>{SuggestionAddressList(searchResultData, setValues, values, clearAddressList, t)}</>
                  )}
                </Grid>
              )}
              {hasSKUProductSKUs && (
                <Grid item xs={12}>
                  <FormikTextField
                    margin="normal"
                    disabled={isDisabled}
                    onChange={e => {
                      handleChange(e);
                      setIsShowSuggestionResult(true);
                      searchAddress(fieldsEnum.ZIPCODE, e.target.value);
                    }}
                    onBlur={e => {
                      handleBlur(e);
                      setIsShowSuggestionResult(false);
                    }}
                    touched={touched}
                    errors={errors}
                    name="postalCode"
                    value={values.postalCode}
                    label={t("POSTAL_CODE")}
                    fullWidth
                    required={hasSKUProductSKUs}
                    variant="outlined"
                  />
                  {searchResultType === fieldsEnum.ZIPCODE && isShowSuggestionResult && (
                    <>{SuggestionAddressList(searchResultData, setValues, values, clearAddressList, t)}</>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <FormikTextField
                  margin="normal"
                  disabled={isDisabled}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  name="deliveryNote"
                  value={values.deliveryNote}
                  label={t("DELIVERY_NOTE")}
                  fullWidth
                />
              </Grid>

              {!isDisabled && (
                <Footer>
                  <Grid container item xs={12} className="mx-3">
                    <Button
                      style={{
                        borderRadius: "10px",
                        height: "42px",
                        fontWeight: 500,
                        margin: "20px 0",
                      }}
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {t("NEXT")}
                    </Button>
                  </Grid>
                </Footer>
              )}
            </Grid>
            <FormikErrorFocus
              // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
              offset={0}
              align={"top"}
              focusDelay={200}
              ease={"out-bounce"}
              duration={100}
            />
          </Form>
        );
      }}
    />
  );
};

export default EditAddress;
