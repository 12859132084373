import moment from "moment";
import * as yup from "yup";

import { DATE_TIME, OFFLINE_STORE } from "../../../constants";
import { REGEX_PHONE_NUMBER_ONLY } from "../../../constant";

const SUNDAY = 7;
export const getStoreOpeningHourByDate = (selectedDate, storeOpeningHours) => {
  let selectedDateInWeekdayNumber = moment(selectedDate).day();
  // mon = 1, tue = 2, ..., sun = 7
  if (selectedDateInWeekdayNumber === SUNDAY) {
    selectedDateInWeekdayNumber = 0;
  }
  return storeOpeningHours[selectedDateInWeekdayNumber];
};

export const validateDate = (selectedDate, storeOpeningHours) => {
  if (storeOpeningHours.length) {
    const storeOpeningHourByDate = getStoreOpeningHourByDate(selectedDate, storeOpeningHours);

    const isSelectedDateAvailable = storeOpeningHourByDate.isActive;

    if (!isSelectedDateAvailable) {
      return OFFLINE_STORE.ERROR.VALIDATE_DATE;
    }
  }
  return null;
};

export const validateTime = (selectedDate, selectedTime, storeOpeningHours) => {
  if (storeOpeningHours.length) {
    const errorText = OFFLINE_STORE.ERROR.VALIDATE_TIME;
    const storeOpeningHourByDate = getStoreOpeningHourByDate(selectedDate, storeOpeningHours);

    const date = moment(selectedDate).format(DATE_TIME.DATE_FORMAT);
    const time = moment(selectedTime, DATE_TIME.TIME_INPUT_FORMAT).format(DATE_TIME.TIME_FORMAT);
    const selectedDateTime = moment(`${date} ${time}`, `${DATE_TIME.DATE_FORMAT} ${DATE_TIME.TIME_FORMAT}`);

    const isSelectTimeInPast = selectedDateTime.isBefore(moment());
    if (isSelectTimeInPast) {
      return errorText;
    }

    const { open, close } = storeOpeningHourByDate;

    const isSelectedTimeAvailable = selectedTime.isBetween(
      moment(open, DATE_TIME.TIME_INPUT_FORMAT),
      moment(close, DATE_TIME.TIME_INPUT_FORMAT),
      undefined,
      [],
    );

    if (!isSelectedTimeAvailable) {
      return errorText;
    }
  }

  return null;
};

/**
 * Get initial values for offline stores
 * @param {object} shippingAddress
 * @returns {object}
 */
export function getInitialValuesForOfflineStores(shippingAddress) {
  return {
    firstName: shippingAddress && shippingAddress.firstName ? shippingAddress.firstName : "",
    lastName: shippingAddress && shippingAddress.lastName ? shippingAddress.lastName : "",
    phoneNumber: shippingAddress && shippingAddress.phoneNumber ? shippingAddress.phoneNumber : "",
    email: shippingAddress && shippingAddress.email ? shippingAddress.email : "",
    date: moment(),
    time: moment()
      .add(1, "hours")
      .format(DATE_TIME.TIME_INPUT_FORMAT),
  };
}

/**
 * Get offline store validation
 * @param {unknown} t
 * @param {boolean} hasDigitalContentTypeProduct
 * @param {boolean} isEmailRequired
 * @returns {yup.Schema}
 */
export function getOfflineStoreValidationSchema(t, hasDigitalContentTypeProduct, isEmailRequired = false) {
  const firstNameIsRequired = t("ADDRESS_FORM:FORM_REQUIRED", {
    fieldName: t("ADDRESS_FORM:FIRST_NAME"),
  });

  const lastNameIsRequired = t("ADDRESS_FORM:FORM_REQUIRED", {
    fieldName: t("ADDRESS_FORM:LAST_NAME"),
  });

  const phoneNumberIsRequiredRangeLength = t("ADDRESS_FORM:FORM_RANGE_LENGTH", {
    fieldName: t("ADDRESS_FORM:MOBILE_PHONE_NUMBER"),
    min: 10,
    max: 10,
  });

  const phoneNumberIsRequired = t("ADDRESS_FORM:FORM_REQUIRED", {
    fieldName: t("ADDRESS_FORM:MOBILE_PHONE_NUMBER"),
  });

  const emailIsRequired = t("ADDRESS_FORM:FORM_REQUIRED", {
    fieldName: t("ADDRESS_FORM:EMAIL"),
  });

  const emailCorrect = t("ADDRESS_FORM:FORM_CORRECT", {
    fieldName: t("ADDRESS_FORM:EMAIL"),
  });

  return yup.object({
    firstName: yup.string().required(firstNameIsRequired),
    lastName: yup.string().required(lastNameIsRequired),
    phoneNumber: yup
      .string()
      .matches(REGEX_PHONE_NUMBER_ONLY, {
        message: t("ADDRESS_FORM:FORM_PHONE_NUMBER_ONLY"),
      })
      .min(10, phoneNumberIsRequiredRangeLength)
      .max(10, phoneNumberIsRequiredRangeLength)
      .required(phoneNumberIsRequired),
    email:
      hasDigitalContentTypeProduct || isEmailRequired
        ? yup
            .string()
            .email()
            .typeError(emailIsRequired)
            .required(emailIsRequired)
        : yup
            .string()
            .email(emailCorrect)
            .optional()
            .nullable(),
  });
}
