const MY_CART = {
  en: {
    CART_SUMMARY: "Cart summary",
    PRODUCT_LISTS: "Product lists",
    CONTINUE_SHOPPING: "Update",
    OUT_OF_CART: "Cart is empty",
    TOTAL_PRICE: "Total price",
    ITEMS: "Item(s)",
    TOTAL_PRICE_INCLUDE_PROMOTIONS:"Promotions already included"
  },
  th: {
    CART_SUMMARY: "รายการสั่งซื้อ",
    PRODUCT_LISTS: "รายการสินค้า",
    CONTINUE_SHOPPING: "ยืนยันรายการ",
    OUT_OF_CART: "คุณยังไม่มีสินค้าในรายการสั่งซื้อ",
    TOTAL_PRICE: "ราคารวม",
    ITEMS: "รายการ",
    TOTAL_PRICE_INCLUDE_PROMOTIONS:"ราคารวมโปรโมชั่นแล้ว"
  },
};

export default MY_CART;
