const COLORS = {
  Badge: "#d04402",
  BoxShadow: "rgba(0, 0, 0, 0.15)",
  CarolinaBlue: "#98ade6",
  COD: "#ef5a97",
  CustomerChatStatus: "#eebde8",
  Dark: "#141931",
  DarkGray: "#42495a",
  DarkLight: "#aeafb7",
  DarkMed: "#737d94",
  Error: "#ff5472",
  GreyBlue: "#64c3c8",
  Info: "#4374fc",
  Light: "#f5f7fb",
  LightBlue: "#cad2e4",
  LightGray: "#eff1f5",
  LightWhite: "#fbfcfe",
  MacaroniCheese: "#e1b540",
  OldBubbleChatBackground: "#e0e0e0",
  OldFontChatColor: "#424252",
  PaleGrey: "#f5f2ff",
  Primary: "#663ee8",
  PrimaryDark: "#4521bb",
  PrimaryLight: "#b097ff",
  PrimaryNude: "#f0f3fc",
  ReadyToApprove: "#ff9526",
  ReadyToShip: "#7ea0ff",
  RedDiscountPrice: "#FF1B16",
  Salmon: "#e67676",
  BitterSweet: "#FE6767",
  Success: "#1be28f",
  Warning: "#fcc943",
  White: "#ffffff",
};

export const BANK_COLORS = {
  SCB: "#4e2e7f",
  KBANK: "#138f2d",
  BBL: "#1e4598",
  CIMB: "#7e2f36",
  CITI: "#1583c7",
  GSB: "#eb198d",
  KTB: "#1ba5e1",
  TMB: "#1279be",
  UOB: "#0b3979",
  BAY: "#fec43b",
};

export const COLOR_BY_STATUS = {
  ABANDONED: COLORS.DarkMed,
  ALL: COLORS.DarkMed,
  CANCELLED: COLORS.DarkMed,
  COD_PICKED_UP: COLORS.COD,
  COD_PREPARE_TO_SHIP: COLORS.ReadyToShip,
  COD_REJECTED: COLORS.DarkMed,
  COMPLETED: COLORS.Success,
  ORDER_PLACED: COLORS.Primary,
  PENDING_FOR_SHIPPING_COST: COLORS.MacaroniCheese,
  PREPARE_TO_SHIPPING: COLORS.ReadyToShip,
  SHIPPED: COLORS.Success,
  WAIT_FOR_PAYMENT_APPROVE: COLORS.ReadyToApprove,
  VOIDED: COLORS.BitterSweet,
};

export default COLORS;
