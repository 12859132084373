import styled from "styled-components";
import {
  Grid,
  TextField,
  Button,
  ButtonBase,
  Typography,
} from "@material-ui/core";

export const AddToCartButton = styled(Button)`
  border-radius: "16px";
`;

export const TotalPriceCaptionText = styled(Typography)`
  color: ${({ theme }) => theme.COLORS.LightBlue};
`;

export const TotalPriceText = styled.span`
  font-size: "16px";
  line-height: "20px";
  color: ${({ theme }) => theme.COLORS.DarkMed};
`;

export const ShippingMethodText = styled.span`
  font-weight: "bold";
`;

export const ShippingConditionDescriptionText = styled.span`
  margin-left: 4px;
  font-size: "12px";
  color: ${({ theme }) => theme.COLORS.DarkMed};
`;

export const SelectedProductButton = styled(Button)`
  font-weight: "normal";
  text-transform: "none";
  border-radius: "8px";
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.COLORS.Primary : theme.COLORS.DarkMed};
  border-color: ${({ isSelected, theme }) =>
    isSelected ? theme.COLORS.Primary : theme.COLORS.LightBlue};
`;

export const OptionsContainer = styled(Grid)`
  border: solid 1px ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 8px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.COLORS.DarkGray};
`;

export const InputNote = styled(TextField)`
  .MuiInput-underline:hover,
  .MuiInput-underline:after,
  .MuiInput-underline.Mui-focused {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.Primary};
  }
`;

export const PromotionTitleText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.DarkGray};
`;

export const PromotionTitlePrimaryText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.Primary};
  font-weight: bold;
`;

export const ButtonCircle = styled.button`
  padding: 0;
  width: 28px;
  height: 28px;
  color: ${({ theme }) => theme.COLORS.DarkMed};
  font-size: 18px;
  line-height: 1px;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.COLORS.LightBlue};
`;

export const PromotionBadge = styled.div`
  position: absolute;
  border-radius: 10px 0;
  padding: 8px 16px;
  background: ${({ theme }) => theme.COLORS.Primary};
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.White};
`;

export const ProductFoodImageContainer = styled.img`
  display: flex;
  width: 100%;
  height: 210px;
  object-fit: cover;
  border-radius: 10px;
  background: ${({ theme }) => theme.COLORS.LightGray};
`;

export const ProductMainImageContainer = styled.img`
  display: flex;
  width: 100%;
  height: 270px;
  object-fit: contain;
  background: ${({ theme }) => theme.COLORS.LightGray};
`;

export const ProductImageSmallContainer = styled.img`
  width: 62px;
  height: 62px;
  margin-bottom: 5px;
  margin-left: 5px;
  object-fit: contain;
  border: solid 1px
    ${({ selected, theme }) => (selected ? theme.COLORS.Primary : "none")};
`;

export const ProductImageSmallScroll = styled.div`
  height: 270px;
  max-width: 70px;
  overflow-x: scroll;
`;

export const ProductTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.Dark};
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
`;

export const ProductPrice = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.Primary};
`;

export const TitleSectionText = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme }) => theme.COLORS.Dark};
`;

export const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.White};
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  z-index: 1000;
  height: 74px;
  box-shadow: 0 -5px 15px 0 rgba(0, 0, 0, 0.1);
`;

export const SKUTextLabel = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.DarkMed};
`;

export const NoDescriptionText = styled.div`
  font-size: 14px;
  line-height: 1.42;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.COLORS.Dark};
`;

export const DescriptionText = styled.div`
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 14px;
  line-height: 1.42;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.COLORS.Dark};
`;

export const PropertyTextKey = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.DarkMed};
`;

export const PropertyTextValue = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.DarkGray};
`;

export const SKUTextLabelWarning = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.Error};
`;

export const SectionDivider = styled.div`
  border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  width: 100%;
  margin: 1rem 0;
`;

export const AmountText = styled.p`
  text-align: center;
`;

export const ProductFoodAmountText = styled.p`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

export const ShippingDescription = styled.div`
  font-size: 14px;
`;

export const ProductImage = styled.img`
  display: flex;
  width: 30px;
  height: 30px;
  object-fit: contain;
  background: ${({ theme }) => theme.COLORS.LightGray};
`;

export const Corner = styled.div`
  display: flex;
  flex: 1;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent ${({ theme }) => theme.COLORS.Primary} transparent
    transparent;
`;

export const ProductTitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.DarkGray};
`;

export const ProductText = styled.div`
  display: flex;
  flex: 1;
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.DarkMed};
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
`;

export const PromotionLabelText = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.Primary};
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
`;

export const DescriptionContainer = styled(Grid)`
  border: solid 1px ${({ theme }) => theme.COLORS.Primary};
  color: ${({ theme }) => theme.COLORS.Dark};
`;

export const AmountPriceBefore = styled.span`
  font-size: 18px;
  text-decoration: line-through;
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.DarkLight};
`;

export const AmountPriceAfter = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.RedDiscountPrice};
`;

export const ZoomToolsWrapper = styled.div`
  position: absolute;
  margin-top: 170px;
  margin-left: 10px;
  font-size: 30px;
  color: ${({ theme }) => theme.COLORS.DarkLight};
`;

export const BackdropWrapper = styled.div`
  background: ${({ theme }) => theme.COLORS.LightGray};
`;

export const ButtonIconZoom = styled(ButtonBase)`
  width: 36px;
  height: 36px;
  opacity: 0.7;
  border-radius: 8px;
  box-shadow: 3px 4px 10px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px ${({ theme }) => theme.COLORS.Dark};
  color: ${({ theme }) => theme.COLORS.Dark};
  background: ${({ theme }) => theme.COLORS.Light};
  margin-bottom: 10px;
`;
