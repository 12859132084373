const PRODUCT_DETAIL = {
  en: {
    ADD_TO_CART: "Add to cart",
    AMOUNT: "Amount",
    DAY: "Day",
    ERROR_SELECT_OPTIONS_PRODUCT: "Please select option product",
    EXAMPLE: "Example: No garlic",
    FREE_PRODUCT: "Free product",
    ITEMS: "Item(s)",
    MAXIMUM_SELECTED: "Maximum selected",
    NO_DESCRIPTION: "No product detail",
    NOTE: "Note",
    OUT_OF_STOCK: "Out of stock",
    PRODUCT_DETAIL: "Product detail",
    PRODUCTS_IN_PROMOTION: "Products in promotion",
    PROPERTIES: "Properties",
    REMAINING_STOCK: "Remaining stock",
    SELECT_AFTER_PAYMENT: "Select after payment",
    SELECT_OPTION_PRODUCT: "Please select option in menu",
    SELECT_SHIPPING_METHOD: "Select shipping method",
    SELECT: "Select",
    TEMPORARY_OUT_OF_STOCK: "Temporary out of stock",
    TOTAL_PRICE_INCLUDE_PROMOTIONS: "Promotions already included",
    TOTAL_PRICE: "Total price",
    POSTAL_CODE_BASED_PRICE: "Price base on postal code",
    DISTANCE_BASE_PRICE: "Price base on distance",
    CONDITIONS: "Conditions",
    DAYS: "days",
    AFTER_PURCHASE: "after purchased",
    EXPIRED_AT: "Expired at",
    AVAILABLE_AT: "Available at",
    TO: "to",
  },
  th: {
    ADD_TO_CART: "เพิ่มในรายการ",
    AMOUNT: "จำนวน",
    DAY: "วัน",
    ERROR_SELECT_OPTIONS_PRODUCT: "โปรดเลือกตัวเลือกสินค้า",
    EXAMPLE: "ตัวอย่าง: ไม่ใส่กระเทียม",
    FREE_PRODUCT: "สินค้าที่แถม",
    ITEMS: "รายการ",
    MAXIMUM_SELECTED: "เลือกได้สูงสุด",
    NO_DESCRIPTION: "สินค้านี้ไม่มีรายละเอียด",
    NOTE: "หมายเหตุ",
    OUT_OF_STOCK: "สินค้าหมด",
    PRODUCT_DETAIL: "รายละเอียดสินค้า",
    PRODUCTS_IN_PROMOTION: "สินค้าที่ร่วมรายการ",
    PROPERTIES: "คุณสมบัติ",
    REMAINING_STOCK: "เหลือสินค้าเพียง",
    SELECT_AFTER_PAYMENT: "เลือกหลังกดชำระเงิน",
    SELECT_OPTION_PRODUCT: "โปรดเลือกตัวเลือกในรายการ",
    SELECT_SHIPPING_METHOD: "ตัวเลือกการจัดส่ง",
    SELECT: "เลือก",
    TEMPORARY_OUT_OF_STOCK: "สินค้าหมดชั่วคราว",
    TOTAL_PRICE_INCLUDE_PROMOTIONS: "ราคารวมโปรโมชั่นแล้ว",
    TOTAL_PRICE: "ราคารวม",
    POSTAL_CODE_BASED_PRICE: "ราคาค่าจัดส่งตามรหัสไปรษณีย์",
    DISTANCE_BASE_PRICE: "แอดมินคำนวณค่าจัดส่ง", //fix constant later
    CONDITIONS: "วิธีการใช้งาน / เงื่อนไข",
    DAYS: "วัน",
    AFTER_PURCHASE: "หลังจากซื้อ",
    EXPIRED_AT: "ใช้ได้ถึง",
    AVAILABLE_AT: "ใช้ได้",
    TO: "ถึง",
  },
};

export default PRODUCT_DETAIL;
