import moment from "moment";
import get from "lodash/get";

const TIMEZONE_OFFSET = "+07:00";
const OPENING_HOURS_TIME_FORMAT = "HH:mmZ";
const FULL_DAY_FORMAT = "dddd";

export const TIME_FORMAT = "HH:mm:ssZZ";
export const TIME_INPUT_FORMAT = "HH:mm";

// we need this because we override moment local to th
const DAYS_MAPPER = {
  อาทิตย์: "SUNDAY",
  จันทร์: "MONDAY",
  อังคาร: "TUESDAY",
  พุธ: "WEDNESDAY",
  พฤหัสบดี: "THURSDAY",
  ศุกร์: "FRIDAY",
  เสาร์: "SATURDAY",
};

/**
 * @private
 * check is current time is between shop available time
 * @param {date} scheduleAt
 * @param {string} start HH:mm:ssZ
 * @param {string} end HH:mm:ssZ
 * @param {string=} timeFormat
 */
const isBetweenShopAvailableTime = (scheduleAt, start, end, timeFormat = TIME_FORMAT) => {
  if (!start || !end) {
    return false;
  }

  const scheduleAtDate = moment(scheduleAt);

  const startDateTime = moment(start, timeFormat);
  const endDateTime = moment(end, timeFormat);
  // care only time
  const scheduleAtTime = moment(scheduleAtDate.format(TIME_FORMAT), TIME_FORMAT);
  const startTime = moment(startDateTime.format(TIME_FORMAT), TIME_FORMAT);
  const endTime = moment(endDateTime.format(TIME_FORMAT), TIME_FORMAT);

  // normal time
  if (startTime.isBefore(endTime)) {
    return scheduleAtTime.isBetween(startTime, endTime, null, "[]");
  }

  return !scheduleAtTime.isBetween(endTime, startTime, null, "()");
};

/**
 * Is schedule time in opening hour of given offline store
 * @param {object} offlineStore
 * @param {Date} scheduleAt
 */
export function isDuringShopOpeningHours(offlineStore, scheduleAt) {
  if (!offlineStore || !scheduleAt) {
    return false;
  }

  const scheduleAtDay = moment(scheduleAt).format(FULL_DAY_FORMAT);
  const scheduleAtDayInThai = DAYS_MAPPER[scheduleAtDay];

  const openingHours = get(offlineStore, "openingHours") || [];
  const openingShop = openingHours.find(openingHour => {
    const isSameDay = openingHour.day === scheduleAtDay.toUpperCase() || openingHour.day === scheduleAtDayInThai;
    const isBetweenShopOpeningTime = isBetweenShopAvailableTime(
      scheduleAt,
      `${openingHour.open}${TIMEZONE_OFFSET}`,
      `${openingHour.close}${TIMEZONE_OFFSET}`,
      OPENING_HOURS_TIME_FORMAT,
    );

    return openingHour.isActive && isSameDay && isBetweenShopOpeningTime;
  });

  return !!openingShop;
}

export const getSuggestDeliveryTime = (offlineStore, deliveryConfiguration, scheduleAt = new Date()) => {
  const timeInAdvance = get(deliveryConfiguration, "timeInAdvance") || 0;

  const isValid = isDuringShopOpeningHours(offlineStore, scheduleAt);

  if (isValid) {
    return scheduleAt;
  } else {
    const openingHours = get(offlineStore, "openingHours") || [];
    let openingShop;
    let availableDate;
    for (let numberOfDay = 1; numberOfDay <= 7; numberOfDay++) {
      const scheduleAtNextDayDate = moment(scheduleAt).add(numberOfDay, "days");
      const scheduleAtNextDay = scheduleAtNextDayDate.format(FULL_DAY_FORMAT).toLocaleUpperCase();

      const scheduleAtNextDayInThai = DAYS_MAPPER[scheduleAtNextDay];

      openingShop = openingHours.find(
        openingHour =>
          (openingHour.day === scheduleAtNextDay.toUpperCase() || openingHour.day === scheduleAtNextDayInThai) &&
          openingHour.isActive,
      );

      if (openingShop) {
        const [openHour, openMinutes] = openingShop.open.split(":");
        availableDate = scheduleAtNextDayDate
          .set({
            hours: openHour,
            minutes: openMinutes,
          })
          .add(timeInAdvance, "minutes");
        break;
      }
    }

    if (!availableDate) {
      return scheduleAt;
    }

    return availableDate.toDate();
  }
};

export const isDeliveryTimeValidWithDeliveryConfiguration = (deliveryConfiguration, scheduleAt = new Date()) => {
  const timeInAdvance = get(deliveryConfiguration, "timeInAdvance") || 0;
  const start = get(deliveryConfiguration, "start");
  const end = get(deliveryConfiguration, "end");

  const openTime = moment(start, TIME_FORMAT).add(timeInAdvance, "minutes");
  const closeTime = moment(end, TIME_FORMAT).subtract(timeInAdvance, "minutes");
  const openTimeString = openTime.format(TIME_FORMAT);
  const closeTimeString = closeTime.format(TIME_FORMAT);

  const scheduleAtDate = moment(scheduleAt);
  const todayDateWithAdvanceTime = moment();

  if (scheduleAtDate.isBefore(todayDateWithAdvanceTime)) {
    return false;
  }

  return isBetweenShopAvailableTime(scheduleAtDate, openTimeString, closeTimeString, TIME_FORMAT);
};

export const getSuggestDeliveryTimeWithDeliveryConfiguration = (deliveryConfiguration, scheduleAt = new Date()) => {
  const timeInAdvance = get(deliveryConfiguration, "timeInAdvance") || 0;
  const start = get(deliveryConfiguration, "start");
  const end = get(deliveryConfiguration, "end");

  const openTime = moment(start, TIME_FORMAT).add(timeInAdvance, "minutes");
  const closeTime = moment(end, TIME_FORMAT).subtract(timeInAdvance, "minutes");
  const openTimeString = openTime.format(TIME_FORMAT);
  const closeTimeString = closeTime.format(TIME_FORMAT);

  const scheduleAtDate = moment(scheduleAt).add(timeInAdvance, "minutes");
  const todayDateWithAdvanceTime = moment().add(timeInAdvance, "minutes");

  const isValid = isBetweenShopAvailableTime(scheduleAtDate, openTimeString, closeTimeString, TIME_FORMAT);

  if (isValid) {
    return scheduleAtDate.toDate();
  } else {
    const isAvailableDateValid = isBetweenShopAvailableTime(
      todayDateWithAdvanceTime,
      openTimeString,
      closeTimeString,
      TIME_FORMAT,
    );
    if (isAvailableDateValid) {
      return todayDateWithAdvanceTime.toDate();
    }

    if (scheduleAtDate.isBefore(openTime)) {
      return openTime.toDate();
    }

    return openTime.add(1, "days").toDate();
  }
};
