import React from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@material-ui/pickers";
import "moment/locale/th";

const DATE_PICKER_VIEWS = ["date"];

const DatePickerField = ({ field, form, minDate, maxDate, format = "DD MMMM YYYY", ...other }) => {
  const { t } = useTranslation(["COMMON"]);
  const currentError = form.errors[field.name];

  return (
    <DatePicker
      disablePast
      label={t("DATE")}
      name={field.name}
      value={field.value}
      inputVariant={"outlined"}
      format={format}
      minDate={minDate}
      maxDate={maxDate}
      views={DATE_PICKER_VIEWS}
      helperText={currentError}
      error={Boolean(currentError)}
      // if you are using custom validation schema you probably want to pass `true` as third argument
      onChange={date => {
        form.setFieldValue(field.name, date.startOf("minute"), true);
        return;
      }}
      {...other}
    />
  );
};

export default DatePickerField;
