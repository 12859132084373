export const STATES_EN = {
  ABANDONED: "Abandoned",
  ALL: "All",
  CANCELLED: "Cancelled",
  COD_PICKED_UP: "COD Approve",
  COD_PREPARE_TO_SHIP: "COD Ready to ship",
  COD_REJECTED: "COD Cancelled",
  COMPLETED: "Completed",
  ORDER_PLACED: "New order",
  PENDING_FOR_SHIPPING_COST: "PENDING_FOR_SHIPPING_COST",
  PREPARE_TO_SHIPPING: "Ready to ship",
  SHIPPED: "Completed",
  WAIT_FOR_PAYMENT_APPROVE: "Ready to approve",
};

export const STATES_TH = {
  ABANDONED: "รายการที่ไม่มีการจ่ายเงิน",
  CANCELLED: "รายการที่ถูกยกเลิก",
  COD_PICKED_UP: "COD รอการยืนยัน",
  COD_PREPARE_TO_SHIP: "COD พร้อมจัดส่ง",
  COD_REJECTED: "COD ถูกยกเลิก",
  COMPLETED: "สำเร็จ",
  ORDER_PLACED: "รายการสั่งซื้อใหม่",
  PENDING_FOR_SHIPPING_COST: "รอคำนวณค่าส่ง",
  PREPARE_TO_SHIPPING: "รายการที่รอการส่ง",
  SHIPPED: "สำเร็จ",
  WAIT_FOR_PAYMENT_APPROVE: "รอการอนุมัติ",
};
