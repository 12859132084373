const SCHEDULE = {
  en: {
    UNAVAILABLE: "Can't deliver on this time",
    SELECT_DELIVERY_TIME: "Select delivery time",
    WORKING_HOURS: "Working hours",
    NOT_DELIVERY: "Not delivery this day",
    OK: "OK",
    CANCEL: "Cancel",
  },
  th: {
    UNAVAILABLE: "ไม่สามารถจัดส่งในช่วงเวลาดังกล่าวได้",
    SELECT_DELIVERY_TIME: "เลือกเวลาจัดส่ง",
    WORKING_HOURS: "เวลาในการจัดส่ง",
    NOT_DELIVERY: "ไม่จัดส่ง",
    OK: "ยืนยัน",
    CANCEL: "ยกเลิก",
  },
};

export default SCHEDULE;
