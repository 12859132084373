import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { COLORS } from "../../../../../constants/index";
import { convertPriceFormat } from "../../../../../utils/common";
import { Divider, StyledTypography } from "./styled";

const OrderProductList = props => {
  const { t, products } = props;

  const productAmount = products.reduce((total, { amount }) => total + amount, 0);

  return (
    <Grid container>
      <Grid item container justify="space-between">
        <Grid item>
          <StyledTypography variant="body2" textColor={COLORS.DarkMed}>
            {t("ORDER_SUMMARY")}
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography variant="body2" textColor={COLORS.DarkMed}>
            {productAmount} {t("ITEMS")}
          </StyledTypography>
        </Grid>
      </Grid>
      <Divider className="py-2 my-1" />
      {products.map(productSKU => {
        const { id, amount, product, price, isFree, variableTypes } = productSKU;
        const productSKUType = variableTypes.reduce(
          (result, { value }, index) => (index ? result + ", " + value : value),
          "",
        );

        return (
          <Grid container key={id} className="mb-2">
            <Grid item xs={1}>
              <StyledTypography variant="body2" textColor={COLORS.DarkMed}>
                {amount}x
              </StyledTypography>
            </Grid>
            <Grid item container xs={11}>
              <Grid item container justify="space-between">
                <Grid item xs={7}>
                  <Typography variant="body2">{product.name}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{isFree ? `${t("FREE")}` : `฿${convertPriceFormat(price * amount)}`}</Typography>
                </Grid>
              </Grid>
              {/* SKU detail */}
              <Grid item xs={7}>
                <StyledTypography variant="caption" textColor={COLORS.DarkMed}>
                  {productSKUType}
                </StyledTypography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default OrderProductList;
