import React from "react";
import styled from "styled-components";

import MUIRadioGroup from "@material-ui/core/RadioGroup";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export const RadioGroup = MUIRadioGroup;

const RadioContainer = styled.button`
  outline: none;
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  text-align: left;
  background-color: ${({ theme, isActive }) => (isActive ? theme.COLORS.PrimaryNude : theme.COLORS.White)};
  border: solid 1px ${({ theme, isActive }) => (isActive ? theme.COLORS.Primary : theme.COLORS.LightBlue)};
  cursor: pointer;
  &[disabled] {
    cursor: initial;
    color: ${({ theme }) => theme.COLORS.DarkLight};
    border: solid 1px ${({ theme }) => theme.COLORS.DarkLight};
  }
`;

const RadioButton = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 12px;

  background-color: ${({ theme }) => theme.COLORS.White};
  border: solid 1px ${({ theme, isActive }) => (isActive ? theme.COLORS.Primary : theme.COLORS.LightBlue)};
`;

const SelectedRadio = styled.div`
  width: 18px;
  height: 18px;
  margin: 3px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.COLORS.Primary};
`;

const RadioImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
`;

export const RadioBox = props => {
  const {
    disabled = false,
    isActive = true,
    title,
    subTitle,
    description,
    className,
    onClick,
    type = "submit",
    rtl,
    remark,
    imageUrl,
  } = props;

  return (
    <RadioContainer type={type} className={className} disabled={disabled} isActive={isActive} onClick={onClick}>
      <Grid container alignItems="center" className="position-relative flex-nowrap">
        {!rtl && (
          <Grid item className={`d-flex flex-grow-0`}>
            <RadioButton isActive={isActive}>{isActive && <SelectedRadio />}</RadioButton>
          </Grid>
        )}

        {imageUrl && <RadioImage src={imageUrl} className="mr-1" />}

        <Grid item container alignItems="center" className={`d-flex flex-grow-1 pl-1`}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              color={disabled ? "inherit" : "primary"}
              className="font-weight-bold"
              style={{ fontSize: 14 }}
            >
              {title}
            </Typography>
          </Grid>
          {subTitle && (
            <Grid item xs={12} className="pt-1">
              <Typography color={disabled ? "inherit" : "textSecondary"} style={{ fontSize: 12 }}>
                {subTitle}
              </Typography>
            </Grid>
          )}
          {description && (
            <Grid item xs={12} className="pt-1">
              <Typography color={disabled ? "inherit" : "textSecondary"} style={{ fontSize: 12 }}>
                {description}
              </Typography>
            </Grid>
          )}
          {remark && (
            <Grid item xs={12} style={{ margin: "auto", right: 0 }} className={"mt-2"}>
              <Typography color={disabled ? "inherit" : "textSecondary"} style={{ fontSize: 12 }}>
                {remark}
              </Typography>
            </Grid>
          )}
        </Grid>
        {rtl && (
          <Grid item className={`d-flex flex-grow-0 pl-2`}>
            <RadioButton isActive={isActive}>{isActive && <SelectedRadio />}</RadioButton>
          </Grid>
        )}
      </Grid>
    </RadioContainer>
  );
};
