import ADDRESS_FORM from "./addressForm";
import COMMON from "./common";
import DAYS from "./days";
import MEMBERSHIP from "./membership";
import MY_CART from "./myCart";
import OFFLINE_STORE from "./offlineStore";
import ORDER_DETAIL from "./orderDetail";
import ORDER_STATE from "./orderState";
import PRODUCT_SEARCH from "./productSearch";
import PAYMENT from "./payment";
import SCHEDULE from "./schedule";
import PRODUCT_DETAIL from "./productDetail";

const translations = {
  EN: {
    ADDRESS_FORM: ADDRESS_FORM.en,
    COMMON: COMMON.en,
    DAYS: DAYS.en,
    MEMBERSHIP: MEMBERSHIP.en,
    MY_CART: MY_CART.en,
    OFFLINE_STORE: OFFLINE_STORE.en,
    ORDER_DETAIL: ORDER_DETAIL.en,
    ORDER_STATE: ORDER_STATE.en,
    PAYMENT: PAYMENT.en,
    PRODUCT_DETAIL: PRODUCT_DETAIL.en,
    PRODUCT_SEARCH: PRODUCT_SEARCH.en,
    SCHEDULE: SCHEDULE.en,
  },
  TH: {
    ADDRESS_FORM: ADDRESS_FORM.th,
    COMMON: COMMON.th,
    DAYS: DAYS.th,
    MEMBERSHIP: MEMBERSHIP.th,
    MY_CART: MY_CART.th,
    OFFLINE_STORE: OFFLINE_STORE.th,
    ORDER_DETAIL: ORDER_DETAIL.th,
    ORDER_STATE: ORDER_STATE.th,
    PAYMENT: PAYMENT.th,
    PRODUCT_DETAIL: PRODUCT_DETAIL.th,
    PRODUCT_SEARCH: PRODUCT_SEARCH.th,
    SCHEDULE: SCHEDULE.th,
  },
};

export default translations;
