import React from "react";

import HttpClientService from "../services/http-client";
import { CircularProgress } from "@material-ui/core";
import get from "lodash/get";
const httpClientService = new HttpClientService();

export default Component =>
  class extends React.Component {
    state = {
      loading: true,
      isReadyToCheckout: undefined,
      errorMessage: "",
    };

    async componentWillMount() {
      try {
        const { projectId } = this.props;
        await httpClientService.validateCheckoutCart(projectId);
        this.setState({
          loading: false,
          isReadyToCheckout: true,
        });
      } catch (err) {
        this.setState({
          loading: false,
          isReadyToCheckout: false,
          errorMessage: get(err, "response.data.message") || "ERROR",
        });
      }
    }

    render() {
      const { loading, isReadyToCheckout, errorMessage } = this.state;

      if (loading) {
        return (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={150} />
          </div>
        );
      }

      return <Component {...this.props} isReadyToCheckout={isReadyToCheckout} checkoutErrorMessage={errorMessage} />;
    }
  };
