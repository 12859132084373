import React, { useEffect, useState } from "react";
import moment from "moment";
import get from "lodash/get";

import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { DateTimePicker } from "@material-ui/pickers";

import HttpClientService from "../../../../../../services/http-client";

import { Container, Grid, Typography, Divider, CircularProgress } from "@material-ui/core";
import {
  TIME_FORMAT,
  TIME_INPUT_FORMAT,
  isDuringShopOpeningHours,
  getSuggestDeliveryTime,
  getSuggestDeliveryTimeWithDeliveryConfiguration,
  isDeliveryTimeValidWithDeliveryConfiguration,
} from "../../../../../../utils/offlineStore";
import { DATE_TIME } from "../../../../../../constants/index";

const httpClientService = new HttpClientService();

const defaultDeliveryConfiguration = {
  start: "02:00:00+00:00",
  end: "11:00:00+00:00",
  timeInAdvance: 120,
};

const Schedule = ({ projectId, shippingMethod, setDeliverySchedule, setIsValidSchedule }) => {
  const { t } = useTranslation(["SCHEDULE", "COMMON", "DAYS"]);

  const currentDate = moment().toDate();

  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [isLoading, setIsLoading] = useState(true);
  const [deliveryConfiguration, setDeliveryConfiguration] = useState(defaultDeliveryConfiguration);
  const [offlineStore, setOfflineStore] = useState({});

  const getOfflineStoreAddress = offlineStoreObject => {
    if (!offlineStoreObject) {
      return null;
    }
    const { address, subDistrict, district, province, postalCode } = offlineStoreObject;
    return [address, subDistrict, district, province, postalCode].filter(Boolean).join(" ");
  };

  const startTimeLabel = moment(deliveryConfiguration.start, TIME_FORMAT)
    .add(Number(deliveryConfiguration.timeInAdvance), "minutes")
    .format(TIME_INPUT_FORMAT);

  const endTimeLabel = moment(deliveryConfiguration.end, TIME_FORMAT)
    .subtract(Number(deliveryConfiguration.timeInAdvance), "minutes")
    .format(TIME_INPUT_FORMAT);

  const isActiveDeliverySchedule = get(deliveryConfiguration, "isActive") || true;

  const isValidSchedule =
    (offlineStore
      ? isDuringShopOpeningHours(offlineStore, selectedDate)
      : isDeliveryTimeValidWithDeliveryConfiguration(deliveryConfiguration, selectedDate)) && isActiveDeliverySchedule;

  const offlineStoreAddress = getOfflineStoreAddress(offlineStore);

  useEffect(() => {
    const getProjectConfiguration = async () => {
      try {
        const { data } = await httpClientService.getDeliveryConfiguration(
          projectId,
          get(shippingMethod, "method"),
          get(shippingMethod, "offlineStore.id"),
        );
        const { configuration, offlineStore: branch } = data; // TODO: get data from props instead

         // use defaultDeliveryConfiguration if project does not have this configuration
         const projectDeliveryConfiguration = configuration || defaultDeliveryConfiguration

        setIsLoading(false);
        setOfflineStore(branch);
        const suggestTime = branch
          ? getSuggestDeliveryTime(branch, projectDeliveryConfiguration, selectedDate)
          : getSuggestDeliveryTimeWithDeliveryConfiguration(projectDeliveryConfiguration);

        setSelectedDate(suggestTime);

        setDeliveryConfiguration(projectDeliveryConfiguration);
      } catch (error) {
        throw new Error(error);
      }
    };

    getProjectConfiguration();

    const deliverySchedule = moment(selectedDate).toISOString();

    setDeliverySchedule(deliverySchedule);
  }, []);

  useEffect(() => {
    setDeliverySchedule(selectedDate);
    setIsValidSchedule(isValidSchedule);
  }, [isValidSchedule, selectedDate]);

  if (isLoading) {
    return (
      <Grid container item xs={12}>
        <div style={{ display: "flex", justifyContent: "center", width: "calc(100vw - 100px)" }}>
          {/* TODO: MUI StepContent cannot set child component center, will using style inline for now */}
          <CircularProgress size="40px" className="my-2" />
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Formik enableReinitialize>
        <Form noValidate>
          <Container className="py-3" style={{ background: "#f5f7fb", borderRadius: 6 }}>
            <Grid container justify="center" className="mb-3">
              <DateTimePicker
                style={{ minWidth: 260, background: "#fff" }}
                autoOk
                ampm={false}
                value={selectedDate}
                onChange={setSelectedDate}
                format={DATE_TIME.FULL_DATE_TIME_DISPLAY_FORMAT}
                inputVariant="outlined"
                disablePast
                hideTabs
                error={!isValidSchedule}
                label={!isValidSchedule && <p className="mt-2">{t("UNAVAILABLE")}</p>}
                okLabel={t("OK")}
                cancelLabel={t("CANCEL")}
              />
            </Grid>

            {offlineStore && offlineStoreAddress && (
              <Grid item container justify="center" alignItems="center" className="mb-3">
                <Grid item>
                  <Typography variant="body2" gutterBottom align="center">
                    {offlineStore.name}
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Divider variant="middle" />

            <Grid container justify="center" alignItems="center" className="mt-3">
              <Grid item>
                <Typography fontWeight="fontWeightBold" variant="body2" gutterBottom>
                  {t("WORKING_HOURS")}
                </Typography>
              </Grid>
            </Grid>

            <Grid container justify="center" alignItems="center">
              <Grid container item justify="center" alignItems="center">
                {offlineStore && offlineStore.openingHours ? (
                  <Grid container item justify="center" alignItems="center">
                    {offlineStore.openingHours.map(openingHour => {
                      return (
                        <React.Fragment key={`${openingHour.day}`}>
                          <Grid item xs={4}>
                            <Typography variant="body2" gutterBottom>
                              {t(`DAYS:${openingHour.day}`)}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container justify="flex-end">
                              <Typography variant="body2" gutterBottom>
                                {!openingHour.isActive
                                  ? t("NOT_DELIVERY")
                                  : `${openingHour.open} - ${openingHour.close}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                ) : (
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      {startTimeLabel} - {endTimeLabel}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Container>
        </Form>
      </Formik>
    </>
  );
};

export default Schedule;
