import React from "react";
import qs from "qs";
import * as Sentry from "@sentry/browser";

import { ACTION, QUERY_PARAM } from "../constant"
import HttpClientService from "../services/http-client";

const withPaymentInformation = Component => class WithPaymentInformationWrapper extends React.Component {
  state = {
    success: false,
    paymentInformation: {},
    validatedQuery: {},
  }

  async componentDidMount() {
    const query = qs.parse(this.props.location.search.replace("?", ""));
    const httpClient = new HttpClientService()
    if (query[QUERY_PARAM.ACTION] === ACTION.CLOSE) {
      if (window.liff) {
        window.liff.init(
          _data => {
            httpClient.closeWindow()
          },
          _err => {

          }
        )
      }

      await httpClient.closeWindow()
    }

    if (query.projectId && query.amount && query.orderNumber) {
      try {
        const {projectId, amount, orderNumber, paymentGatewayId} = query
        const validatedQuery = {
          orderNumber,
          projectId,
          amount: Math.round(Number(Number(amount).toFixed(2)) * 100), // Follow by chillpay document that treat last 2 digit as floating point
        }

        const [paymentInformation, storeInformation] = await Promise.all([
          httpClient.getPaymentGatewayInformation(projectId, orderNumber, paymentGatewayId),
          httpClient.getStoreDetail(projectId),
        ])
        this.setState({ paymentInformation: { ...paymentInformation.data, storeName: storeInformation.data.name }, success: true, validatedQuery })
      } catch(e) {
        Sentry.captureException(e)
        await httpClient.closeWindow()
      }
    } else {
      await httpClient.closeWindow()
    }
  }

  render() {
    if (this.state.success) {
      return <Component
        {...this.props}
        {...this.state.validatedQuery}
        paymentInformation={this.state.paymentInformation}
      />
    } else {
      return null
    }
  }
}

export default withPaymentInformation
