import Grid from "@material-ui/core/Grid";
import styled, { css } from "styled-components";

export const CheckBoxContainer = styled.button`
  outline: none;
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  text-align: left;
  background-color: ${({ theme }) => theme.COLORS.White};
  border: solid 1px
    ${({ isActive, theme }) =>
      isActive ? theme.COLORS.Primary : theme.COLORS.LightBlue};
  color: ${({ isActive, theme }) =>
    isActive ? theme.COLORS.Primary : theme.COLORS.DarkGray};
  font-size: 14px;
  cursor: pointer;
  &[disabled] {
    cursor: initial;
    color: ${({ theme }) => theme.COLORS.DarkLight};
    border: ${({ isNoBorder, theme }) =>
      `solid 1px ${isNoBorder ? theme.COLORS.White : theme.COLORS.DarkLight}`};
  }

  ${({ isNoBorder }) =>
    isNoBorder &&
    css`
      border: 1px solid ${({ theme }) => theme.COLORS.White};
    `}
`;

export const CheckButton = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: ${({ isRadio }) => (isRadio ? "12px" : "4px")};
  margin-right: 20px;

  background-color: ${({ isRadio, isActive, theme }) =>
    !isRadio && isActive ? theme.COLORS.Primary : theme.COLORS.White};
  border: solid 1px
    ${({ isActive, theme }) =>
      isActive ? theme.COLORS.Primary : theme.COLORS.LightBlue};
`;

export const SelectedIcon = styled.div`
  width: 18px;
  height: 18px;
  margin: 3px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.COLORS.Primary};
`;

export const StyledGridCheckButton = styled(Grid)`
  height: 26px;
  width: 26px;
`;

export const ChildrenWrapper = styled(Grid)`
  flex: 1;
`;
