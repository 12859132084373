import styled from "styled-components";
import { COLOR_BY_STATUS } from "../../../constants/colors";

export const OrderDetailWrapper = styled.div`
  font-size: 11px;
`;

export const OrderNumberLabel = styled.div`
  font-weight: bold;

  color: ${({ theme }) => theme.COLORS.DarkMed};
`;

export const PromotionLabel = styled.div`
  color: ${({ theme }) => theme.COLORS.Error};
`;

export const OrderTotalLabel = styled.div`
  font-weight: bold;

  color: ${({ theme }) => theme.COLORS.Dark};
`;

export const OrderNumberValue = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme }) => theme.COLORS.Dark};
`;

export const DiscountValue = styled.span`
  color: ${({ theme }) => theme.COLORS.Error};
`;

export const PromotionText = styled.span`
  color: ${({ theme }) => theme.COLORS.Error};
`;

export const VatOption = styled.span`
  text-transform: uppercase;
`;

export const OrderStatus = styled.div`
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.White};
  border-radius: 10px 0 0 10px;
  padding: 5px 15px;
  background-color: ${({ state }) => COLOR_BY_STATUS[state]};
`;

export const OrderHeaderLabel = styled.div`
  width: 100%;
  height: 33px;
  line-height: 33px;

  color: ${({ theme }) => theme.COLORS.DarkMed};
  background-color: ${({ theme }) => theme.COLORS.Light};
`;

export const ProductTitle = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.Dark};
`;

export const OrderDivider = styled.div`
  width: 100%;
  border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
`;

export const AmountBox = styled.span`
  width: 21px;
  height: 21px;
  border-radius: 5px;

  line-height: 21px;
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.Primary};
  text-align: center;
  padding: 4px;
  background-color: ${({ theme }) => theme.COLORS.LightGray};
`;

export const AmountPrice = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.Primary};
`;

export const AmountPriceBefore = styled.span`
  text-decoration: line-through;
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.DarkLight};
`;

export const GrandTotalPrice = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.Dark};
`;
