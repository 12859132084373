import { ORDER_STATES, SHIPPING_METHOD } from "../../../constants/index";
import { formatMoney } from "../../../utils/product/index";

const getShippingPriceText = (orderState, shippingMethod, shippingPrice) => {
  if (orderState === ORDER_STATES.STATES_EN.PENDING_FOR_SHIPPING_COST) {
    return ORDER_STATES.STATES_TH.PENDING_FOR_SHIPPING_COST;
  }

  if (shippingMethod === SHIPPING_METHOD.SHIPPING_METHOD.PICK_UP) {
    return SHIPPING_METHOD.SHIPPING_METHOD_TH.PICK_UP;
  }

  return `฿ ${formatMoney(shippingPrice)}`;
};

export default getShippingPriceText;
