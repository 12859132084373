/* eslint-disable no-irregular-whitespace */

const { sumBy, get } = require('lodash')
const moment = require('moment')

const { PROMOTION } = require('../../constants')

/**
 * Map promotion with promotion name for flex message
 * @param {Object} promotion
 * @returns {String}
 */
function getPromotionDisplayNameForFlexMessage(promotion) {
  if (!promotion) {
    return ''
  }

  const fullName = get(promotion, 'fullName')
  // just display full name promotion for support enterprise promotion
  if (fullName) {
    return fullName
  }

  const description = get(promotion, 'description')
  if (description) {
    return description
  }

  const promotionName = get(promotion, 'name')
  if (promotionName) {
    return promotionName
  }

  // Buy X get Y
  if (promotion.settings.type === PROMOTION.SETTING.TYPE.BUY_X_GET_Y) {
    const totalAmountOfFreeProducts = sumBy(promotion.freeProducts, 'amount')

    if (promotion.settings && promotion.settings.threshold && promotion.settings.threshold.amount) {
      return `ซื้อ ${promotion.settings.threshold.amount} ชิ้น แถม ${totalAmountOfFreeProducts} ชิ้น*`
    }

    return `ซื้อ 1 แถม​ ${totalAmountOfFreeProducts} ชิ้น*`
  }

  // Fixed amount
  if (promotion.settings.type === PROMOTION.SETTING.TYPE.FIXED_AMOUNT_DISCOUNT) {
    if (promotion.settings && promotion.settings.threshold && promotion.settings.threshold.amount) {
      return `ลด ${promotion.settings.discount.amount} บาท เมื่อซื้อ ${promotion.settings.threshold.amount} ชิ้น*`
    }

    return `ลด ${promotion.settings.discount.amount} บาท เมื่อซื้อของทั้งหมดราคา ${promotion.settings.threshold.price} บาท*`
  }

  // Fixed price
  if (promotion.settings.type === PROMOTION.SETTING.TYPE.FIXED_PRICE_DISCOUNT) {
    return `ซื้อ ${promotion.settings.threshold.amount} ชิ้นในราคา ${promotion.settings.discount.fixed} บาท*`
  }

  // Free giveaway
  if (promotion.settings.type === PROMOTION.SETTING.TYPE.FREE_GIVEAWAY) {
    const totalAmountOfFreeProducts = sumBy(promotion.freeProducts, 'amount')

    if (promotion.settings && promotion.settings.threshold && promotion.settings.threshold.price) {
      return `เมื่อซื้อของทั้งหมดราคา ${promotion.settings.threshold.price} บาท รับของแถม ${totalAmountOfFreeProducts} ชิ้น*`
    }

    return `ฟรีของแถม ${totalAmountOfFreeProducts} ชิ้น*`
  }

  // Free shipping
  if (promotion.settings.type === PROMOTION.SETTING.TYPE.FREE_SHIPPING) {
    if (promotion.settings && promotion.settings.threshold && promotion.settings.threshold.price) {
      return `ส่งฟรี เมื่อซื้อของทั้งหมดราคา ${promotion.settings.threshold.price} บาท*`
    }

    return 'ส่งฟรี*'
  }

  // Percentage
  if (promotion.settings.type === PROMOTION.SETTING.TYPE.PERCENTAGE_DISCOUNT) {
    return `ลด ${promotion.settings.discount.percent}% เมื่อซื้อ ${promotion.settings.threshold.amount} ชิ้น*`
  }

  return ''
}

const compareCampaignCreateAt = (promotionA, promotionB) => {
  const campaignPromotionA = get(promotionA, 'campaign')
  const campaignPromotionB = get(promotionB, 'campaign')
  if (!campaignPromotionA || !campaignPromotionB || campaignPromotionA.id === campaignPromotionB.id) {
    return 0
  }
  const campaignCreatedAtPromotionA = moment(get(campaignPromotionA, 'createdAt'))
  const campaignCreatedAtPromotionB = moment(get(campaignPromotionB, 'createdAt'))
  const campaignCreateAtPriority = campaignCreatedAtPromotionA < campaignCreatedAtPromotionB ? 1 : -1

  return campaignCreateAtPriority
}

const comparePromotionPriority = (promotionA, promotionB) => {
  const priorityPromotionA = get(promotionA, 'priority')
  const priorityPromotionB = get(promotionB, 'priority')
  const promotionPriority = priorityPromotionA < priorityPromotionB ? -1 : 1

  return promotionPriority
}
/**
 * sort promotions by priority
 *
 * campaign created date > promotion priority
 *
 * @param {object<Promotion>} promotionA
 * @param {object<Promotion>} promotionB
 */
const sortPromotionByPriority = (promotionA, promotionB) => {
  // chain compare function with ||
  return compareCampaignCreateAt(promotionA, promotionB) || comparePromotionPriority(promotionA, promotionB)
}

/**
 * return first sorted promotions by sortPromotionByPriority
 * @param {object[]} productSKUs
 * @return {object} promotion
 */
function getRelatedPromotionForProductHeaderBadge(promotions) {
  // sort latest updateAt first
  const sortedPromotions = promotions.sort(sortPromotionByPriority)

  if (!sortedPromotions.length) {
    return null
  }

  return sortedPromotions[0]
}

module.exports = { getPromotionDisplayNameForFlexMessage, getRelatedPromotionForProductHeaderBadge, sortPromotionByPriority }
