const PRODUCT_SEARCH = {
  en: {
    SEARCH_TITLE: "Search",
    SELECT: "Select",
  },
  th: {
    SEARCH_TITLE: "ค้นหา",
    SELECT: "เลือก",
  },
};

export default PRODUCT_SEARCH;
