import React from "react";
import Grid from "@material-ui/core/Grid";
import Schedule from "./Schedule";

const SelectDeliveryDateTime = ({
  projectId,
  setDeliverySchedule,
  shippingMethod,
  isValidSchedule,
  setIsValidSchedule,
}) => {
  return (
    <Grid container>
      <Grid item xs={12} className="p-2">
        <Schedule
          projectId={projectId}
          shippingMethod={shippingMethod}
          setDeliverySchedule={setDeliverySchedule}
          isValidSchedule={isValidSchedule}
          setIsValidSchedule={setIsValidSchedule}
        />
      </Grid>
    </Grid>
  );
};

export default SelectDeliveryDateTime;
