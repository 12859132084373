import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

import { COLORS } from "../../../../../constants/index";

export const StyledCard = styled.div`
  padding: 16px;
  border-radius: 5px;
  background-color: ${COLORS.Light};
`;

export const Divider = styled.hr`
  border-style: dash;
  border-color: ${COLORS.LightBlue};
  border-width: 0.5px 0 0 0;
  margin: 4px 0;
  width: 100%;
`;

export const StoreLogo = styled.img`
  height: 40px;
  width: 40px;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ textColor }) => textColor || "inherit"};
`;

export const StoreName = styled(Typography)`
  font-weight: bold;
`;
