import React from "react";
import { get } from "lodash";
import styled, { css } from "styled-components";
import { Grid, Paper, Button, TextField } from "@material-ui/core";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const base64ToJSON = s => (s ? JSON.parse(Buffer.from(s, "base64").toString()) : undefined);

const convertPriceFormat = (price = 0, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
  return price.toLocaleString("en-US", { minimumFractionDigits, maximumFractionDigits });
};

const isMobileDevice = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i].some((value) => {
  return navigator.userAgent.match(value);
});

const GRID_CONTAINER_SPACING = 32;

const baseItemCSS = css`
  padding: 8px 16px;
`;

const GridItem = styled(Grid)`
  ${baseItemCSS};
`;

const SectorContainer = styled(Paper)`
  padding: 16px;
`;

const SaveButton = styled(Button)`
  background: ${({ theme }) => theme.COLORS.Primary};
  color: #f9fdff;
  margin-top: 24px;
`;

const TitleContainer = styled.div`
  height: 33px;
  line-height: 33px;
  background-color: #f5f7fb;
  color: #737d94;
  font-size: 11px;
  padding-left: 16px;
  text-align: center;
  display: flex;
  align-items: flex-start;
  align-items: flex-start;
`;

const StyledTextField = styled(({ color, ...otherProps }) => <TextField {...otherProps} />)`
  input {
    color: #42495a;
    line-height: 2rem;
    height: 2rem;
  }
  .MuiInputBase-input .MuiOutlinedInput-input,
  .MuiOutlinedInput-input {
    box-sizing: border-box;
    line-height: initial;
    height: initial;
  }
  .MuiInput-underline {
    border-bottom: 1px solid #cad2e4;
  }
  .MuiInput-underline:hover,
  .MuiInput-underline:after,
  .MuiInput-underline.Mui-focused {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.Primary};
  }
  .MuiInput-underline.Mui-error,
  .MuiInput-underline.Mui-error:after {
    border-bottom: 1px solid #ff5472;
  }

  .MuiInput-underline.Mui-disabled {
    border-bottom: 1px solid #cad2e4;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiInput-underline:before {
    border: 0;
  }
`;

const FormikTextField = ({ errors, touched, name, label, required, endAdornment, ...otherProps }) => {
  const isError = get(errors, name) && get(touched, name);
  const extraProps = {};
  if (endAdornment) {
    extraProps.InputProps = {
      endAdornment,
    };
  }
  return (
    <StyledTextField
      label={required ? `${label} *` : label}
      error={isError}
      helperText={isError ? get(errors, name) : null}
      name={name}
      {...otherProps}
      {...extraProps}
    />
  );
};

export const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.White};
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  z-index: 1;
  height: 74px;
  box-shadow: 0 -5px 15px 0 rgba(0, 0, 0, 0.1);
`;

export {
  base64ToJSON,
  baseItemCSS,
  convertPriceFormat,
  FormikTextField,
  GRID_CONTAINER_SPACING,
  GridItem,
  isMobileDevice,
  SaveButton,
  SectorContainer,
  sleep,
  TitleContainer,
};
