const ADDRESS_FORM = {
  en: {
    DELIVERY_NOTE: "Additional note",
    DISTRICT: " District",
    EMAIL: "E-mail",
    FIRST_NAME: "First name",
    FORM_INVALID: "{{fieldName}} is invalid",
    FORM_LENGTH: "{{fieldName}} should be {{length}} digits",
    FORM_NUMBER_ONLY: "Only accept number",
    FORM_PHONE_NUMBER_ONLY: "Only accept phone number 10 digits e.g. 0912345678",
    FORM_RANGE_LENGTH: "{{fieldName}} should be between {{max}} digits",
    FORM_REQUIRED: "{{fieldName}} is required",
    FORM_CORRECT: "Please fill {{fieldName}} correct",
    LAST_NAME: "Last name",
    NO_POSTAL_CODE: "There is no postal code",
    MOBILE_PHONE_NUMBER: "Mobile Phone number 10 digits e.g. 0912345678",
    PHONE_NUMBER: "Mobile Phone number",
    PLEASE_RETRY: "Please retry",
    POSTAL_CODE: "Postal code",
    PROVINCE: "Province",
    SEARCH: "Search by postal code, province district, sub-district",
    SHIPPING_INFORMATION: "Shipping information",
    STREET_ADDRESS: "Address",
    STREET_ADDRESS2: "Additional address (optional)",
    SUB_DISTRICT: "Sub district",
    USE_THIS_ADDRESS: "Use this address for shipping",
    SUBMIT: "Submit",
    NEXT: "Next",
    BACK: "Back",
    SHIPPING_METHOD: "Shipping method",
    DELIVERY_DATE_TIME: "Delivery date time",
    DELIVERY_SCHEDULE: "Delivery schedule",
    ORDER_SUMMARY: "Order summary",
    ORDER_SUMMARY_DIGITAL_ONLY: "Order summary",
    CASH_ON_DELIVERY: "Cash on delivery (COD)",
    POSTAL_CODE_BASED_PRICE: "Price base on postal code",
    DISTANCE_BASE_PRICE: "Price base on distance",
    DAY: "Day",
    DISCOUNT: "Discount",
    DISCOUNT_SHIPPING: "Discount(Shipping)",
    SUB_TOTAL: "Sub total",
    SHIPPING_VIA: "Shipping via",
    TOTAL_PRICE: "Total price",
    VAT: "Vat",
    ITEMS: "Item(s)",
    MINIMUM_TOTAL_PRICE_PER_ORDER: "Minimum total price per order",
    DEPOSIT_AMOUNT: "Deposit amount",
    THB: "฿",
    FREE: "Free",
    WAITING_FOR_ADMIN_CONFIRM_SHIPPING_COST: "Waiting for shipping cost confirmation from admin",
    // Carrier translation
    DHL: "DHL",
    "Flash express": "Flash express",
    "J&T": "J&T",
    Kerry: "Kerry",
    "SCG yamato express": "SCG yamato express",
    "Thaipost-EMS": "Thaipost-EMS",
    "Thaipost-Register": "Thaipost-Register",
    TNT: "TNT",
    CART_EMPTY: "Cart empty",
    OUT_OF_SERVICE_AREA: "Out of service area",
    OUT_OF_AVAILABLE_TIME: "Out of available time",
    SHIPPING_IS_NOT_AVAILABLE: "Shipping is not available",
    UNAVAILABLE: "Can't deliver on this time",
    SELECT_DELIVERY_TIME: "Select delivery time",
    WORKING_HOURS: "Working hours",
    NOT_DELIVERY: "Not delivery this day",
    CHOOSE_DELIVERY_TIME: "Choose delivery time",
    FASTEST_DELIVERY_TIME: "As soon as possible",
    NOTE: "Note",
    PLEASE_TRY_AGAIN: "Please try again",
  },
  th: {
    DELIVERY_NOTE: "โน้ตเพิ่มเติม",
    DISTRICT: "เขต/อำเภอ",
    EMAIL: "อีเมล",
    FIRST_NAME: "ชื่อ",
    FORM_INVALID: "{{fieldName}}ไม่ถูกต้อง",
    FORM_LENGTH: "{{fieldName}}ต้องมี {{length}} หลัก",
    FORM_MIN_LENGTH: "กรุณาใส่{{fieldName}} {{length}} หลัก",
    FORM_NUMBER_ONLY: "กรุณาใส่ตัวเลข",
    FORM_PHONE_NUMBER_ONLY: "กรุณาใส่ตัวเลขเบอร์โทรศัพท์มือถือ 10 หลัก เช่น 0912345678",
    FORM_RANGE_LENGTH: "{{fieldName}} ต้องมี {{max}} ตัว",
    FORM_REQUIRED: "กรุณากรอก{{fieldName}}",
    FORM_CORRECT: "กรุณากรอก{{fieldName}} ให้ถูกต้อง",
    LAST_NAME: "นามสกุล",
    NO_POSTAL_CODE: "ไม่มีรหัสไปรษณีย์",
    MOBILE_PHONE_NUMBER: "เบอร์โทรศัพท์มือถือ 10 หลัก เช่น 0912345678",
    PHONE_NUMBER: "เบอร์โทรศัพท์มือถือ",
    PLEASE_RETRY: "กรุณาลองใหม่อีกครั้ง",
    POSTAL_CODE: "รหัสไปรษณีย์",
    PROVINCE: "จังหวัด",
    SEARCH: "ค้นหาด้วย รหัสไปรษณีย์ จังหวัด อำเภอ ตำบล",
    SHIPPING_INFORMATION: "ข้อมูลการจัดส่ง",
    STREET_ADDRESS: "ที่อยู่",
    STREET_ADDRESS2: "ที่อยู่เพิ่มเติม (ถ้ามี)",
    SUB_DISTRICT: "แขวง/ตำบล",
    USE_THIS_ADDRESS: "ใช้ที่อยู่นี้เพื่อจัดส่ง",
    SUBMIT: "ยืนยัน",
    NEXT: "ถัดไป",
    BACK: "กลับ",
    SHIPPING_METHOD: "วิธีการจัดส่ง",
    DELIVERY_DATE_TIME: "เลือกเวลาจัดส่ง",
    DELIVERY_SCHEDULE: "เวลาจัดส่ง",
    ORDER_SUMMARY: "สรุปออเดอร์และข้อมูลการจัดส่ง",
    ORDER_SUMMARY_DIGITAL_ONLY: "สรุปออเดอร์",
    CASH_ON_DELIVERY: "ชำระเงินปลายทาง (COD)",
    POSTAL_CODE_BASED_PRICE: "ราคาค่าจัดส่งตามรหัสไปรษณีย์",
    DISTANCE_BASE_PRICE: "แอดมินคำนวณค่าจัดส่ง", //fix constant later
    DAY: "วัน",
    DISCOUNT: "ส่วนลด",
    DISCOUNT_SHIPPING: "ส่วนลด(ค่าจัดส่ง)",
    SUB_TOTAL: "ราคาสินค้ารวม",
    SHIPPING_VIA: "จัดส่งโดย",
    TOTAL_PRICE: "ราคารวม",
    VAT: "ภาษี",
    ITEMS: "รายการ",
    MINIMUM_TOTAL_PRICE_PER_ORDER: "ยอดสั่งซื้อขั้นต่ำ",
    DEPOSIT_AMOUNT: "ราคามัดจำ",
    THB: "฿",
    FREE: "ฟรี",
    WAITING_FOR_ADMIN_CONFIRM_SHIPPING_COST: "รอยืนยันค่าจัดส่งจากแอดมิน",
    // Carrier translation
    DHL: "ดีเอชแอล",
    "Flash express": "แฟลชเอ็กซ์แพรส",
    "J&T": "เจแอนด์ที",
    Kerry: "เคอรี่",
    "SCG yamato express": "เอสซีจี ยามาโตะ",
    "Thaipost-EMS": "ไปรษณีย์ไทย - ส่งด่วนพิเศษ",
    "Thaipost-Register": "ไปรษณีย์ไทย - ส่งลงทะเบียน",
    TNT: "ทีเอ็นที",
    CART_EMPTY: "คุณยังไม่มีสินค้าในรายการสั่งซื้อ",
    OUT_OF_SERVICE_AREA: "เนื่องจากอยู่นอกพื้นที่ให้บริการ",
    OUT_OF_AVAILABLE_TIME: "เนื่องจากอยู่นอกเวลาให้บริการ",
    SHIPPING_IS_NOT_AVAILABLE: "ไม่สามารถให้บริการได้ในขณะนี้",
    UNAVAILABLE: "ไม่สามารถจัดส่งในช่วงเวลาดังกล่าวได้",
    SELECT_DELIVERY_TIME: "เลือกเวลาจัดส่ง",
    WORKING_HOURS: "เวลาในการจัดส่ง",
    NOT_DELIVERY: "ไม่จัดส่ง",
    CHOOSE_DELIVERY_TIME: "เลือกเวลาจัดส่ง",
    FASTEST_DELIVERY_TIME: "จัดส่งเร็วที่สุด",
    NOTE: "โน้ต",
    PLEASE_TRY_AGAIN: "กรุณาลองใหม่อีกครั้ง",
  },
};

export default ADDRESS_FORM;
