import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Typography,
} from "@material-ui/core";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import PropTypes from "prop-types";

const RequestOTP = ({ getOTP, sendVerifyOTP, refCode, isOTPVerified }) => {
  const { t } = withTranslation(["MEMBERSHIP", "COMMON"]);
  const [isSendPhoneNumber, setIsSendPhoneNumber] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const [OTP, setOTP] = useState();

  const handleClickSendVerifyOTP = () => {
    sendVerifyOTP(OTP, refCode);
  };

  const handleClickGetOTP = () => {
    getOTP(phoneNumber);
    setIsSendPhoneNumber(true);
  };

  if (isOTPVerified) {
    return (
      <Grid item xs={12} container justify="center" className="mt-5">
        <Typography variant="h6" color="primary">
          {t("VERIFIED_SUCCESS")} !!!
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={12}
      container
      justify="center"
      className="mt-5"
      alignItems="center"
      alignContent="center"
    >
      {isSendPhoneNumber ? (
        <>
          <Typography variant="subtitle1">{t("CONFIRM_REF_CODE")} :</Typography>
          <Typography variant="subtitle2" color="primary">
            {refCode}
          </Typography>

          <Grid item xs={12} container justify="center" className="pt-4">
            <TextField
              type="number"
              variant="outlined"
              name="OTP"
              value={OTP}
              onChange={(event) => setOTP(event.target.value)}
              label="OTP"
              required
            />
            <Button
              className="ml-2"
              variant="contained"
              color="primary"
              disabled={!OTP}
              onClick={handleClickSendVerifyOTP}
            >
              {t("COMMON:SUBMIT")}
            </Button>
          </Grid>
        </>
      ) : (
        <>
          <Typography variant="subtitle1">{t("GET_OTP")}</Typography>
          <Grid item xs={12} container justify="center" className="pt-4">
            <TextField
              type="number"
              variant="outlined"
              name="phoneNumber"
              value={phoneNumber}
              onChange={(event) =>
                setPhoneNumber(+parseInt(event.target.value, 10))
              }
              label={t("PHONE_NUMBER")}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIphoneIcon style={{ color: "#a1a6b9" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              className="ml-2"
              variant="contained"
              color="primary"
              disabled={!phoneNumber}
              onClick={handleClickGetOTP}
            >
              {t("GET_OTP")}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

RequestOTP.propTypes = {
  getOTP: PropTypes.func.isRequired,
  sendVerifyOTP: PropTypes.func.isRequired,
  refCode: PropTypes.string,
  isOTPVerified: PropTypes.bool.isRequired,
};

export default RequestOTP;
