const ORDER_STATE = {
  en: {
    ABANDONED: "Abandoned",
    ALL: "All",
    CANCELLED: "Cancelled",
    COD_PICKED_UP: "COD Shipping",
    COD_PREPARE_TO_SHIP: "COD Preparing",
    COD_REJECTED: "COD Cancelled",
    COMPLETED: "Completed",
    ORDER_PLACED: "New order",
    PENDING_FOR_SHIPPING_COST: "Cost pending",
    PREPARE_TO_SHIPPING: "Preparing",
    SHIPPED: "Completed",
    WAIT_FOR_PAYMENT_APPROVE: "Approval pending",
    VOIDED: "Void",
  },
  th: {
    ABANDONED: "ไม่ได้ชำระเงิน",
    ALL: "ทั้งหมด",
    CANCELLED: "ถูกยกเลิกแล้ว",
    COD_PICKED_UP: "COD รอการยืนยัน",
    COD_PREPARE_TO_SHIP: "COD เตรียมการจัดส่ง",
    COD_REJECTED: "COD ถูกยกเลิก",
    COMPLETED: "สำเร็จ",
    ORDER_PLACED: "รอการชำระเงิน",
    PENDING_FOR_SHIPPING_COST: "รอคำนวณค่าส่ง",
    PREPARE_TO_SHIPPING: "เตรียมการจัดส่ง",
    SHIPPED: "จัดส่งแล้ว",
    WAIT_FOR_PAYMENT_APPROVE: "กำลังตรวจสอบ",
    VOIDED: "ยกเลิกออเดอร์ (void)",
  },
};

export default ORDER_STATE;
