const OFFLINE_STORE = {
  en: {
    CHOOSE_BRANCH_AND_TIME: "Choose branch and receive time",
    BRANCH: "Branch",
    WORKING_HOUR: "Working hour",
    CLOSE: "Close",
    NO_AVAILABLE_STORE : "No available offline store",
  },
  th: {
    CHOOSE_BRANCH_AND_TIME: "เลือกสาขาและเวลารับสินค้า",
    BRANCH: "สาขา",
    WORKING_HOUR: "ช่วงเวลาทำการ",
    CLOSE: "ปิดทำการ",
    NO_AVAILABLE_STORE : "ไม่มีร้านค้าเปิดบริการ",
  },
};

export default OFFLINE_STORE;
