import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants/index";

export const BottomUpdateCartContainer = styled.div`
  position: fixed;
  bottom: 0;
  box-shadow: 0 -5px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: ${COLORS.White};
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const ButtonStyle = {
  backgroundColor: COLORS.White,
  borderRadius: "12px",
  color: COLORS.DarkMed,
  border: `solid 1px ${COLORS.LightBlue}`,
  padding: "5px",
};

export const ButtonDeleteStyle = {
  backgroundColor: COLORS.Light,
  color: COLORS.DarkMed,
  padding: "33px 6px",
  marginLeft: "8px",
};

export const ProductImageStyle = {
  height: "80px",
  width: "80px",
  border: "none",
  objectFit: "cover",
  margin: "0 10px",
};

export const SvgIcDelete = (props) => (
  <svg width="1.5em" height="1.5em" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.191 22.914a3.918 3.918 0 01-3.913 3.913h-9.19a3.918 3.918 0 01-3.913-3.913V9.942H24.19v12.972zM13 7.104c0-1.208.819-2.191 1.826-2.191h1.47c1.16 0 2.102 1.072 2.186 2.42h-5.53c.016-.078.048-.148.048-.229zm14.19.23h-6.103c-.093-2.789-2.198-5.03-4.791-5.03h-1.47c-2.446 0-4.435 2.154-4.435 4.8 0 .081.031.151.047.23H4.304a1.304 1.304 0 100 2.608h.262v12.972a6.53 6.53 0 006.522 6.522h9.19a6.53 6.53 0 006.522-6.522V9.942h.39a1.305 1.305 0 000-2.608zm-8.245 5.705c.72 0 1.305.585 1.305 1.305v7.46a1.304 1.304 0 11-2.609 0v-7.46c0-.72.584-1.305 1.304-1.305zm-6.522 0c.72 0 1.304.585 1.304 1.305v7.46a1.304 1.304 0 11-2.608 0v-7.46c0-.72.584-1.305 1.304-1.305z"
    />
  </svg>
);
