import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import { COLORS } from "../../../../../constants/index";
import { convertPriceFormat } from "../../../../../utils/common";
import { StyledCard, Divider, StyledTypography } from "./styled";

const PriceSummaryCard = props => {
  const {
    t,
    discountPrice = 0,
    subTotalPrice = 0,
    hasVat = false,
    vatValue = 0,
    vatPrice = 0,
    vatOptions,
    shippingPrice = 0,
    shippingMethod,
    grandTotal = 0,
    orderPromotions = [],
    shippingCarrier = "",
    shippingDiscountPrice = 0,
    isShippingDistanceBaseType = false,
  } = props;

  return (
    <StyledCard>
      {/* Discount */}
      {Boolean(discountPrice) && (
        <Grid container justify="space-between" alignItems="flex-start" className="mb-2">
          <Grid item xs={7}>
            <Typography variant="body2" color="error">
              {t("DISCOUNT")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="error">
              -฿{convertPriceFormat(discountPrice)}
            </Typography>
          </Grid>
        </Grid>
      )}
      {Boolean(shippingDiscountPrice) && (
        <Grid container justify="space-between" alignItems="flex-start" className="mb-2">
          <Grid item xs={7}>
            <Typography variant="body2" color="error">
              {t("DISCOUNT_SHIPPING")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="error">
              -฿{convertPriceFormat(shippingDiscountPrice)}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container item justify="space-between" alignItems="flex-start" className="mb-2">
        <Grid item xs={7}>
          <StyledTypography variant="body2" textColor={COLORS.DarkMed}>
            {t("SUB_TOTAL")}
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography variant="body2" textColor={COLORS.DarkMed}>
            ฿{convertPriceFormat(subTotalPrice)}
          </StyledTypography>
        </Grid>
      </Grid>

      {hasVat && (
        <Grid container item justify="space-between" alignItems="flex-start" className="mb-2">
          <Grid item xs={7}>
            <StyledTypography variant="body2" textColor={COLORS.DarkMed}>
              {t("VAT")} {vatValue}% {`(${vatOptions})`}
            </StyledTypography>
          </Grid>
          <Grid item>
            <StyledTypography variant="body2" textColor={COLORS.DarkMed}>
              ฿{convertPriceFormat(vatPrice)}
            </StyledTypography>
          </Grid>
        </Grid>
      )}

      {shippingMethod && (
        <Grid container item justify="space-between" alignItems="flex-start" className="mb-2">
          <Grid item xs={7}>
            <StyledTypography variant="body2" textColor={COLORS.DarkMed}>
              {t("SHIPPING_VIA")} {shippingCarrier}
            </StyledTypography>
            {isShippingDistanceBaseType && (
              <StyledTypography variant="caption" textColor={COLORS.DarkMed}>
                *{t("WAITING_FOR_ADMIN_CONFIRM_SHIPPING_COST")}
              </StyledTypography>
            )}
          </Grid>
          <Grid item>
            {!isShippingDistanceBaseType && (
              <StyledTypography variant="body2" textColor={COLORS.DarkMed}>
                {Boolean(shippingPrice) ? `฿${convertPriceFormat(shippingPrice)}` : t("FREE")}
              </StyledTypography>
            )}
          </Grid>
        </Grid>
      )}

      <Divider />
      {/* Grand total */}
      <Grid container item justify="space-between" alignItems="flex-start" className="mt-3">
        <Grid item xs={7}>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {t("TOTAL_PRICE")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            ฿{convertPriceFormat(grandTotal)}
          </Typography>
        </Grid>
      </Grid>

      {/* Promotion detail */}
      {Boolean(orderPromotions.length) && (
        <Grid container item className="mt-2">
          {orderPromotions.map((orderPromotion, index) => (
            <Grid key={orderPromotion.id} item xs={12}>
              <StyledTypography variant="caption" textColor={COLORS.DarkMed} className="mt-1">
                {!index && `Promotion: `}
                {orderPromotion.description || orderPromotion.name}
              </StyledTypography>
            </Grid>
          ))}
        </Grid>
      )}
    </StyledCard>
  );
};

PriceSummaryCard.propTypes = {
  discountPrice: PropTypes.number,
  shippingDiscountPrice: PropTypes.number,
  subTotalPrice: PropTypes.number.isRequired,
  hasVat: PropTypes.bool.isRequired,
  vatValue: PropTypes.number,
  vatPrice: PropTypes.number,
  vatOptions: PropTypes.string,
  shippingPrice: PropTypes.number.isRequired,
  shippingMethod: PropTypes.object.isRequired,
  grandTotal: PropTypes.number.isRequired,
  orderPromotions: PropTypes.array,
  shippingCarrier: PropTypes.string,
  isShippingDistanceBaseType: PropTypes.bool,
};

export default PriceSummaryCard;
