import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

const CustomPayment = ({ payload }) => {
  const { body, url } = payload

  const bodyInformation = Object.entries(body)

  return (
    <>
      <Grid container justify="center" className="p-4">
        <CircularProgress />
      </Grid>

      <form action={url} method="POST" style={{ display: "none" }}>
        {bodyInformation.map(bodyInfo => {
          const [name, value] = bodyInfo

          return (
            <>
              <input type="hidden" name={name} value={value} />
            </>
          )
        })}
        <input
          type="submit"
          name="submit"
          value="Confirm"
          id="buttonSubmitCustomPaymentGatewayForm"
        />
      </form>
    </>
  )
}

export default CustomPayment;
