import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import VConsole from "vconsole";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import config from "./config";
import * as serviceWorker from "./serviceWorker";

const { SENTRY_DSN, SENTRY_ENV } = config;

// Sentry init
Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV
});

// Init vConsole to help debugging on development
if (process.env.REACT_APP_IS_ENABLE_VCONSOLE === 'true') {
  new VConsole();
}

ReactDOM.render(<App />,document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
