import MUISVGIcon from "@material-ui/core/SvgIcon";
import styled from "styled-components";

import IcCheck from "./icCheck.js";

const IconWrapper = styled(MUISVGIcon)`
  overflow: visible;
  margin-right: 3px;
  padding-right: 6px;
  padding-bottom: 6px;
`;

export { IconWrapper, IcCheck };
