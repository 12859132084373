import React, { useEffect, useState } from "react";
import get from "lodash/get";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";

import { buttonInlineStyle, stepLabelInlineStyle, QontoStepIcon } from "./styled";
import EditAddress from "./EditAddress";
import OrderSummary from "./OrderSummary";
import SelectShippingMethod from "./SelectShippingMethod";
import SelectDeliveryDateTime from "./SelectDeliveryDateTime";

import config from "../../../../config";
import { SHIPPING_METHOD } from "../../../../constants";
import { TitleContainer, Footer } from "../../../../utils/common";

const { IS_USING_NEW_SHIPPING_SERVICE } = config;

export default function NewAddress(props) {
  const {
    t,
    projectId,
    isDisabled,
    hasSKUProductSKUs,
    hasDigitalProductSKUs,
    isFilled,
    submitAddress,
    addressData,
    shippingMethod,
    selectedShippingMethodIndex,
    setSelectedShippingMethodIndex,
    cartData,
    storeDetail,
    activeStep,
    setActiveStep,
    submitShippingMethod,
    isSubmitForm,
    isLoadingShippingMethod,
    deliverySchedule,
    setDeliverySchedule,
    isValidSchedule,
    setIsValidSchedule,
    isScheduleEnabled,
    isEmailRequired,
    isBackButtonEnabled,
  } = props;

  const TabConfigDigitalProductOnly = [t("SHIPPING_INFORMATION"), t("ORDER_SUMMARY_DIGITAL_ONLY")]; // for cart include ONLY digital content
  const [steps, setSteps] = useState(
    hasSKUProductSKUs
      ? [t("SHIPPING_INFORMATION"), t("SHIPPING_METHOD"), t("SELECT_DELIVERY_TIME"), t("ORDER_SUMMARY")] // for cart include physical SKU
      : TabConfigDigitalProductOnly,
  );

  const handleNext = () => {
    if (activeStep <= steps.length) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const isReadyToSubmit = activeStep === steps.length - 1;
  const isNotCompleteStep = activeStep !== steps.length;
  const isStartStep = activeStep !== 0;

  const disableSummaryStep = hasSKUProductSKUs && selectedShippingMethodIndex === null && activeStep === 1;

  const isSelectedShippingMethod = selectedShippingMethodIndex !== null;

  let isSelectedLalamove = false;
  let isHideDeliveryStep = false;
  let selectedShipping = null;
  if (IS_USING_NEW_SHIPPING_SERVICE) {
    selectedShipping =
      isSelectedShippingMethod && shippingMethod.find(shipping => shipping.shippingId === selectedShippingMethodIndex);

    isSelectedLalamove = get(selectedShipping, "type") === SHIPPING_METHOD.SHIPPING_TYPE.LALAMOVE;

    isHideDeliveryStep = !isScheduleEnabled && !isSelectedLalamove;
  } else {
    selectedShipping = isSelectedShippingMethod && get(shippingMethod, `[${selectedShippingMethodIndex}]`);

    isSelectedLalamove = get(selectedShipping, "calculateType") === SHIPPING_METHOD.SHIPPING_CALCULATE_TYPE.LALAMOVE;

    isHideDeliveryStep = !isScheduleEnabled && !isSelectedLalamove;
  }

  useEffect(() => {
    if (isSelectedLalamove) {
      // show delivery step
      setSteps(
        hasSKUProductSKUs
          ? [t("SHIPPING_INFORMATION"), t("SHIPPING_METHOD"), t("SELECT_DELIVERY_TIME"), t("ORDER_SUMMARY")] // for cart include physical SKU
          : TabConfigDigitalProductOnly,
      );
    } else if (isHideDeliveryStep) {
      // remove delivery step
      setSteps(
        hasSKUProductSKUs
          ? [t("SHIPPING_INFORMATION"), t("SHIPPING_METHOD"), t("ORDER_SUMMARY")] // for cart include physical SKU
          : TabConfigDigitalProductOnly,
      );
    }
  }, [selectedShippingMethodIndex]);

  return (
    <>
      <Grid container item xs={12} className="mb-5">
        <Stepper activeStep={activeStep} orientation="vertical" style={{ width: "100%", paddingTop: 0 }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel style={stepLabelInlineStyle} StepIconComponent={QontoStepIcon}>
                <TitleContainer>{label}</TitleContainer>
              </StepLabel>
              <StepContent className="d-flex">
                {index === 0 && (
                  <EditAddress
                    isDisabled={isDisabled}
                    hasSKUProductSKUs={hasSKUProductSKUs}
                    hasDigitalProductSKUs={hasDigitalProductSKUs}
                    isFilled={isFilled}
                    submitAddress={submitAddress}
                    addressData={addressData}
                    isEmailRequired={isEmailRequired}
                    t={t}
                  />
                )}

                {hasSKUProductSKUs ? ( // In case contain physical product
                  <>
                    {index === 1 && (
                      <>
                        {isLoadingShippingMethod ? (
                          <div style={{ display: "flex", justifyContent: "center", width: "calc(100vw - 100px)" }}>
                            {/* TODO: MUI StepContent cannot set child component center, will using style inline for now */}
                            <CircularProgress size="40px" className="my-2" />
                          </div>
                        ) : (
                          <SelectShippingMethod
                            t={t}
                            shippingMethod={shippingMethod}
                            setSelectedShippingMethodIndex={setSelectedShippingMethodIndex}
                            selectedShippingMethodIndex={selectedShippingMethodIndex}
                          />
                        )}
                      </>
                    )}

                    {index === 2 && isSelectedShippingMethod && !isHideDeliveryStep ? (
                      <SelectDeliveryDateTime
                        projectId={projectId}
                        shippingMethod={selectedShipping}
                        isValidSchedule={isValidSchedule}
                        setIsValidSchedule={setIsValidSchedule}
                        setDeliverySchedule={setDeliverySchedule}
                      />
                    ) : (
                      <>
                        {index === 2 && isSelectedShippingMethod && (
                          <OrderSummary
                            t={t}
                            cartData={cartData}
                            storeDetail={storeDetail}
                            addressData={addressData}
                            shippingData={selectedShipping}
                            hasSKUProductSKUs={hasSKUProductSKUs}
                            hasDigitalProductSKUs={hasDigitalProductSKUs}
                            deliverySchedule={deliverySchedule}
                          />
                        )}
                      </>
                    )}

                    {index === 3 && isSelectedShippingMethod && (
                      <OrderSummary
                        t={t}
                        cartData={cartData}
                        storeDetail={storeDetail}
                        addressData={addressData}
                        shippingData={selectedShipping}
                        hasSKUProductSKUs={hasSKUProductSKUs}
                        hasDigitalProductSKUs={hasDigitalProductSKUs}
                        deliverySchedule={deliverySchedule}
                      />
                    )}
                  </>
                ) : (
                  // In case contain only digital product
                  <>
                    {index === 1 && (
                      <OrderSummary
                        t={t}
                        cartData={cartData}
                        storeDetail={storeDetail}
                        addressData={addressData}
                        shippingData={selectedShipping}
                        hasSKUProductSKUs={hasSKUProductSKUs}
                        hasDigitalProductSKUs={hasDigitalProductSKUs}
                        deliverySchedule={deliverySchedule}
                      ></OrderSummary>
                    )}
                  </>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>

      {activeStep !== 0 && ( // activeStep = is for submit address
        <Footer>
          <Grid container item xs={12} className="mx-3">
            {isStartStep && isNotCompleteStep && (
              <Grid item xs={6} className="px-1">
                <Button
                  fullWidth
                  variant="outlined"
                  disabled={activeStep === 0 || !isBackButtonEnabled}
                  onClick={handleBack}
                  style={buttonInlineStyle}
                >
                  {t("BACK")}
                </Button>
              </Grid>
            )}
            {isNotCompleteStep && (
              <Grid item xs={isStartStep ? 6 : 12} className="px-1">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => (isReadyToSubmit ? submitShippingMethod() : handleNext())}
                  style={buttonInlineStyle}
                  disabled={
                    disableSummaryStep ||
                    isSubmitForm ||
                    (hasSKUProductSKUs && activeStep === 2 && !isValidSchedule) ||
                    isLoadingShippingMethod ||
                    !isBackButtonEnabled
                  }
                >
                  {isReadyToSubmit ? t("SUBMIT") : t("NEXT")}

                  {isSubmitForm && <CircularProgress size="24px" className="ml-2" />}
                </Button>
              </Grid>
            )}
          </Grid>
        </Footer>
      )}
    </>
  );
}
