import React from "react";
import { Redirect } from "react-router-dom";

export default (Component) => (props) => {
  const queryString = decodeURIComponent(window.location.search).replace(
    "?liff.state=",
    ""
  );
  const params = new URLSearchParams(queryString);
  const projectId = params.get("projectId");
  if (projectId !== null && projectId !== "") {
    return <Component {...props} projectId={projectId} />;
  } else {
    return <Redirect to="/" />;
  }
};
