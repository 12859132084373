import React from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import { COLORS, DATE_TIME } from "../../../../../constants/index";
import { Divider, StyledTypography } from "./styled";

const ShippingInformation = props => {
  const { t, address, phoneNo, email, deliverySchedule } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledTypography variant="body2" textColor={COLORS.DarkMed}>
          {t("SHIPPING_INFORMATION")}
        </StyledTypography>
      </Grid>
      <Divider className="py-2 my-1" />
      <Grid item xs={12}>
        <Typography variant="body2">{address}</Typography>
      </Grid>
      <Grid item xs={12} className="mt-2">
        <Typography variant="body2">
          {t("PHONE_NUMBER")} {phoneNo}
        </Typography>
      </Grid>
      {email && (
        <Grid item xs={12} className="mt-2">
          <Typography variant="body2">
            {t("EMAIL")}: {email}
          </Typography>
        </Grid>
      )}

      {deliverySchedule && (
        <Grid item xs={12} className="mt-2">
          <Typography variant="body2">
            {t("DELIVERY_SCHEDULE")} : {moment(deliverySchedule).format(DATE_TIME.FULL_DATE_TIME_DISPLAY_FORMAT)}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

ShippingInformation.propTypes = {
  t: PropTypes.func,
  address: PropTypes.string,
  phoneNo: PropTypes.string,
  email: PropTypes.string,
};

export default ShippingInformation;
