/**
 * Get initial customer address information
 * @param {object} shippingAddress
 * @param {boolean} hasSKUTypeProduct
 * @param {boolean} hasDigitalContentTypeProduct
 * @returns {object}
 */
export function getInitialCustomerAddressInformation(shippingAddress, hasSKUTypeProduct, hasDigitalContentTypeProduct) {
  if (!hasSKUTypeProduct && hasDigitalContentTypeProduct) {
    return {
      firstName: shippingAddress.firstName,
      lastName: shippingAddress.lastName,
      phoneNumber: shippingAddress.phoneNumber,
      email: shippingAddress.email,
      deliveryNote: shippingAddress.note,
    };
  }

  return {
    firstName: shippingAddress.firstName,
    lastName: shippingAddress.lastName,
    streetAddress: shippingAddress.streetAddress,
    streetAddress2: shippingAddress.streetAddress2,
    district: shippingAddress.district,
    subDistrict: shippingAddress.subDistrict,
    province: shippingAddress.province,
    postalCode: shippingAddress.postalCode,
    phoneNumber: shippingAddress.phoneNumber,
    email: shippingAddress.email,
    deliveryNote: shippingAddress.note,
  };
}
