import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

export const Wrapper = styled(Grid)`
  height: 100vh;
`;

export const ErrorTextTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.COLORS.Dark};
`;

export const ErrorTextDescription = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.DarkGray};
`;
