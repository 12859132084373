import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";

const TimePickerField = ({ field, form, ...other }) => {
  const { t } = useTranslation(["COMMON"]);
  const currentError = form.errors[field.name];
  return (
    <TextField
      fullWidth
      name={field.name}
      value={field.value}
      variant={"outlined"}
      label={t("TIME")}
      type="time"
      width={1}
      helperText={currentError}
      error={Boolean(currentError)}
      onChange={event => {
        form.setFieldValue(field.name, event.target.value, true);
        return;
      }}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        min: "00:00",
        max: "23:59",
        step: 300, // 5 min
      }}
      {...other}
    />
  );
};

export default TimePickerField;
