/* eslint-disable react/jsx-no-undef */
import React from "react";
import { get } from "lodash";

import {
  ACTION,
  LOCAL_STORAGE,
  ORDER_STATES,
  PAYMENT_GATEWAY,
  QUERY_PARAM,
} from "../../../constant";
import { withPaymentInformation } from "../../../hoc";
import OrderStatusReport from "./orderStatusReport";
import TwoCTwoPPayment from "./2C2P";
import ChillPayPayment from "./ChillPay";
import CustomPayment from "./Custom";

class PaymentPage extends React.Component {
  componentDidMount() {
    if (
      this.isValidPaymentInformationForChillPay() ||
      this.isValidPaymentInformationFor2C2P() || this.isValidPaymentInformationForCustom()
    ) {
      const hash = window.location.hash;
      const search =
        window.location.search + `&${QUERY_PARAM.ACTION}=${ACTION.CLOSE}`;
      const pathname = window.location.pathname;
      const origin = window.location.origin;
      window.localStorage.setItem(
        LOCAL_STORAGE.HREF,
        `${origin}${pathname}${search}${hash}`
      );

      if (document.getElementById("buttonSubmit2C2PForm")) {
        document.getElementById("buttonSubmit2C2PForm").click();
      }

      if (document.getElementById("buttonSubmitCustomPaymentGatewayForm")) {
        document.getElementById("buttonSubmitCustomPaymentGatewayForm").click();
      }
    }
  }

  isValidPaymentInformationForChillPay() {
    const { paymentInformation } = this.props
    const { orderStatus } = paymentInformation
    const apiKey = get(paymentInformation, 'payload.apiKey')
    const merchantId = get(paymentInformation, 'payload.merchantId')
    const customerId = get(paymentInformation, 'payload.customerId')

    return (
      orderStatus === ORDER_STATES.ORDER_PLACED && !!
      Boolean(apiKey) &&
      Boolean(merchantId) &&
      Boolean(customerId)
    );
  }

  isValidPaymentInformationFor2C2P() {
    const { paymentInformation } = this.props
    const { orderStatus } = paymentInformation
    const formattedAmount = get(paymentInformation, 'payload.amount')
    const twoCTwoPCurrency = get(paymentInformation, 'payload.currency')
    const enableStoreCard = get(paymentInformation, 'payload.enableStoreCard')
    const hashValue = get(paymentInformation, 'payload.hashValue')
    const merchantId = get(paymentInformation, 'payload.merchantId')
    const paymentDescription = get(paymentInformation, 'payload.paymentDescription')
    const paymentOption = get(paymentInformation, 'payload.paymentOption')
    const request3DS = get(paymentInformation, 'payload.request3DS')
    const resultURL1 = get(paymentInformation, 'payload.resultURL1')
    const resultURL2 = get(paymentInformation, 'payload.resultURL2')
    const version = get(paymentInformation, 'payload.version')
    const lang = get(paymentInformation, 'payload.lang')


    return (
      orderStatus === ORDER_STATES.ORDER_PLACED &&
      Boolean(formattedAmount) &&
      Boolean(twoCTwoPCurrency) &&
      Boolean(enableStoreCard) &&
      Boolean(hashValue) &&
      Boolean(merchantId) &&
      Boolean(paymentDescription) &&
      Boolean(paymentOption) &&
      Boolean(request3DS) &&
      Boolean(resultURL1) &&
      Boolean(resultURL2) &&
      Boolean(version) &&
      Boolean(lang)
    );
  }

  isValidPaymentInformationForCustom() {
    const { paymentInformation } = this.props
    const { orderStatus } = paymentInformation
    const header = get(paymentInformation, 'payload.header')
    const body = get(paymentInformation, 'payload.body')
    const url = get(paymentInformation, 'payload.url')

    return orderStatus === ORDER_STATES.ORDER_PLACED && Boolean(header) && Boolean(body) && Boolean(url)
  }

  render() {
    const { amount, orderNumber, paymentInformation } = this.props;
    const { orderStatus } = paymentInformation

    const isValidPaymentInformation = this.isValidPaymentInformationFor2C2P() || this.isValidPaymentInformationForChillPay() || this.isValidPaymentInformationForCustom()
    if (!isValidPaymentInformation) {
      return (
        <div>
          <OrderStatusReport orderNumber={orderNumber} status={orderStatus} />
        </div>
      )
    }

    const paymentGatewayType = paymentInformation.type;

    switch (paymentGatewayType) {
      case PAYMENT_GATEWAY.TYPE.TWO_C_TWO_P:
        return <TwoCTwoPPayment payload={paymentInformation.payload} handleSubmit={this.handleSubmit} />
      case PAYMENT_GATEWAY.TYPE.CHILL_PAY:
        return <ChillPayPayment amount={amount} orderNumber={orderNumber} storeName={paymentInformation.storeName} payload={paymentInformation.payload} />
      case PAYMENT_GATEWAY.TYPE.CUSTOM:
        return <CustomPayment payload={paymentInformation.payload} />
      default:
        return (
          <div>
            <OrderStatusReport orderNumber={orderNumber} status={orderStatus} />
          </div>
        )
    }
  }
}

export default withPaymentInformation(PaymentPage);
