import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { toast, ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import get from "lodash/get";
import httpStatus from "http-status";

import config from "../../../config";
import ErrorPage from "../../../components/ErrorPage";
import { SHIPPING_METHOD } from "../../../constants/shipping";
import { ERROR_MESSAGE } from "../../../constants/errorMessage";
import { withQueryProjectId, withQueryShippingAddress, withValidateCheckoutCart } from "../../../hoc";
import HttpClientService from "../../../services/http-client";
import { sleep } from "../../../utils/common";
import { getInitialCustomerAddressInformation } from "../../../utils/address";
import NewFormAddress from "./NewFormAddress";

const { IS_USING_NEW_SHIPPING_SERVICE } = config;

const httpClientService = new HttpClientService();

const AddressForm = props => {
  const {
    checkoutErrorMessage,
    hasDigitalProductSKUs,
    isDisabled,
    isReadyToCheckout,
    projectId,
    shippingAddress,
    t,
    hasSKUProductSKUs = true,
  } = props;

  const [shippingMethod, setShippingMethod] = useState([]);
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const [isLoadingShippingMethod, setIsLoadingShippingMethod] = useState(false);
  const [deliverySchedule, setDeliverySchedule] = useState(null);
  const [isValidSchedule, setIsValidSchedule] = useState(true);
  const [isScheduleEnabled, setIsScheduleEnabled] = useState(null);
  const [isEmailRequired, setIsEmailRequired] = useState(false);
  const [isBackButtonEnabled, setIsBackButtonEnabled] = useState(true);

  const [selectedShippingMethodIndex, setSelectedShippingMethodIndex] = useState(null);

  const [cartData, setCartData] = useState([]);

  const [storeDetail, setStoreDetail] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  const isFilled = !!shippingAddress;

  let customInit = {
    firstName: "",
    lastName: "",
    streetAddress: "",
    district: "",
    subDistrict: "",
    province: "",
    postalCode: "",
    phoneNumber: "",
    email: "",
    deliveryNote: "",
  };

  if (isFilled) {
    customInit = {
      ...customInit,
      ...getInitialCustomerAddressInformation(shippingAddress, hasSKUProductSKUs, hasDigitalProductSKUs),
    };
  }

  const [addressData, setAddressData] = useState(customInit);

  // #region Get shipping method
  const getShippingMethod = async address => {
    try {
      setIsLoadingShippingMethod(true);
      const responseData = await httpClientService.getShippingMethod(projectId, address);
      if (responseData && responseData.data) {
        setShippingMethod(responseData.data);
      }
    } catch (err) {
      const errStatus = get(err, "response.status");
      toast.error(t(errStatus), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoadingShippingMethod(false);
  };
  // #endregion

  // #region Get cart
  const getCart = async () => {
    try {
      const responseData = await httpClientService.getCart(projectId);
      if (responseData && responseData.data) {
        setCartData(responseData.data);
      }
    } catch (err) {
      const errStatus = get(err, "response.status");
      toast.error(t(errStatus), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  // #endregion

  // #region Get store detail
  const getStoreDetail = async () => {
    try {
      const responseData = await httpClientService.getStoreDetail(projectId);
      if (responseData && responseData.data) {
        setStoreDetail(responseData.data);
      }
    } catch (err) {
      const errStatus = get(err, "response.status");
      toast.error(t(errStatus), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  // #endregion

  // #region Get project configuration
  const getProjectConfiguration = async () => {
    try {
      const responseData = await httpClientService.getProjectConfiguration(projectId);
      if (responseData && responseData.data) {
        const scheduleIsActive = Boolean(get(responseData, "data.delivery.schedule.isActive"));
        const isEmailRequiredFlag = Boolean(get(responseData, "data.order.isEmailRequired"));
        setIsEmailRequired(isEmailRequiredFlag);
        setIsScheduleEnabled(scheduleIsActive);
      }
    } catch (err) {
      const errStatus = get(err, "response.status");
      toast.error(t(errStatus), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  // #endregion

  // #region Submit address to get supported shipping address
  const submitAddress = addressDataProps => {
    // set address data then go to the next step
    setAddressData(addressDataProps);

    if (hasSKUProductSKUs) {
      getShippingMethod(addressDataProps);
    }

    setActiveStep(activeStep + 1);
  };
  // #endregion

  // #region Add order
  const submitShippingMethodAndAddress = async () => {
    if (!IS_USING_NEW_SHIPPING_SERVICE) {
      const selectedShipping =
        selectedShippingMethodIndex !== null && get(shippingMethod, `[${selectedShippingMethodIndex}]`);

      const isCODPayment = Boolean(get(selectedShipping, "isCODPayment"));
      const selectedShippingMethod = get(selectedShipping, "method") || SHIPPING_METHOD.DIGITAL;
      const offlineStoreId = get(selectedShipping, "offlineStore.id");

      try {
        setIsSubmitForm(true);
        await httpClientService.addLegacyOrder(
          projectId,
          selectedShippingMethod,
          addressData,
          isCODPayment,
          deliverySchedule, // from state
          offlineStoreId,
        );

        /**
         * We need to delay after already done of a new order and delay for process message between
         * chat webview -> rest api -> sme -> publish message (pub/sub) -> message-consumer -> process data -> publish to provider (FACEBOOK, INSTAGRAM, LINE etc.)
         **/
        await sleep(3000);

        // Remove loading
        setIsSubmitForm(false);
        // Disable back button to force customer to close window by themselves if system cannot do it
        setIsBackButtonEnabled(false);

        await httpClientService.closeWindow();
      } catch (err) {
        const errStatus = get(err, "response.status");
        toast.error(t(errStatus || t("PLEASE_TRY_AGAIN")), {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      const selectedShipping =
        selectedShippingMethodIndex !== null &&
        shippingMethod.find(shipping => shipping.shippingId === selectedShippingMethodIndex);
      const offlineStoreId = get(selectedShipping, "offlineStoreId");
      const shippingId = get(selectedShipping, "shippingId");

      try {
        setIsSubmitForm(true);
        await httpClientService.addOrder(
          projectId,
          addressData,
          deliverySchedule, // from state
          offlineStoreId,
          shippingId,
        );

        /**
         * We need to delay after already done of a new order and delay for process message between
         * chat webview -> rest api -> sme -> publish message (pub/sub) -> message-consumer -> process data -> publish to provider (FACEBOOK, INSTAGRAM, LINE etc.)
         **/
        await sleep(3000);

        // Remove loading
        setIsSubmitForm(false);
        // Disable back button to force customer to close window by themselves if system cannot do it
        setIsBackButtonEnabled(false);

        await httpClientService.closeWindow();
      } catch (err) {
        const errStatus = get(err, "response.status");
        toast.error(t(errStatus || t("PLEASE_TRY_AGAIN")), {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };
  // #endregion

  useEffect(() => {
    getProjectConfiguration();
    getStoreDetail();
    getCart();
    setAddressData(customInit);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("SHIPPING_INFORMATION")}</title>
      </Helmet>
      <ToastContainer />

      {isReadyToCheckout && isScheduleEnabled !== null ? (
        <NewFormAddress
          t={t}
          projectId={projectId}
          isDisabled={isDisabled}
          hasSKUProductSKUs={hasSKUProductSKUs}
          hasDigitalProductSKUs={hasDigitalProductSKUs}
          isFilled={isFilled}
          submitAddress={submitAddress}
          submitShippingMethod={submitShippingMethodAndAddress}
          addressData={addressData}
          shippingMethod={shippingMethod}
          selectedShippingMethodIndex={selectedShippingMethodIndex}
          setSelectedShippingMethodIndex={setSelectedShippingMethodIndex}
          cartData={cartData}
          storeDetail={storeDetail}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isSubmitForm={isSubmitForm}
          isLoadingShippingMethod={isLoadingShippingMethod}
          deliverySchedule={deliverySchedule}
          setDeliverySchedule={setDeliverySchedule}
          isValidSchedule={isValidSchedule}
          setIsValidSchedule={setIsValidSchedule}
          isScheduleEnabled={isScheduleEnabled}
          isEmailRequired={isEmailRequired}
          isBackButtonEnabled={isBackButtonEnabled}
        />
      ) : (
        <>
          {Object.values(ERROR_MESSAGE).includes(checkoutErrorMessage) && (
            <ErrorPage errorCode={httpStatus.NOT_FOUND} />
          )}
        </>
      )}
    </>
  );
};

export default withRouter(
  withQueryProjectId(
    withValidateCheckoutCart(withQueryShippingAddress(withTranslation(["ADDRESS_FORM"])(AddressForm))),
  ),
);
