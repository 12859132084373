import React from 'react'

import { LOCAL_STORAGE } from '../../../constant'
import httpClient from '../../../services/http-client'

class PaymentSuccess extends React.Component {
  componentDidMount() {
    const href = window.localStorage.getItem(LOCAL_STORAGE.HREF)
    if (href) {
      window.localStorage.removeItem(LOCAL_STORAGE.HREF)
      window.location.href = href
    }
  }

  render() {
    const client = new httpClient()
    client.closeWindow()
    return <div/>
  }
}

export default PaymentSuccess
