const ORDER_DETAIL = {
  en: {
    DISCOUNT: "Discount",
    ITEMS: "Item(s)",
    ORDER_NUMBER: "Order No.",
    ORDER: "Order",
    PRICE: "Price",
    PROMOTION: "Promotion",
    SHIPPING_DATE: "Shipping date",
    SHIPPING_DISCOUNT: "Shipping discount",
    SHIPPING: "Shipping",
    TOTAL_PRICE: "Total price",
    TRACKING_NUMBER: "Tracking number",
    VAT: "Vat",
  },
  th: {
    DISCOUNT: "ส่วนลด",
    ITEMS: "รายการ",
    ORDER_NUMBER: "หมายเลขรายการสั่งซื้อ",
    ORDER: "รายการสั่งซื้อ",
    PRICE: " ราคา",
    PROMOTION: "โปรโมชั่น",
    SHIPPING_DATE: "วันที่จัดส่ง",
    SHIPPING_DISCOUNT: "ส่วนลดค่าจัดส่ง",
    SHIPPING: "การจัดส่ง",
    TOTAL_PRICE: "ราคารวม",
    TRACKING_NUMBER: "หมายเลขการจัดส่ง",
    VAT: "ภาษี",
  },
};

export default ORDER_DETAIL;
