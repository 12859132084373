import React from "react";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { ButtonBase, Grid, Typography } from "@material-ui/core";
import { NO_IMAGE_AVAL_SRC } from "../../../constant";
import { PRODUCT } from "../../../constants";

import {
  formatMoney,
  getProductTypeListText,
  getProductOptionList,
  getProductOptionPrice,
  getDigitalContentAvailabilityText,
} from "../../../utils/product/index";

import { ButtonStyle, ButtonDeleteStyle, ProductImageStyle, SvgIcDelete } from "./MyCart.styled";

import { AmountPrice, AmountPriceBefore } from "../orderDetail/OrderDetail.styled";

const ProductListItem = props => {
  const {
    amount,
    img,
    isFree,
    max,
    name,
    note,
    options,
    price,
    variableTypes,
    promotions,
    type,
    digitalContentOptions,
  } = props;

  const { t } = useTranslation(["COMMON"]);

  const handleReduceClick = () => {
    const { onAmountChange } = props;
    onAmountChange("reduce");
  };

  const handleAddClick = () => {
    const { onAmountChange } = props;
    onAmountChange("add");
  };

  const handleDeleteClick = () => {
    const { onDelete } = props;
    onDelete();
  };

  const totalDiscount = Number(promotions && promotions.reduce((prev, cur) => prev + cur.totalDiscount, 0));

  const displayDiscountPrice = (priceValue, amountValue, discountValue, isBeforePrice = false) => {
    const discountPrice = Number(priceValue) * Number(amountValue) - Number(discountValue);

    if (isBeforePrice) {
      return formatMoney(discountPrice < 0 ? Number(price) * Number(amountValue) : discountPrice);
    }
    return formatMoney(discountPrice < 0 ? 0 : discountPrice);
  };

  let digitalContentAvailabilityText = "";

  if (type === PRODUCT.TYPE.DIGITAL_CONTENT && digitalContentOptions) {
    digitalContentAvailabilityText = () => getDigitalContentAvailabilityText(digitalContentOptions);
  }

  let productPrice = price;

  if (type === PRODUCT.TYPE.SINGLE_SKU_WITH_OPTIONS) {
    productPrice += getProductOptionPrice(options);
  }

  return (
    <Grid container item alignItems="center" style={{ marginTop: 4 }}>
      <Grid item xs={7}>
        <Grid container item alignItems="center">
          <Grid item container justify="center" xs={5}>
            <img src={img ? img : NO_IMAGE_AVAL_SRC} alt="" style={ProductImageStyle} />
          </Grid>
          <Grid item xs={7} className="pl-1">
            <Grid container direction="column">
              <Typography variant="caption" style={{ fontWeight: "bold" }} className="ellipsis">
                {name}
              </Typography>
              <Typography variant="caption" className="ellipsis">
                {type === PRODUCT.TYPE.SINGLE_SKU_WITH_OPTIONS
                  ? getProductOptionList(options)
                  : getProductTypeListText(variableTypes)}
              </Typography>
              {type === PRODUCT.TYPE.SINGLE_SKU_WITH_OPTIONS && note && (
                <Typography variant="caption" className="ellipsis">
                  * {note}
                </Typography>
              )}
              {digitalContentAvailabilityText && (
                <Typography variant="caption" className="ellipsis">
                  {digitalContentAvailabilityText}
                </Typography>
              )}
              {promotions &&
                promotions.map(promotion => {
                  const promotionName = get(promotion, "name");
                  const promotionDescription = get(promotion, "description");

                  return (
                    <Typography key={promotion.id} variant="caption" color="error" className="ellipsis">
                      {promotionDescription || promotionName}
                    </Typography>
                  );
                })}
            </Grid>
            <Grid container direction="row">
              <Grid item>
                <Typography variant="caption" color="primary" style={{ fontWeight: "bold" }}>
                  {Boolean(totalDiscount > 0) ? (
                    <Grid container justify="space-between" alignContent="center" direction="column">
                      <AmountPriceBefore className="mr-2">
                        ฿{displayDiscountPrice(productPrice, amount, 0, true)}
                      </AmountPriceBefore>
                      <AmountPrice className="mr-2">
                        ฿{displayDiscountPrice(productPrice, amount, totalDiscount)}
                      </AmountPrice>
                    </Grid>
                  ) : (
                    <AmountPrice className="mr-2">
                      {!isFree ? `฿${displayDiscountPrice(productPrice, amount, 0)}` : t("FREE")}
                    </AmountPrice>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container item justify="flex-end">
          {!isFree ? (
            <Grid container justify="flex-end" alignItems="center">
              <Grid item>
                <ButtonBase disabled={amount === 1} style={ButtonStyle} onClick={handleReduceClick}>
                  <RemoveIcon fontSize="small" />
                </ButtonBase>
              </Grid>
              <Grid item>
                <span style={{ margin: "8px" }}>{amount}</span>
              </Grid>
              <Grid item>
                <ButtonBase disabled={amount === max} style={ButtonStyle} onClick={handleAddClick}>
                  <AddIcon fontSize="small" />
                </ButtonBase>
              </Grid>
              <Grid item>
                <ButtonBase style={ButtonDeleteStyle} onClick={handleDeleteClick}>
                  <SvgIcDelete fontSize="small" />
                </ButtonBase>
              </Grid>
            </Grid>
          ) : (
            <Grid container justify="flex-end" alignItems="center">
              <Grid item>
                <span style={{ paddingRight: "90px" }}>{amount}</span>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductListItem;
