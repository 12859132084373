import React from "react";
import { COLORS } from "../../../../constants/index";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#737d94",
  },
  circle: {
    marginLeft: 4,
    width: 16,
    height: 16,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#737d94",
    zIndex: 1,
    fontSize: 18,
  },
});

export function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

export const buttonInlineStyle = {
  borderRadius: "10px",
  height: "42px",
  fontWeight: 500,
  margin: "20px 0",
  width: "100%",
};

export const stepLabelInlineStyle = {
  backgroundColor: COLORS.Light,
  marginLeft: -22,
  paddingLeft: 22,
  marginRight: -22,
  borderBottom: `solid 0.5px ${COLORS.LightBlue}`,
  borderTop: `solid 0.5px ${COLORS.LightBlue}`,
};
