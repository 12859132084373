import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { ShoppingCartCalculator } from "@deeple-ai/shopping-cart-calculator";

import { DescriptionText, TitleSectionText } from "./ProductDetail.styled";
import { get } from "lodash";

import { NO_IMAGE_AVAL_SRC } from "../../../constant";
import { formatMoney } from "../../../utils/product";
import { getRelatedPromotionForProductHeaderBadge } from "../../../utils/promotion";

import { SelectFoodProduct } from "./SelectFoodProduct";
import {
  AmountPriceAfter,
  AmountPriceBefore,
  ProductFoodImageContainer,
  ProductPrice,
  ProductTitle,
  PromotionBadge,
} from "./ProductDetail.styled";

export const ProductDetailFoodPresentation = ({
  productDetail,
  onChangeAmount,
  onClickAddToCart,
  amount,
  promotions,
  productSKUOptions,
  onSelectOption,
  onChangeNote,
  optionErrorIndexes,
}) => {
  const { t } = useTranslation(["PRODUCT_DETAIL"]);
  const foodProductSKU = productDetail.productSKUs[0];
  const productPrice = foodProductSKU.price;

  const shoppingCartCalculator = new ShoppingCartCalculator([
    {
      id: foodProductSKU.id,
      price: Number(productPrice),
      amount: Number(amount),
      stock: Number(amount),
      options: productSKUOptions,
    },
  ]);

  const totalData = shoppingCartCalculator
    .setBulkPromotionWithPromotionProperties(promotions)
    .calculate();
  const grandTotalPrice = totalData ? totalData.grandTotal : 0;

  const shoppingCartCalculatorForDisplayDiscount = new ShoppingCartCalculator([
    {
      id: foodProductSKU.id,
      price: Number(productPrice),
      amount: 1,
      stock: 1,
    },
  ]);
  const totalDataPerItem = shoppingCartCalculatorForDisplayDiscount
    .setBulkPromotionWithPromotionProperties(promotions)
    .calculate();

  const selectedDiscountProductPrice = totalDataPerItem.grandTotal;
  const productImage = get(productDetail, "images[0].src");
  const promotionBadge = getRelatedPromotionForProductHeaderBadge(promotions);
  const promotionBadgeText =
    promotionBadge && promotionBadge.shortName
      ? promotionBadge.shortName
      : undefined;

  return (
    <Grid container className="px-3 pb-4">
      <Grid container item>
        <ProductFoodImageContainer src={productImage || NO_IMAGE_AVAL_SRC} />
        {promotionBadgeText && (
          <PromotionBadge>
            <div>{promotionBadgeText}</div>
          </PromotionBadge>
        )}
      </Grid>

      <Grid container direction="column" className="py-2">
        <Grid item>
          <ProductTitle className="py-1">{productDetail.name}</ProductTitle>
        </Grid>
        <Grid item>
          {selectedDiscountProductPrice < productPrice ? (
            <Grid container justify="flex-start">
              <AmountPriceBefore className="py-1">
                ฿{formatMoney(productPrice)}
              </AmountPriceBefore>
              <AmountPriceAfter className="pl-2 py-1">
                ฿{formatMoney(selectedDiscountProductPrice)}
              </AmountPriceAfter>
            </Grid>
          ) : (
            <ProductPrice className="py-1">
              ฿{formatMoney(productPrice)}
            </ProductPrice>
          )}
        </Grid>
      </Grid>

      {productDetail && productDetail.description && (
        <Grid container item direction="row" xs={12}>
          <Grid item xs={12}>
            <TitleSectionText className="pb-2">
              {t("PRODUCT_DETAIL")}
            </TitleSectionText>
            <Grid item xs={12} className="pb-2">
              <DescriptionText>{productDetail.description}</DescriptionText>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid container style={{ marginBottom: "60px" }}>
        <SelectFoodProduct
          onChangeAmount={onChangeAmount}
          amount={amount}
          onClickAddToCart={onClickAddToCart}
          promotions={promotions}
          onSelectOption={onSelectOption}
          productSKUOptions={productSKUOptions}
          onChangeNote={onChangeNote}
          grandTotalPrice={grandTotalPrice}
          optionErrorIndexes={optionErrorIndexes}
        />
      </Grid>
    </Grid>
  );
};
