import get from "lodash/get";

import config from "../../../../../config"
import { SHIPPING_METHOD } from "../../../../../constants/index";

const { IS_USING_NEW_SHIPPING_SERVICE } = config

const getLegacyPriceAndShippingDurationText = (t, shipping) => {
  const disabledDuration = get(shipping, "disabledDuration", false);

  const isDisabled = get(shipping, "disabled", false);
  const isCODPayment = get(shipping, "isCODPayment", false);

  if (isDisabled) {
    return `${isCODPayment ? "" : t("SHIPPING_IS_NOT_AVAILABLE")} ${t(get(shipping, "disabledReason"))}`;
  }

  const displayTotalShippingPrice = Number(shipping.totalShippingPrice)
    ? `฿${shipping.totalShippingPrice}`
    : t("FREE");
  const displayMinDuration = shipping.maxDuration > shipping.minDuration > 0 ? shipping.minDuration + " - " : "";
  switch (shipping.calculateType) {
    case SHIPPING_METHOD.SHIPPING_CALCULATE_TYPE.FIXED_PRICE:
      if (disabledDuration) {
        return displayTotalShippingPrice;
      }
      return `(${displayMinDuration}${shipping.maxDuration} ${t("DAY")}) ${displayTotalShippingPrice} `;

    case SHIPPING_METHOD.SHIPPING_CALCULATE_TYPE.POSTAL_CODE_BASED_PRICE:
      if (disabledDuration) {
        return `(${t("POSTAL_CODE_BASED_PRICE")}) ${displayTotalShippingPrice}`;
      }
      return `(${t("POSTAL_CODE_BASED_PRICE")}) (${displayMinDuration}${shipping.maxDuration} ${t(
        "DAY",
      )}) ${displayTotalShippingPrice}`;

    case SHIPPING_METHOD.SHIPPING_CALCULATE_TYPE.DISTANCE_BASE_PRICE:
      if (disabledDuration) {
        return `(${t("DISTANCE_BASE_PRICE")})`;
      }
      return `(${t("DISTANCE_BASE_PRICE")}) (${displayMinDuration}${shipping.maxDuration} ${t("DAY")})`;

    case SHIPPING_METHOD.SHIPPING_CALCULATE_TYPE.SHIPPOP:
      if (disabledDuration) {
        return `${displayTotalShippingPrice}`;
      }
      return `(${displayMinDuration}${shipping.maxDuration} ${t("DAY")}) ${displayTotalShippingPrice}`;

    default:
      if (disabledDuration) {
        return `${displayTotalShippingPrice}`;
      }
      return `(${displayMinDuration}${shipping.maxDuration} ${t("DAY")}) ${displayTotalShippingPrice}`;
  }
}

export const getPriceAndShippingDurationText = t => shipping => {
  if (!IS_USING_NEW_SHIPPING_SERVICE) {
    return getLegacyPriceAndShippingDurationText(t, shipping)
  }

  const isActiveDuration = get(shipping, "duration.isActive") || false;
  const isDisabled = get(shipping, "isDisabled") || false;
  const isCod = get(shipping, "isCod") || false;
  const maxDuration = get(shipping, "duration.max");
  const minDuration = get(shipping, "duration.min");

  if (isDisabled) {
    return `${isCod ? "" : t("SHIPPING_IS_NOT_AVAILABLE")} ${t(get(shipping, "disabledReason"))}`;
  }

  const displayTotalShippingPrice = Number(shipping.price)
    ? `฿${shipping.price}`
    : t("FREE");
  const displayMinDuration = maxDuration > minDuration > 0 ? minDuration + " - " : "";
  switch (shipping.type) {
    case SHIPPING_METHOD.SHIPPING_TYPE.FIXED_PRICE:
      if (!isActiveDuration) {
        return displayTotalShippingPrice;
      }

      return `(${displayMinDuration}${maxDuration} ${t("DAY")}) ${displayTotalShippingPrice} `;
    case SHIPPING_METHOD.SHIPPING_TYPE.POSTAL_CODE:
      if (!isActiveDuration) {
        return `(${t("POSTAL_CODE_BASED_PRICE")}) ${displayTotalShippingPrice}`;
      }

      return `(${t("POSTAL_CODE_BASED_PRICE")}) (${displayMinDuration}${maxDuration} ${t(
        "DAY",
      )}) ${displayTotalShippingPrice}`;
    case SHIPPING_METHOD.SHIPPING_TYPE.MANUAL:
      if (!isActiveDuration) {
        return `(${t("DISTANCE_BASE_PRICE")})`;
      }

      return `(${t("DISTANCE_BASE_PRICE")}) (${displayMinDuration}${maxDuration} ${t("DAY")})`;
    case SHIPPING_METHOD.SHIPPING_TYPE.SHIPPOP:
      if (!isActiveDuration) {
        return `${displayTotalShippingPrice}`;
      }

      return `(${displayMinDuration}${maxDuration} ${t("DAY")}) ${displayTotalShippingPrice}`;
    default:
      if (!isActiveDuration) {
        return `${displayTotalShippingPrice}`;
      }

      return `(${displayMinDuration}${maxDuration} ${t("DAY")}) ${displayTotalShippingPrice}`;
  }
};
