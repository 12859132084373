import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { get, truncate } from "lodash";
import { Grid } from "@material-ui/core";
import { NO_IMAGE_AVAL_SRC } from "../../../constant";
import { findSKUFromProductType, formatMoney, getDigitalContentAvailabilityText } from "../../../utils/product";
import PromotionDetail from "./PromotionDetail";
import { ShoppingCartCalculator } from "@deeple-ai/shopping-cart-calculator";
import { PRODUCT, SHIPPING_METHOD } from "../../../constants/index";
import { TYPE } from "../../../constants/product";
import {
  AddToCartButton,
  AmountText,
  ButtonCircle,
  Footer,
  ProductPrice,
  SectionDivider,
  SelectedProductButton,
  ShippingConditionDescriptionText,
  ShippingDescription,
  ShippingMethodText,
  SKUTextLabel,
  SKUTextLabelWarning,
  TitleSectionText,
  TotalPriceCaptionText,
  TotalPriceText,
} from "./ProductDetail.styled";

export const SelectProduct = ({
  product,
  skuForRender,
  selectedSKU,
  onChangeSKU,
  onChangeAmount,
  onClickAddToCart,
  amount,
  promotions,
  setPresentProductImage,
  setSelectedProductName,
  setSelectedProductPrice,
  setSelectedDiscountProductPrice,
  productSKUs,
}) => {
  const { t } = useTranslation(["PRODUCT_DETAIL"]);
  const selectSKUText = (() => {
    return Object.keys(selectedSKU)
      .map(e => selectedSKU[e])
      .join(", ");
  })();
  const selectedSKUDetail = findSKUFromProductType({ product, selectedSKU });

  const selectedSKUId = selectedSKUDetail && selectedSKUDetail.id;
  const selectProductSKUData = productSKUs.find(SKU => SKU.id === selectedSKUId);
  const digitalContentOptions = selectProductSKUData && selectProductSKUData.digitalContentOptions;

  const availableStockAmount = selectedSKUDetail.inventory - selectedSKUDetail.reserved;
  const stockLimit =
    availableStockAmount && availableStockAmount < PRODUCT.QUANTITY_LIMIT
      ? availableStockAmount
      : PRODUCT.QUANTITY_LIMIT;
  const skuImageURL = (() => {
    if (product.isCustomSKU === false) {
      return get(product, "images.0.src");
    } else if (!selectedSKUDetail.image) {
      return get(product, "images.0.src") || NO_IMAGE_AVAL_SRC;
    } else {
      return selectedSKUDetail.image || NO_IMAGE_AVAL_SRC;
    }
  })();

  useEffect(() => {
    setPresentProductImage(skuImageURL);
    setSelectedProductName(`${product.name} ${selectSKUText ? selectSKUText : ""}`);
    setSelectedProductPrice(selectedSKUDetail.price);
  }, [skuImageURL, selectSKUText, selectedSKUDetail]);

  let totalData = null;
  let totalDataPerItem = null;
  let grandTotalPrice = 0;

  if (stockLimit > 0) {
    const shoppingCartCalculator = new ShoppingCartCalculator([
      {
        id: selectedSKUDetail.id,
        price: Number(selectedSKUDetail.price),
        amount: Number(amount),
        stock: Number(stockLimit),
      },
    ]);

    totalData = shoppingCartCalculator.setBulkPromotionWithPromotionProperties(promotions).calculate();

    grandTotalPrice = totalData.grandTotal;

    const shoppingCartCalculatorForDisplayDiscount = new ShoppingCartCalculator([
      {
        id: selectedSKUDetail.id,
        price: Number(selectedSKUDetail.price),
        amount: 1,
        stock: 1,
      },
    ]);

    totalDataPerItem = shoppingCartCalculatorForDisplayDiscount
      .setBulkPromotionWithPromotionProperties(promotions)
      .calculate();
    setSelectedDiscountProductPrice(totalDataPerItem.grandTotal);
  }

  const getPriceAndShippingDuration = shipping => {
    switch (shipping.calculateType) {
      case SHIPPING_METHOD.SHIPPING_CALCULATE_TYPE.FIXED_PRICE:
        const disabledDuration = get(shipping, "disabledDuration", false);
        if (disabledDuration) {
          return `฿${shipping.fixedPrice}`;
        }

        return `฿${shipping.fixedPrice} (${
          shipping.maxDuration > shipping.minDuration ? shipping.minDuration + " - " : ""
        }${shipping.maxDuration} ${t("DAY")})`;
      case SHIPPING_METHOD.SHIPPING_CALCULATE_TYPE.POSTAL_CODE_BASED_PRICE:
        return `(${t("POSTAL_CODE_BASED_PRICE")})`;
      case SHIPPING_METHOD.SHIPPING_CALCULATE_TYPE.DISTANCE_BASE_PRICE:
        return `(${t("DISTANCE_BASE_PRICE")})`;
      default:
        return "";
    }
  };

  const digitalContentAvailabilityText = getDigitalContentAvailabilityText(digitalContentOptions);

  return (
    <>
      <Grid container>
        {Object.keys(skuForRender).map((key, i) => {
          return (
            <Grid container item xs={12} key={i}>
              <Grid item xs={12}>
                <SKUTextLabel>{key} : </SKUTextLabel>
              </Grid>
              <Grid item xs={12}>
                {skuForRender[key].map((value, j) => {
                  const isSelected = selectedSKU[key] === value;
                  return (
                    <SelectedProductButton
                      key={j}
                      className="mr-2 my-2"
                      isSelected={isSelected}
                      variant="outlined"
                      onClick={() => onChangeSKU(key, value)}
                    >
                      {value}
                    </SelectedProductButton>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}

        <Grid item xs={12}>
          <SKUTextLabel>{t("AMOUNT")}</SKUTextLabel>
        </Grid>

        <Grid item xs={12}>
          <Grid container alignContent="center" alignItems="center">
            <Grid item>
              <Grid container alignContent="center" alignItems="center">
                <ButtonCircle
                  onClick={() => onChangeAmount(-1, stockLimit)}
                  disabled={amount === 1 || availableStockAmount < 1}
                >
                  -
                </ButtonCircle>
                <AmountText className="px-2">{amount}</AmountText>
                <ButtonCircle
                  onClick={() => onChangeAmount(1, stockLimit)}
                  disabled={amount === stockLimit || availableStockAmount < 1}
                >
                  +
                </ButtonCircle>
              </Grid>
            </Grid>
            <Grid item className="pl-2">
              <Grid container alignContent="center" alignItems="center">
                {availableStockAmount < 1 ? (
                  <SKUTextLabelWarning>{t("TEMPORARY_OUT_OF_STOCK")}</SKUTextLabelWarning>
                ) : (
                  availableStockAmount < 10 && (
                    <SKUTextLabelWarning>
                      {t("REMAINING_STOCK")} {availableStockAmount} {t("ITEMS")}
                    </SKUTextLabelWarning>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SectionDivider />

      {digitalContentAvailabilityText && (
        <>
          {digitalContentAvailabilityText}
          <SectionDivider />
        </>
      )}

      {Boolean(promotions.length) && (
        <Grid container direction="column">
          <TitleSectionText>{t("PROMOTION")}</TitleSectionText>
          {promotions.map(promotion => {
            return (
              <PromotionDetail key={promotion.id} promotion={promotion} selectedSKUId={selectedSKUId}></PromotionDetail>
            );
          })}
          <SectionDivider />
        </Grid>
      )}
      {product.type !== TYPE.DIGITAL_CONTENT && (
        <>
          <Grid container direction="column">
            <TitleSectionText className="mb-2">
              {t("SELECT_SHIPPING_METHOD")}
              <ShippingConditionDescriptionText>({t("SELECT_AFTER_PAYMENT")})</ShippingConditionDescriptionText>
            </TitleSectionText>
            {product.shippingMethod.map(method => {
              const shippingMethodName = `${truncate(method.description, {
                length: 17,
              })}`;
              const priceAndShippingDuration = getPriceAndShippingDuration(method);

              return (
                <>
                  {method.method && (
                    <ShippingDescription className="py-1">
                      <ShippingMethodText>{method.method} : </ShippingMethodText>
                      {`${shippingMethodName} ${priceAndShippingDuration}`}
                    </ShippingDescription>
                  )}
                </>
              );
            })}
          </Grid>
          <SectionDivider />
        </>
      )}
      <Footer>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Grid container>
              <Grid item>
                <TotalPriceText className="pl-3 pr-1">{t("TOTAL_PRICE")}</TotalPriceText>
              </Grid>
              <Grid item>
                <ProductPrice>฿{formatMoney(grandTotalPrice)}</ProductPrice>
              </Grid>
            </Grid>
            <Grid item className="pl-3 pb-1">
              <Grid container direction="column">
                <Grid item>
                  <TotalPriceCaptionText variant="caption">
                    {Boolean(promotions.length) && `*${t("TOTAL_PRICE_INCLUDE_PROMOTIONS")}`}
                  </TotalPriceCaptionText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <AddToCartButton
              className="my-3 mx-2 pr-4"
              disabled={amount < 1 || availableStockAmount < 1}
              onClick={onClickAddToCart}
              variant="contained"
              color="primary"
            >
              {t("ADD_TO_CART")}
            </AddToCartButton>
          </Grid>
        </Grid>
      </Footer>
    </>
  );
};
