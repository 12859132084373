import flatMap from "lodash/flatMap";
import get from "lodash/get";
import moment from "moment";
import orderBy from "lodash/orderBy";

import { NO_IMAGE_AVAL_SRC, PROMOTION } from "../../constant";

import { useTranslation } from "react-i18next";
import { DATE_TIME } from "../../constants/index";
import { DigitalContentAvailability } from "../../constants/product";

const DEFAULT_SORT_FOR_VARIABLE_TYPES = ["type", "asc"];

/**
 * Get backward compatible variable types (variable types or product type)
 * @param {object} productSKU
 * @returns {ReadonlyArray<Record<string, string>>}
 */
export function getBackwardCompatibleVariableTypes(productSKU) {
  if (productSKU.variableTypes && productSKU.variableTypes.length) {
    return orderBy(productSKU.variableTypes, DEFAULT_SORT_FOR_VARIABLE_TYPES);
  }

  // Converting deprecated to new structure
  if (productSKU.productType) {
    const productTypes = productSKU.productType;

    if (!Array.isArray(productTypes)) {
      return [{ key: get(productTypes, "key.key"), value: get(productTypes, "value") }];
    }

    const variableTypes = productTypes.map(productType => ({
      key: get(productType, "key.key"),
      value: get(productType, "value"),
    }));

    return orderBy(variableTypes, DEFAULT_SORT_FOR_VARIABLE_TYPES);
  }

  return [];
}

/**
 * Get variable types from product sku
 * @param {object} productSKU
 * @returns {Record<string, string>}
 */
export function getVariableTypes(productSKU) {
  if (productSKU.variableTypes && productSKU.variableTypes.length) {
    const { variableTypes } = productSKU;

    return variableTypes.reduce(
      (accumulatedVariableTypes, currentVariableType) => ({
        ...accumulatedVariableTypes,
        [currentVariableType.key]: currentVariableType.value,
      }),
      {},
    );
  }

  // Backward compatible variable type
  const productTypes = productSKU.productType;

  return productTypes.reduce(
    (accumulatedProductType, currentProductType) => ({
      ...accumulatedProductType,
      [currentProductType.key.key]: currentProductType.value,
    }),
    {},
  );
}

/**
 * Find sku from product detail
 * @param {object} queryParam - query object
 * @param {object} queryParam.product - product object
 * @param {Record<string, string>} queryParam.selectedSKU - selectedSKU object
 * @returns {object} return selected sku
 */
export const findSKUFromProductType = ({ product, selectedSKU: selectedSKUVariableType }) => {
  return product.productSKUs.find(productSKU => {
    const variableTypes = getBackwardCompatibleVariableTypes(productSKU);
    const keyValueSKUVariableTypes = variableTypes.reduce(
      (accumulatedKeyValueVariableTypes, currentVariableType) => ({
        ...accumulatedKeyValueVariableTypes,
        [currentVariableType.key]: currentVariableType.value,
      }),
      {},
    );

    return (
      Object.keys(selectedSKUVariableType).length === Object.keys(keyValueSKUVariableTypes).length &&
      Object.keys(selectedSKUVariableType).reduce(
        (accumulatedMatched, currentVariableType) =>
          accumulatedMatched &&
          selectedSKUVariableType[currentVariableType] === keyValueSKUVariableTypes[currentVariableType],
        true,
      )
    );
  });
};

/**
 * Generate money currency text
 * @param {number} amount - input number for format
 * @param {number} [decimalCount] - decimal count
 * @param {string} [decimal] - decimal separate eg. "."
 * @param {string} [thousands] - thousands eg. ","
 * @returns {string} return format of amount
 */
export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.error(e);
  }
};

/**
 * group promotion by product SKU id
 * @param {[object]} promotions
 *
 * ex, promotions = [{id: 1, selectedProducts: [{id: 40}]}, {id: 2, selectedProducts: [{id: 43}]}]
 *     result = [
  {
    "40": [{ // product SKU id
      "id": 1, // promotion id
      "selectedProducts": [{
          "id": 40
        }]
    }]
  },
  {
    "43": [{
      "id": 2,
      "selectedProducts": [{
          "id": 43
        }]
    }]
  }
]
*/
export const groupPromotionsByProductSKUId = (promotions = []) => {
  const groupedPromotions = {};

  promotions.forEach(promotion => {
    const selectedProducts = promotion.selectedProducts || [];

    selectedProducts.forEach(selectedProductSKU => {
      const selectedProductSKUId = selectedProductSKU.id;

      if (!groupedPromotions[selectedProductSKUId]) {
        groupedPromotions[selectedProductSKUId] = [];
      }
      // don't add duplicated promotion that already added
      const findDuplicatedPromotion = groupedPromotions[selectedProductSKUId].find(
        addedPromotion => addedPromotion.id === promotion.id,
      );

      if (!findDuplicatedPromotion) {
        groupedPromotions[selectedProductSKUId].push(promotion);
        // groupedPromotions[selectedProductSKUId].sort(sortPromotionByTypeAndUpdatedAt)
      }
    });
  });

  return groupedPromotions;
};

export const calculateMinAndMaxPrice = product => {
  const priceArray = product.productSKUs.map(sku => sku.price);
  const minPrice = Math.min(...priceArray).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const maxPrice = Math.max(...priceArray).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return { minPrice, maxPrice };
};

export const getProductTypeList = (variableTypes = []) => {
  return variableTypes.map(variableType => variableType.value).join(", ");
};

export const getProductOptionList = (options = []) => {
  return options
    .map(option => {
      const selectedChoices = option.choices
        .filter(({ isSelected }) => isSelected)
        .map(({ name }) => name)
        .join(", ");

      return selectedChoices;
    })
    .filter(Boolean)
    .join(", ");
};

export const getProductOptionPrice = (options = []) => {
  let totalPrice = 0;
  options.map(option => {
    const selectedChoices = option.choices
      .filter(({ isSelected }) => isSelected)
      .map(({ price }) => (totalPrice += price));

    return selectedChoices;
  });

  return totalPrice;
};

export const getProductTypeListText = (variableTypes = []) => {
  return variableTypes.length ? `${variableTypes.map(({ value }) => value).join(", ")}` : "-";
};

export const getPromotionTotalDiscountForProductSKU = (productSKU, promotions = []) => {
  // free product
  if (productSKU.isFree) {
    return 0;
  }

  const totalDiscountForProductSKU = promotions.reduce((prev, currentPromotion) => {
    const { selectedProducts } = currentPromotion;

    const selectedProduct = selectedProducts.find(
      selectedProduct => selectedProduct.id === productSKU.id && selectedProduct.amount === productSKU.amount,
    );

    // free product don't have promotion
    if (!selectedProduct) {
      return prev;
    }

    const relatePromotions = selectedProduct.promotions || [];
    const discount = relatePromotions.reduce((previous, relatePromotion) => {
      return previous + relatePromotion.totalDiscount;
    }, 0);

    return prev + discount;
  }, 0);

  return totalDiscountForProductSKU;
};

/**
 * get related promotion with the productSKUs (BUY_ANY_PRODUCT will alway included)
 * @param {number[]} productSKUIds
 * @param {object[]} promotions
 * @return {object[]]} promotion
 */
export function getRelatedPromotionByProductSKUIds(productSKUIds, promotions = []) {
  const relatedPromotions = promotions.filter(promotion => {
    const {
      settings: { condition },
    } = promotion;
    // BUY_ANY_PRODUCT and BUY_X_PIECE_OF_ANY_PRODUCT and BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT will alway included

    if (
      [
        PROMOTION.SETTING.CONDITION.BUY_ANY_PRODUCT,
        PROMOTION.SETTING.CONDITION.BUY_X_PIECE_OF_ANY_PRODUCT,
        PROMOTION.SETTING.CONDITION.BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT,
      ].includes(condition)
    ) {
      return true;
    }

    const selectedProducts = promotion.selectedProducts || [];

    // this promotion apply all product
    if (!selectedProducts.length) {
      return true;
    }

    const isRelatedPromotion = selectedProducts.find(selectedProduct =>
      productSKUIds.find(productSKUId => selectedProduct.id === productSKUId),
    );

    return isRelatedPromotion;
  });

  return relatedPromotions;
}

/**
 * get related promotion with the products
 * @param {object[]} products
 * @param {object[]} promotions
 * @return {object[]} promotion
 */
export function getRelatedPromotionByProducts(products, promotions) {
  const productSKUIds = flatMap(products, product => {
    const productSKUs = product.productSKUs || [];

    return productSKUs.map(productSKU => productSKU.id);
  });

  return getRelatedPromotionByProductSKUIds(productSKUIds, promotions);
}

export function compareCampaignCreateAt(promotionA, promotionB) {
  const campaignPromotionA = get(promotionA, "campaign");
  const campaignPromotionB = get(promotionB, "campaign");
  if (!campaignPromotionA || !campaignPromotionB || campaignPromotionA.id === campaignPromotionB.id) {
    return 0;
  }
  const campaignCreatedAtPromotionA = moment(get(campaignPromotionA, "createdAt"));
  const campaignCreatedAtPromotionB = moment(get(campaignPromotionB, "createdAt"));
  const campaignCreateAtPriority = campaignCreatedAtPromotionA < campaignCreatedAtPromotionB ? 1 : -1;

  return campaignCreateAtPriority;
}

export function comparePromotionPriority(promotionA, promotionB) {
  const priorityPromotionA = get(promotionA, "priority");
  const priorityPromotionB = get(promotionB, "priority");
  const promotionPriority = priorityPromotionA < priorityPromotionB ? -1 : 1;

  return promotionPriority;
}
/**
 * sort promotions by priority
 *
 * campaign created date > promotion priority
 *
 * @param {object<Promotion>} promotionA
 * @param {object<Promotion>} promotionB
 */
export function sortPromotionByPriority(promotionA, promotionB) {
  // chain compare function with ||
  return compareCampaignCreateAt(promotionA, promotionB) || comparePromotionPriority(promotionA, promotionB);
}

/**
 * Sort selected variable types by key
 * @param {Record<string, string>} variableTypeObject { size: 'xl', color: 'black' }
 * @returns {Record<string, string>} { color: 'black', size: 'xl' }
 */
export function sortVariableTypesByKey(variableTypeObject) {
  const sortedVariableTypeObject = {};
  const sortedVariableTypeKeys = Object.keys(variableTypeObject).sort();

  sortedVariableTypeKeys.forEach(variableTypeKey => {
    sortedVariableTypeObject[variableTypeKey] = variableTypeObject[variableTypeKey];
  });

  return sortedVariableTypeObject;
}

/**
 * Generate render object
 * @param {object} product - query object
 * @param {Array} product.productSKUs - array of sku
 * @param {Array} product.productSKUs.productType - array of sku type eg. {key: { key: 'Color' }, value: 'White'}
 * @param {Record<string, string>} selectedProductSKU - selectedSKU object eg { Color: 'black', Size: 'XL' }
 * @returns {object} return render sku object
 */
export const generateRenderSKUObject = (product, selectedProductSKU) => {
  const SKUIdWithKeyValue = {};
  const newSelectedSKU = sortVariableTypesByKey({ ...selectedProductSKU });

  product.productSKUs.forEach(productSKU => {
    const variableTypes = getBackwardCompatibleVariableTypes(productSKU);
    variableTypes.forEach(variableType => {
      SKUIdWithKeyValue[variableType.key] = [
        ...new Set([...(SKUIdWithKeyValue[variableType.key] || []), variableType.value]),
      ];
    });
  });

  Object.keys(SKUIdWithKeyValue).forEach((keyValue, index) => {
    if (index !== 0) {
      const primaryKey = Object.keys(SKUIdWithKeyValue)[0];
      const primaryValue = selectedProductSKU[primaryKey];

      SKUIdWithKeyValue[keyValue] = SKUIdWithKeyValue[keyValue].filter(value => {
        return product.productSKUs.some(productSKU => {
          const variableTypes = getBackwardCompatibleVariableTypes(productSKU);
          const findPrimary = variableTypes.find(type => type.key === primaryKey && primaryValue === type.value);

          const findSecondary = variableTypes.find(type => type.key === keyValue && value === type.value);

          return findPrimary && findSecondary;
        });
      });
    }
  });

  Object.keys(newSelectedSKU).forEach(type => {
    if (!SKUIdWithKeyValue[type].includes(newSelectedSKU[type])) {
      newSelectedSKU[type] = SKUIdWithKeyValue[type][0];
    }
  });

  return { renderSKU: SKUIdWithKeyValue, selectedSKU: newSelectedSKU };
};

/**
 * Get product image from product sku or 1st image of product or default image
 * @param {object} productSKU
 * @returns {string}
 */
export function getProductImage(productSKU) {
  const imageURL = productSKU.image ? productSKU.image : get(productSKU, "product.images[0].src");

  return imageURL || NO_IMAGE_AVAL_SRC;
}

/**
 * Get normalized variable types by product skus
 * @param {ReadonlyArray<object>} productSKUs
 * @returns {Record<string, string>}
 */
export function normalizeVariableTypesByProductSKUs(productSKUs) {
  const normalizedVariableTypes = productSKUs.reduce((obj, productSKU) => {
    const types = { ...obj };

    const variableTypes = getBackwardCompatibleVariableTypes(productSKU);
    variableTypes.forEach(productType => {
      const { key, value } = productType;
      if (!types[key]) {
        types[key] = [value];
      } else if (types[key] && !types[key].includes(value)) {
        types[key].push(value);
      }
    });

    return types;
  }, {});

  return sortVariableTypesByKey(normalizedVariableTypes);
}

/**
 * Get product sku data
 * @param {object} currentProduct
 * @returns {object}
 */
export function getProductSKUData(currentProduct) {
  const productTypeFromFirstProductSKU = get(currentProduct, "productSKUs.0.productType");
  if (Array.isArray(productTypeFromFirstProductSKU)) {
    return normalizeVariableTypesByProductSKUs(currentProduct.productSKUs);
  }

  const { productSKUs } = currentProduct;

  return productSKUs.reduce((accumulatedProductSKUVariableTypes, currentProductSKU) => {
    const accumulatedVariableTypes = accumulatedProductSKUVariableTypes;

    const variableTypes = getBackwardCompatibleVariableTypes(currentProductSKU);
    variableTypes.forEach(variableType => {
      accumulatedVariableTypes[variableType.key] = [
        ...(accumulatedVariableTypes[variableType.key] || []),
        variableType.value,
      ];
    });

    return accumulatedVariableTypes;
  }, {});
}

/**
 * Check whether product sku has variable types or product type
 * @param {object} productSKU
 * @returns {boolean}
 */
export function hasVariableTypesOrProductType(productSKU) {
  return (
    (Boolean(productSKU.productType) || Boolean(productSKU.variableTypes)) &&
    (productSKU.productType.length > 0 || productSKU.variableTypes.length > 0)
  );
}

export const getDigitalContentAvailabilityText = digitalContentOptionsData => {
  const { t } = useTranslation(["PRODUCT_DETAIL"]);
  if (digitalContentOptionsData) {
    const outputType = get(digitalContentOptionsData, "outputType");
    const settingsType = get(digitalContentOptionsData, "settings.availability.type");
    const availability = get(digitalContentOptionsData, "settings.availability");

    if (outputType) {
      let returnDescriptionText = "";

      switch (settingsType) {
        case DigitalContentAvailability.PERIOD_AFTER_PURCHASED:
          if (get(availability, "settings.value")) {
            returnDescriptionText = `${t("AVAILABLE_AT")} ${get(availability, "settings.value")} ${t("DAYS")} ${t(
              "AFTER_PURCHASE",
            )}`;
          } else {
            returnDescriptionText = "";
          }
          break;
        case DigitalContentAvailability.EXPIRY_DATE:
          if (get(availability, "settings.expiredAt")) {
            returnDescriptionText = `${t("EXPIRED_AT")} ${
              get(availability, "settings.expiredAt")
                ? moment(
                    get(availability, "settings.expiredAt"),
                    DATE_TIME.DATE_DIGITAL_CONTENT_EXPIRED_AT_FORMAT,
                  ).format(DATE_TIME.DATE_DIGITAL_CONTENT_DISPLAY_FORMAT)
                : ""
            }`;
          } else {
            returnDescriptionText = "";
          }
          break;
        case DigitalContentAvailability.DATE_RANGE:
          returnDescriptionText = `${t("AVAILABLE_AT")} ${get(availability, "settings.availableAt")} ${t("TO")} ${t(
            get(availability, "settings.expiredAt"),
          )}`;
          break;
        case DigitalContentAvailability.NO_EXPIRY: // hidden
          return "";
        default:
          return "";
      }

      return returnDescriptionText;
    }
    return "";
  }
};
