import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { SelectProduct } from "./SelectProduct";
import { RenderPropertiesTable } from "./ProductDescriptionAndProperty";
import { NO_IMAGE_AVAL_SRC } from "../../../constant";
import { get } from "lodash";
import { formatMoney, calculateMinAndMaxPrice } from "../../../utils/product";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useTranslation } from "react-i18next";

import {
  AmountPriceAfter,
  AmountPriceBefore,
  BackdropWrapper,
  ButtonIconZoom,
  ProductImageSmallContainer,
  ProductImageSmallScroll,
  ProductMainImageContainer,
  ProductPrice,
  ProductTitle,
  ZoomToolsWrapper,
  SectionDivider,
  TitleSectionText,
  DescriptionText,
} from "./ProductDetail.styled";

export const DigitalContentProductDetailPresentation = ({
  productDetail,
  selectedSKU,
  onChangeSKU,
  onChangeAmount,
  onClickAddToCart,
  amount,
  skuForRender,
  promotions,
  productSKUs,
}) => {
  const { t } = useTranslation(["PRODUCT_DETAIL"]);

  const { minPrice, maxPrice } = calculateMinAndMaxPrice(productDetail);
  const [selectedProductImage, setSelectedProductImage] = useState(0);
  const [selectedProductName, setSelectedProductName] = useState(productDetail.name);

  const [selectedProductPrice, setSelectedProductPrice] = useState(
    minPrice === maxPrice ? `฿${minPrice}` : `฿${minPrice} - ฿${maxPrice}`,
  );

  const [selectedDiscountProductPrice, setSelectedDiscountProductPrice] = useState(0);

  const [presentProductImage, setPresentProductImage] = useState(get(productDetail, "images[0].src"));
  useEffect(() => {
    setPresentProductImage(get(productDetail, "images[0].src"));
  }, []);

  return (
    <Grid container className="px-3 pb-5 mb-5">
      <Grid container item justify="center">
        <Grid item container xs={9}>
          <TransformWrapper>
            {({ zoomIn, zoomOut }) => (
              <>
                <BackdropWrapper>
                  <TransformComponent>
                    <ProductMainImageContainer src={presentProductImage || NO_IMAGE_AVAL_SRC} />
                  </TransformComponent>
                </BackdropWrapper>

                <ZoomToolsWrapper>
                  <Grid container direction="column">
                    <ButtonIconZoom onClick={zoomIn}>
                      <AddIcon color="inherit" fontSize="small" />
                    </ButtonIconZoom>
                    <ButtonIconZoom onClick={zoomOut}>
                      <RemoveIcon color="inherit" fontSize="small" />
                    </ButtonIconZoom>
                  </Grid>
                </ZoomToolsWrapper>
              </>
            )}
          </TransformWrapper>
        </Grid>
        <ProductImageSmallScroll>
          {productDetail.images &&
            productDetail.images.map((image, index) => {
              return (
                <ProductImageSmallContainer
                  key={index}
                  selected={selectedProductImage === index}
                  onClick={() => {
                    setSelectedProductImage(index);
                    setPresentProductImage(image.src);
                  }}
                  src={image.src || NO_IMAGE_AVAL_SRC}
                />
              );
            })}
        </ProductImageSmallScroll>
      </Grid>
      <Grid container direction="column" className="py-2">
        <Grid item>
          <ProductTitle className="py-1">{selectedProductName}</ProductTitle>
        </Grid>
        <Grid item>
          {selectedDiscountProductPrice < selectedProductPrice ? (
            <Grid container justify="flex-start">
              <AmountPriceBefore className="py-1">฿{formatMoney(selectedProductPrice)}</AmountPriceBefore>
              <AmountPriceAfter className="pl-2 py-1">฿{formatMoney(selectedDiscountProductPrice)}</AmountPriceAfter>
            </Grid>
          ) : (
            <ProductPrice className="py-1">฿{formatMoney(selectedProductPrice)}</ProductPrice>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <SelectProduct
          onChangeAmount={onChangeAmount}
          amount={amount}
          onChangeSKU={onChangeSKU}
          product={productDetail}
          selectedSKU={selectedSKU}
          onClickAddToCart={onClickAddToCart}
          skuForRender={skuForRender}
          promotions={promotions}
          setPresentProductImage={setPresentProductImage}
          setSelectedProductName={setSelectedProductName}
          setSelectedProductPrice={setSelectedProductPrice}
          setSelectedDiscountProductPrice={setSelectedDiscountProductPrice}
          productSKUs={productSKUs}
        />

        {productDetail.description && (
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TitleSectionText className="pb-2">{t("PRODUCT_DETAIL")}</TitleSectionText>
            </Grid>

            <DescriptionText>{productDetail.description}</DescriptionText>
            <SectionDivider />
          </Grid>
        )}

        {productDetail.additionalDescription && (
          <>
            <Grid item xs={12}>
              <TitleSectionText className="pb-2">{t("CONDITIONS")}</TitleSectionText>
            </Grid>

            <Grid item xs={12}>
              <DescriptionText>{productDetail.additionalDescription}</DescriptionText>
            </Grid>

            <SectionDivider />
          </>
        )}

        <Grid container item xs={12}>
          <Grid item xs={12}>
            <TitleSectionText className="pb-2">{t("PROPERTIES")}</TitleSectionText>
          </Grid>
          {RenderPropertiesTable(productDetail.properties, t)}
        </Grid>
      </Grid>
    </Grid>
  );
};
