import React from "react";
import qs from "qs";
import { Redirect } from "react-router-dom";
import * as yup from 'yup'

const validationSchema = yup.object({
  name: yup.array().of(yup.string()),
  brand: yup.array().of(yup.string()),
  category: yup.array().of(yup.string()),
  property_type: yup.array().of(yup.string()),
  property_value: yup.array().of(yup.string()),
  sku_type: yup.array().of(yup.string()),
  sku_value: yup.array().of(yup.string()),
  is_product_promotion: yup.boolean(),
  promotion_ids: yup.array().of(yup.number()),
  product_ids: yup.array().of(yup.number()),
})

export default Component => props => {
  const query = qs.parse(props.location.search.replace("?", ""));
  const searchParams = JSON.parse(query.searchParams)
  validationSchema.isValid(searchParams)
  return query.searchParams ? (
    <Component
      {...props}
      searchParams={searchParams}
    />
  ) : (
    <Redirect to="/" />
  );
};
