import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import qs from "qs";

import { COLORS } from "./constants";
import { theme } from "./theme";
import HttpClientService from "./services/http-client";

const httpClientService = new HttpClientService();

const ThemeProjectProviders = ({ children }) => {
  const query = qs.parse(window.location.search.replace("?", ""));
  const { projectId } = query;

  const dynamicTheme = theme(projectId);

  const [isLoadingConfigComplete, setIsLoadingConfigComplete] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(COLORS);

  useEffect(() => {
    const getProjectConfiguration = async () => {
      if (projectId) {
        try {
          const {
            data: { theme },
          } = await httpClientService.getProjectConfiguration(projectId);

          if (theme && theme.color) {
            const newPrimaryPalette = {
              ...COLORS,
              Primary: theme.color.primary,
              Secondary: theme.color.secondary,
            };
            setCurrentTheme(newPrimaryPalette);
          }
        } catch (err) {
          // Use default theme color
          const newPrimaryPalette = {
            ...COLORS,
          };
          setCurrentTheme(newPrimaryPalette);
        }

        setIsLoadingConfigComplete(true);
      } else {
        // Use default theme color
        const newPrimaryPalette = {
          ...COLORS,
        };
        setCurrentTheme(newPrimaryPalette);
        setIsLoadingConfigComplete(true);
      }
    };
    getProjectConfiguration();
  }, []);

  return isLoadingConfigComplete ? (
    <MuiThemeProvider theme={dynamicTheme}>
      <ThemeProvider theme={{ COLORS: currentTheme }}>
        <StylesProvider injectFirst>{children}</StylesProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  ) : null;
};

export default ThemeProjectProviders;
