import React from "react";
import styled from "styled-components";

export const SuggestionContainer = styled.div`
  border-radius: 8px;
  border: solid 1px #cad2e4;
  background-color: #ffffff;
  padding: 10px;
  margin: 10px 0px;
  color: #42495a;
`;

const SuggestionAddressList = (addressResult, setValues, values, clearAddressList, t) => {
  const parseSearchResultData = [];
  Object.keys(addressResult).forEach(key => {
    parseSearchResultData.push(addressResult[key]);
  });

  return (
    <>
      {Boolean(parseSearchResultData && parseSearchResultData.length) && (
        <SuggestionContainer>
          {parseSearchResultData.map(addressData => {
            return (
              <div
                style={{ padding: "8px" }}
                onMouseDown={() => {
                  setValues({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    streetAddress: values.streetAddress,
                    streetAddress2: values.streetAddress2,
                    district: addressData.a, // a = amphoe
                    subDistrict: addressData.d, // d = District
                    province: addressData.p, //p = Province
                    postalCode: addressData.z, //z = Zipcode
                    phoneNumber: values.phoneNumber,
                    email: values.email,
                    deliveryNote: values.note,
                  });
                  clearAddressList();
                }}
              >
                {`${addressData.d} » ${addressData.a} » ${addressData.p} » `}
                {addressData.z || <li>{t("NO_POSTAL_CODE")}</li>}
              </div>
            );
          })}
        </SuggestionContainer>
      )}
    </>
  );
};

export default SuggestionAddressList;
