import { CircularProgress } from "@material-ui/core";
import { get } from "lodash";
import React from "react";

import HttpClientService from "../services/http-client";
import { SHIPPING_METHOD } from "../constants/shipping";

const httpClientService = new HttpClientService();

export default Component =>
  class extends React.Component {
    state = {
      loading: true,
      offlineStores: [],
      shippingMethod: [],
    };

    async componentDidMount() {
      let offlineStores = [];
      let shippingAddress;
      let shippingMethod;
      let isEmailRequired;

      try {
        const { projectId } = this.props;
        const [
          offlineStoreResponse,
          shippingAddressResponse,
          shippingMethodTypeResponse,
          projectConfiguration,
        ] = await Promise.all([
          httpClientService.getOfflineStores(projectId),
          httpClientService.getShippingAddress(projectId),
          httpClientService.getShippingMethodByShippingMethodType(projectId, SHIPPING_METHOD.PICK_UP),
          httpClientService.getProjectConfiguration(projectId),
        ]);

        offlineStores = get(offlineStoreResponse, "data.offlineStores") || [];
        shippingAddress = get(shippingAddressResponse, "data");
        shippingMethod = get(shippingMethodTypeResponse, "data.shippingMethods") || [];
        isEmailRequired = Boolean(get(projectConfiguration, "data.order.isEmailRequired"));
      } catch (err) {
        console.error(err);
      }

      this.setState({
        loading: false,
        shippingAddress,
        offlineStores,
        isEmailRequired,
        shippingMethod,
      });
    }

    render() {
      const { loading, shippingAddress, offlineStores, isEmailRequired, shippingMethod } = this.state;

      if (loading) {
        return (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={150} />
          </div>
        );
      } else if (!loading && !offlineStores) {
        throw new Error();
      }

      return (
        <Component
          offlineStores={offlineStores}
          {...shippingAddress}
          shippingMethod={shippingMethod}
          isEmailRequired={isEmailRequired}
          {...this.props}
        />
      );
    }
  };
