import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import httpStatus from "http-status";

import AddressForm from "./pages/liff/addressForm";
import Membership from "./pages/liff/membership";
import MyCart from "./pages/liff/myCart";
import OfflineStore from "./pages/liff/offlineStore";
import OrderDetail from "./pages/liff/orderDetail";
import Payment from "./pages/liff/payment";
import PaymentSuccess from "./pages/liff/payment-success";
import ProductDetail from "./pages/liff/productDetail";
import Schedule from "./pages/liff/schedule";
import SearchProduct from "./pages/liff/searchProducts";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorPage from "./components/ErrorPage";

import ThemeProvider from "./themeProvider";
import MessengerSDKInitialization from "./MessengerSDKInitialization";
import i18n from "./utils/i18n";
import "./App.css";
import "bootstrap-utilities/bootstrap-utilities.css";

const Index = () => <ErrorPage errorCode={httpStatus.INTERNAL_SERVER_ERROR} />;

const AppRouter = () => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MessengerSDKInitialization>
        <ThemeProvider>
          <I18nextProvider i18n={i18n}>
            <ErrorBoundary fallback={<ErrorPage errorCode={httpStatus.INTERNAL_SERVER_ERROR} />}>
              <Router>
                <Switch>
                  <Route path="/addressForm" component={AddressForm} />
                  <Route path="/membership" component={Membership} />
                  <Route path="/cart" component={MyCart} />
                  <Route path="/offline-store" component={OfflineStore} />
                  <Route path="/order-detail" component={OrderDetail} />
                  <Route path="/liff/payment-success" component={PaymentSuccess} />
                  <Route path="/payment" component={Payment} />
                  <Route path="/product/:id" component={ProductDetail} />
                  <Route path="/schedule" component={Schedule} />
                  <Route path="/search-products" component={SearchProduct} />
                  <Route path="/" component={Index} />
                </Switch>
              </Router>
            </ErrorBoundary>
          </I18nextProvider>
        </ThemeProvider>
      </MessengerSDKInitialization>
    </MuiPickersUtilsProvider>
  );
};

export default AppRouter;
