import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import get from "lodash/get";
import { ProductTitle, AmountBox, AmountPrice, AmountPriceBefore, PromotionLabel } from "./OrderDetail.styled";
import {
  formatMoney,
  getBackwardCompatibleVariableTypes,
  getProductOptionList,
  getProductTypeList,
  getPromotionTotalDiscountForProductSKU,
  groupPromotionsByProductSKUId,
  getDigitalContentAvailabilityText,
} from "../../../utils/product";

import { NO_IMAGE_AVAL_SRC, PROMOTION } from "../../../constant";

export const ProductSummary = ({ products, promotionProduct }) => {
  const { t } = useTranslation(["ORDER_DETAIL,COMMON"]);
  const groupPromotionProduct = groupPromotionsByProductSKUId(promotionProduct);

  return (
    <>
      {products.map(product => {
        const promotions = groupPromotionProduct[product.id] || [];
        const variableTypes = getBackwardCompatibleVariableTypes(product);
        const productTypeList = getProductTypeList(variableTypes);
        const productOptionList = getProductOptionList(product.options);
        const totalDiscount = getPromotionTotalDiscountForProductSKU(product, promotions);
        const hasBundledPromotion = promotions.some(promotion => get(promotion, "settings.isBundled"));
        const hasFreeLowestPricePromotionAndDiscounted = promotions.some(
          promotion =>
            get(promotion, "settings.type") === PROMOTION.SETTING.TYPE.FREE_LOWEST_PRICE &&
            get(promotion, "totalDiscount"),
        );

        const productOptionPrice =
          product.options &&
          product.options.reduce((sumOptionPrice, { choices }) => {
            const optionPrice = choices.reduce((sumChoicePrice, { price, isSelected }) => {
              return isSelected ? sumChoicePrice + Number(price) : sumChoicePrice;
            }, 0);

            return sumOptionPrice + optionPrice;
          }, 0);
        const productPrice = Number(product.productPrice) + productOptionPrice;

        const digitalContentAvailabilityText = getDigitalContentAvailabilityText(product.digitalContentOptions);

        const isShowPromotion =
          Boolean(promotions.length) && (!hasBundledPromotion || hasFreeLowestPricePromotionAndDiscounted);

        const isShowDiscountPrice = totalDiscount && (!hasBundledPromotion || hasFreeLowestPricePromotionAndDiscounted);

        return (
          <Grid key={product.id} container item xs={12} justify="space-between" className="px-3 pb-2">
            <Grid item container xs={9}>
              <Grid item>
                <img
                  alt={product.product.name}
                  width="50"
                  height="50"
                  src={product.image || get(product, "product.images.0.src", NO_IMAGE_AVAL_SRC)}
                />
              </Grid>
              <Grid item container xs={9} className="ml-2">
                <Grid item xs={12}>
                  <ProductTitle className="pb-1">{product.product.name}</ProductTitle>
                </Grid>

                <Grid item container alignItems="center">
                  <AmountBox>{product.amount}x</AmountBox>
                  {productTypeList && <span className="ml-2">{productTypeList}</span>}
                </Grid>

                {productOptionList && (
                  <Grid item xs={12} className="pt-1">
                    <span>{productOptionList}</span>
                  </Grid>
                )}
                {product.note && (
                  <Grid item xs={12} className="pt-1">
                    <span>{product.note}</span>
                  </Grid>
                )}
                {isShowPromotion && (
                  <Grid item xs={12}>
                    {promotions.map(promotion => {
                      const promotionName = get(promotion, "name");
                      const promotionDescription = get(promotion, "description");

                      return (
                        <PromotionLabel key={promotion.id} className="pt-1">
                          {promotionDescription || promotionName}
                        </PromotionLabel>
                      );
                    })}
                  </Grid>
                )}

                {digitalContentAvailabilityText && (
                  <Grid item xs={12} className="pt-1">
                    {digitalContentAvailabilityText}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item className="pt-4">
              {isShowDiscountPrice ? (
                <Grid container justify="space-between" alignContent="center" alignItems="center" direction="column">
                  <AmountPriceBefore>฿{formatMoney(Number(product.amount) * productPrice)}</AmountPriceBefore>
                  <AmountPrice>฿{formatMoney(Number(product.amount) * productPrice - totalDiscount)}</AmountPrice>
                </Grid>
              ) : (
                <>
                  {product.isFree ? (
                    <AmountPrice>{t("COMMON:FREE")}</AmountPrice>
                  ) : (
                    <AmountPrice>฿{formatMoney(Number(product.amount) * productPrice)}</AmountPrice>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};
