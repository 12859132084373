import React, { useEffect, useMemo, useState } from "react";
import get from "lodash/get";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import moment from "moment";

import { Button, Grid, Typography, Divider, CircularProgress } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import DatePickerField from "../../../components/DatePickerField";
import TimePickerField from "../../../components/TimePickerField";
import { DATE_TIME } from "../../../constants";
import { withQueryOfflineStore, withQueryProjectId } from "../../../hoc";
import HttpClientService from "../../../services/http-client";
import { FormikTextField, sleep } from "../../../utils/common";
import { getInitialValuesForOfflineStores, getOfflineStoreValidationSchema, validateDate, validateTime } from "./utils";

const httpClientService = new HttpClientService();

const OfflineStore = props => {
  const {
    hasDigitalProductSKUs,
    offlineStores = [],
    projectId,
    shippingAddress,
    shippingMethod,
    isEmailRequired,
  } = props;
  const { t } = useTranslation(["OFFLINE_STORE", "COMMON", "DAYS", "ADDRESS_FORM"]);
  const validationSchema = useMemo(() => getOfflineStoreValidationSchema(t, hasDigitalProductSKUs, isEmailRequired));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = getInitialValuesForOfflineStores(shippingAddress);

  const [selectedBranch, setSelectedBranch] = useState();
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);

  const offlineStoreOptions = offlineStores.filter(offlineStore => {
    const { isActive, isAvailableForPickUp } = offlineStore;

    return isActive && isAvailableForPickUp;
  });

  const openingHours = selectedBranch && selectedBranch.openingHours ? selectedBranch.openingHours : [];

  const handleChangePickupStore = event => {
    const selectedBranchId = event.target.value;
    const newBranch = offlineStoreOptions.find(
      offlineStoreOption => Number(offlineStoreOption.id) === Number(selectedBranchId),
    );
    setSelectedBranch(newBranch);
  };

  const handleSubmit = async value => {
    setIsSubmitting(true);

    const offlineStoreId = selectedBranch.id;

    const date = moment(value.date).format(DATE_TIME.DATE_FORMAT);
    const time = moment(value.time, DATE_TIME.TIME_INPUT_FORMAT).format(DATE_TIME.TIME_FORMAT);

    const deliverySchedule = moment(
      `${date} ${time}`,
      `${DATE_TIME.DATE_FORMAT} ${DATE_TIME.TIME_FORMAT}`,
    ).toISOString();
    const selectedShippingAddress = {
      firstName: value.firstName,
      lastName: value.lastName,
      phoneNumber: value.phoneNumber,
      email: value.email ? value.email : undefined,
      note: value.note ? value.note : undefined,
    };

    try {
      await httpClientService.addPickedUpBranchAndSchedule(
        projectId,
        offlineStoreId,
        deliverySchedule,
        selectedShippingAddress,
      );
    } catch (err) {
      console.error(err);
    } finally {
      /**
       * We need to delay after already done of a new order and delay for process message between
       * chat webview -> rest api -> sme -> publish message (pub/sub) -> message-consumer -> process data -> publish to provider (FACEBOOK, INSTAGRAM, LINE etc.)
       **/
      await sleep(3000);

      // Remove loading
      setIsSubmitting(false);
      // Disable back button to force customer to close window by themselves if system cannot do it
      setIsButtonEnabled(false);

      await httpClientService.closeWindow();
    }
  };

  useEffect(() => {
    if (!selectedBranch) {
      setSelectedBranch(offlineStoreOptions[0]);
    }
  }, [offlineStoreOptions]);

  const isDisabledSchedulePickUp = Boolean(get(shippingMethod, "[0].isDisabledSchedulePickUp") || false);

  return (
    <>
      <Helmet>
        <title>{t("CHOOSE_BRANCH_AND_TIME")}</title>
      </Helmet>
      {offlineStoreOptions.length && selectedBranch ? (
        <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
          {({ errors, handleBlur, handleChange, touched, values }) => {
            const isDisabled = Object.keys(errors).length > 0;

            return (
              <Form noValidate>
                <br />
                <Grid container>
                  <Grid item>
                    <Typography
                      className={"px-3"}
                      fontWeight="fontWeightBold"
                      variant="h5"
                      color={"primary"}
                      gutterBottom
                    >
                      {t("CHOOSE_BRANCH_AND_TIME")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="middle" />
                  </Grid>
                </Grid>
                <br />
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justify="center" alignItems="center">
                      <Grid container className="px-3 mb-3">
                        <Grid item xs={6} className="pr-1">
                          <FormikTextField
                            onChange={handleChange}
                            name="firstName"
                            value={values.firstName}
                            onBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            label={t("ADDRESS_FORM:FIRST_NAME")}
                            required
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6} className="pl-1">
                          <FormikTextField
                            onChange={handleChange}
                            name="lastName"
                            value={values.lastName}
                            onBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            label={t("ADDRESS_FORM:LAST_NAME")}
                            required
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="px-3 mb-3">
                        <FormikTextField
                          onChange={handleChange}
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          label={t("ADDRESS_FORM:PHONE_NUMBER")}
                          required
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} className="px-3 mb-3">
                        <FormikTextField
                          onChange={handleChange}
                          name="email"
                          value={values.email}
                          onBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          label={t("ADDRESS_FORM:EMAIL")}
                          required={hasDigitalProductSKUs || isEmailRequired}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} className="px-3 mb-3">
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel>{t("BRANCH")}</InputLabel>
                          <Select
                            native
                            label={t("BRANCH")}
                            labelWidth={35}
                            value={selectedBranch.id}
                            onChange={handleChangePickupStore}
                          >
                            {offlineStoreOptions.map(option => {
                              const storeName = option.name;
                              const storeId = option.id;
                              return <option value={storeId}>{storeName}</option>;
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      {!isDisabledSchedulePickUp && (
                        <>
                          <Grid item xs={12} className="px-3 mb-3">
                            <Field
                              name="date"
                              minDate={moment()}
                              maxDate={moment().add(1, "months")}
                              format="ddd, DD MMMM YYYY"
                              validate={date => validateDate(date, openingHours)}
                              component={DatePickerField}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} className="px-3 mb-3">
                            <Field
                              name="time"
                              type="time"
                              validate={time =>
                                validateTime(values.date, moment(time, DATE_TIME.TIME_INPUT_FORMAT), openingHours)
                              }
                              component={TimePickerField}
                              fullWidth
                            />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} className="px-3 mb-3">
                        <FormikTextField
                          onChange={handleChange}
                          name="note"
                          value={values.note}
                          onBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          label={t("ADDRESS_FORM:NOTE")}
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={2}
                        />
                      </Grid>

                      <Grid item xs={12} className="px-3 mb-3">
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={isDisabled || isSubmitting || !isButtonEnabled}
                          color="primary"
                          fullWidth
                        >
                          {t("ADDRESS_FORM:SUBMIT")}
                          {isSubmitting && <CircularProgress size="24px" className="ml-2" />}
                        </Button>
                      </Grid>
                      <br />
                    </Grid>
                    {!isDisabledSchedulePickUp && (
                      <Grid item xs={12}>
                        <Grid container justify="center">
                          {!!openingHours.length && (
                            <Grid container item direction="column" xs={7} sm={8} md={4} className="mt-2 mb-4">
                              <Grid container justify="center">
                                <Typography variant="h6" gutterBottom>
                                  {t("WORKING_HOUR")}
                                </Typography>
                              </Grid>

                              {openingHours.map(openingHour => {
                                const { day, isActive, close, open } = openingHour;
                                const openTime = isActive ? `${open} - ${close}` : t("CLOSE");
                                const dayText = `${t(`DAYS:${DATE_TIME.DAYS[day]}`)}:`;

                                return (
                                  <Grid item container>
                                    <Grid item xs={6}>
                                      <Typography variant="ิbody2">{dayText}</Typography>
                                    </Grid>
                                    <Grid item container justify="center" xs={6}>
                                      <Grid item>
                                        <Typography variant="ิbody2" className={isActive ? "" : "text-danger"}>
                                          {openTime}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Grid container justify="center" className="mt-5">
          {t("NO_AVAILABLE_STORE")}
        </Grid>
      )}
    </>
  );
};

export default withRouter(withQueryProjectId(withQueryOfflineStore(OfflineStore)));
