import React, { useState, useEffect } from "react"
import httpStatus from "http-status";

import HttpClientService from "./services/http-client";
import ErrorPage from "./components/ErrorPage";
import ThemeProvider from "./themeProvider";


const httpClientService = new HttpClientService();
const DELAY_TIMEOUT = 15000;

let timeCount;

const MessengerSDKInitialization = ({ children }) => {
  const [isInitService, setIsInitService] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [isBrowserNotSupport, setIsBrowserNotSupport] = useState(false);

  const _initializeMessengerSDK = () => {
    timeCount = setTimeout(() => {
      setIsTimeout(true);
    }, DELAY_TIMEOUT); // waiting for 15sec

    ((d, s, id) => {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
      if (fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs);
      }
    })(document, "script", "Messenger");
    window.extAsyncInit = _onMessengerSDKLoaded;
  };

  const _onMessengerSDKLoaded = async () => {
    const isMetaClient = await httpClientService.isMetaClient();
    if (!isMetaClient) {
      setIsBrowserNotSupport(true)
    }

    clearTimeout(timeCount);
    setIsInitService(true);
  };

  const init = async () => {
    const isLineClient = await httpClientService.isLineClient();
    if (isLineClient) {
      await httpClientService.init();

      setIsInitService(true);
    } else {
      _initializeMessengerSDK();
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isBrowserNotSupport && process.env.NODE_ENV !== 'development') {
    return (
      <ThemeProvider>
        <ErrorPage errorCode="DEVICE_NOT_SUPPORT" />
      </ThemeProvider>
    );
  }

  if (isTimeout) {
    return (
      <ThemeProvider>
        <ErrorPage errorCode={httpStatus.REQUEST_TIMEOUT} />
      </ThemeProvider>
    );
  }
  if (!isInitService) {
    return null;
  }

  return children;
};

export default MessengerSDKInitialization;
