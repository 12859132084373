import React from "react";
import qs from "qs";
import { Redirect } from "react-router-dom";

export default Component => props => {
  const query = qs.parse(props.location.search.replace("?", ""));
  return query.projectId ? (
    <Component
      {...props}
      projectId={query.projectId}
      orderId={query.orderId}
      customerId={query.customerId}
    />
  ) : (
    <Redirect to="/" />
  );
};
