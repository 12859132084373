import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

export const ShippedDetail = ({ shippedDetail, shippingMethod }) => {
  const { t } = useTranslation(["ORDER_DETAIL"]);

  return (
    <Grid item xs={12} className="px-3">
      <Grid container>
        {shippedDetail.detail &&
          shippedDetail.detail.map((shipItem, index) => {
            return (
              <Grid item key={index} className="pb-2" xs={12}>
                <p>
                  {shippingMethod} : {shipItem.carrierName}
                </p>
                <p>
                  {`${t("SHIPPING_DATE")} : ${moment(
                    shipItem.deliveryDate
                  ).format("DD/MM/YYYY HH:mm")}`}
                </p>
                <p>{`${t("TRACKING_NUMBER")} : ${shipItem.trackingNumber}`}</p>
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};
