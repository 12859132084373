import React from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import {
  Corner,
  DescriptionContainer,
  ProductImage,
  ProductText,
  ProductTitleText,
  PromotionLabelText,
} from "./ProductDetail.styled";
import { getBackwardCompatibleVariableTypes, getProductImage, getProductTypeList, hasVariableTypesOrProductType } from "../../../utils/product"

const PromotionDetail = ({ promotion, selectedSKUId }) => {
  const { t } = useTranslation(["PRODUCT_DETAIL"]);

  const freeProductSKUs = get(promotion, "freeProducts");
  const selectedProductSKUs = get(promotion, "selectedProducts");

  return (
    <Grid container>
      <DescriptionContainer container className="my-2">
        <Grid item container justify="space-between" xs={12}>
          <Grid container item style={{ flex: 1 }}>
            <PromotionLabelText className="p-2">
              {promotion.description || promotion.name}
            </PromotionLabelText>
          </Grid>
          <Grid item>
            <Corner />
          </Grid>
        </Grid>
      </DescriptionContainer>

      {Boolean(selectedProductSKUs.length) && (
        <Grid container item>
          <Grid item>
            <ProductTitleText className="py-1">
              {t("PRODUCTS_IN_PROMOTION")}
            </ProductTitleText>
          </Grid>
          {selectedProductSKUs.map((productSKU) => (
            <Grid
              alignContent="center"
              alignItems="center"
              className="py-1"
              justify="space-between"
              container
              item
              key={productSKU.id}
              xs={12}
            >
              <ProductImage src={getProductImage(productSKU)} />
              <ProductText
                className="pl-3"
                style={{
                  fontWeight: `${
                    Number(selectedSKUId) === Number(productSKU.id)
                      ? "bold"
                      : "inherit"
                  }`,
                }}
              >
                {productSKU.product.name}
                {hasVariableTypesOrProductType(productSKU) &&
                  `(${getProductTypeList(getBackwardCompatibleVariableTypes(productSKU))})`}
              </ProductText>
            </Grid>
          ))}
        </Grid>
      )}

      {Boolean(freeProductSKUs.length) && (
        <Grid container item>
          <Grid item>
            <ProductTitleText className="py-1">
              {t("FREE_PRODUCT")}
            </ProductTitleText>
          </Grid>

          {freeProductSKUs.map((freeProductSKU) => (
            <Grid
              alignContent="center"
              alignItems="center"
              className="py-1"
              container
              item
              key={freeProductSKU.id}
              xs={12}
            >
              <ProductImage src={getProductImage(freeProductSKU)} />
              <ProductText className="pl-3">
                {freeProductSKU.product.name}
                {hasVariableTypesOrProductType(freeProductSKU) &&
                  `(${getProductTypeList(getBackwardCompatibleVariableTypes(freeProductSKU))})`}
              </ProductText>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default PromotionDetail;
